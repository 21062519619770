:root, [p-color-scheme="light"] {
  color-scheme: light;
  --p-background: #f6f6f7;
  --p-background-hovered: #f1f2f3;
  --p-background-pressed: #edeeef;
  --p-background-selected: #edeeef;
  --p-surface: #fff;
  --p-surface-dark: #202123;
  --p-surface-neutral: #e4e5e7;
  --p-surface-neutral-hovered: #dbdddf;
  --p-surface-neutral-pressed: #c9ccd0;
  --p-surface-neutral-disabled: #f1f2f3;
  --p-surface-neutral-subdued: #f6f6f7;
  --p-surface-neutral-subdued-dark: #44474a;
  --p-surface-subdued: #fafbfb;
  --p-surface-disabled: #fafbfb;
  --p-surface-hovered: #f6f6f7;
  --p-surface-hovered-dark: #2f3133;
  --p-surface-pressed: #f1f2f3;
  --p-surface-pressed-dark: #3e4043;
  --p-surface-depressed: #edeeef;
  --p-surface-search-field: #f1f2f3;
  --p-surface-search-field-dark: #2f3133;
  --p-backdrop: #00000080;
  --p-overlay: #ffffff80;
  --p-shadow-color-picker: #00000080;
  --p-shadow-color-picker-dragger: #212b3652;
  --p-hint-from-direct-light: #00000026;
  --p-border: #8c9196;
  --p-border-on-dark: #505356;
  --p-border-neutral-subdued: #babfc3;
  --p-border-hovered: #999ea4;
  --p-border-disabled: #d2d5d8;
  --p-border-subdued: #c9cccf;
  --p-border-depressed: #575959;
  --p-border-shadow: #aeb4b9;
  --p-border-shadow-subdued: #babfc4;
  --p-divider: #e1e3e5;
  --p-divider-dark: #454749;
  --p-icon: #5c5f62;
  --p-icon-on-dark: #a6acb2;
  --p-icon-hovered: #1a1c1d;
  --p-icon-pressed: #44474a;
  --p-icon-disabled: #babec3;
  --p-icon-subdued: #8c9196;
  --p-text: #202223;
  --p-text-on-dark: #e3e5e7;
  --p-text-disabled: #8c9196;
  --p-text-subdued: #6d7175;
  --p-text-subdued-on-dark: #999fa4;
  --p-interactive: #2c6ecb;
  --p-interactive-on-dark: #36a3ff;
  --p-interactive-disabled: #bdc1cc;
  --p-interactive-hovered: #1f5199;
  --p-interactive-pressed: #103262;
  --p-interactive-pressed-on-dark: #88bcff;
  --p-focused: #458fff;
  --p-surface-selected: #f2f7fe;
  --p-surface-selected-hovered: #edf4fe;
  --p-surface-selected-pressed: #e5effd;
  --p-icon-on-interactive: #fff;
  --p-text-on-interactive: #fff;
  --p-action-secondary: #fff;
  --p-action-secondary-disabled: #fff;
  --p-action-secondary-hovered: #f6f6f7;
  --p-action-secondary-hovered-dark: #54575b;
  --p-action-secondary-pressed: #f1f2f3;
  --p-action-secondary-pressed-dark: #606467;
  --p-action-secondary-depressed: #6d7175;
  --p-action-primary: #008060;
  --p-action-primary-disabled: #f1f1f1;
  --p-action-primary-hovered: #006e52;
  --p-action-primary-pressed: #005e46;
  --p-action-primary-depressed: #003d2c;
  --p-icon-on-primary: #fff;
  --p-text-on-primary: #fff;
  --p-text-primary: #007b5c;
  --p-text-primary-hovered: #006c50;
  --p-text-primary-pressed: #005c44;
  --p-surface-primary-selected: #f1f8f5;
  --p-surface-primary-selected-hovered: #b3d0c3;
  --p-surface-primary-selected-pressed: #a2bcb0;
  --p-border-critical: #fd5749;
  --p-border-critical-subdued: #e0b3b2;
  --p-border-critical-disabled: #ffa7a3;
  --p-icon-critical: #d72c0d;
  --p-surface-critical: #fed3d1;
  --p-surface-critical-subdued: #fff4f4;
  --p-surface-critical-subdued-hovered: #fff0f0;
  --p-surface-critical-subdued-pressed: #ffe9e8;
  --p-surface-critical-subdued-depressed: #febcb9;
  --p-text-critical: #d72c0d;
  --p-action-critical: #d82c0d;
  --p-action-critical-disabled: #f1f1f1;
  --p-action-critical-hovered: #bc2200;
  --p-action-critical-pressed: #a21b00;
  --p-action-critical-depressed: #6c0f00;
  --p-icon-on-critical: #fff;
  --p-text-on-critical: #fff;
  --p-interactive-critical: #d82c0d;
  --p-interactive-critical-disabled: #fd938d;
  --p-interactive-critical-hovered: #cd290c;
  --p-interactive-critical-pressed: #670f03;
  --p-border-warning: #b98900;
  --p-border-warning-subdued: #e1b878;
  --p-icon-warning: #b98900;
  --p-surface-warning: #ffd79d;
  --p-surface-warning-subdued: #fff5ea;
  --p-surface-warning-subdued-hovered: #fff2e2;
  --p-surface-warning-subdued-pressed: #ffebd3;
  --p-text-warning: #916a00;
  --p-border-highlight: #449da7;
  --p-border-highlight-subdued: #98c6cd;
  --p-icon-highlight: #00a0ac;
  --p-surface-highlight: #a4e8f2;
  --p-surface-highlight-subdued: #ebf9fc;
  --p-surface-highlight-subdued-hovered: #e4f7fa;
  --p-surface-highlight-subdued-pressed: #d5f3f8;
  --p-text-highlight: #347c84;
  --p-border-success: #00a47c;
  --p-border-success-subdued: #95c9b4;
  --p-icon-success: #007f5f;
  --p-surface-success: #aee9d1;
  --p-surface-success-subdued: #f1f8f5;
  --p-surface-success-subdued-hovered: #ecf6f1;
  --p-surface-success-subdued-pressed: #e2f1ea;
  --p-text-success: #008060;
  --p-icon-attention: #8a6116;
  --p-surface-attention: #ffea8a;
  --p-decorative-one-icon: #7e5700;
  --p-decorative-one-surface: #ffc96b;
  --p-decorative-one-text: #3d2800;
  --p-decorative-two-icon: #af294e;
  --p-decorative-two-surface: #ffc4b0;
  --p-decorative-two-text: #490b1c;
  --p-decorative-three-icon: #006d41;
  --p-decorative-three-surface: #92e6b5;
  --p-decorative-three-text: #002f19;
  --p-decorative-four-icon: #006a68;
  --p-decorative-four-surface: #91e0d6;
  --p-decorative-four-text: #002d2d;
  --p-decorative-five-icon: #ae2b4c;
  --p-decorative-five-surface: #fdc9d0;
  --p-decorative-five-text: #4f0e1f;
  --p-breakpoints-xs: 0rem;
  --p-breakpoints-sm: 30.625rem;
  --p-breakpoints-md: 48rem;
  --p-breakpoints-lg: 65rem;
  --p-breakpoints-xl: 90rem;
  --p-shadow-transparent: 0 0 0 0 transparent;
  --p-shadow-faint: 0 .0625rem 0 0 #161d250d;
  --p-shadow-base: 0 0 0 .0625rem #3f3f440d, 0 .0625rem .1875rem 0 #3f3f4426;
  --p-shadow-deep: 0 0 0 .0625rem #062c521a, 0 .125rem 1rem #212b3614;
  --p-shadow-button: 0 .0625rem 0 #0000000d;
  --p-shadow-top-bar: 0 .125rem .125rem -.0625rem #00000026;
  --p-shadow-card: 0 0 .3125rem #1718180d, 0 .0625rem .125rem #00000026;
  --p-shadow-popover: 0 .1875rem .375rem -.1875rem #17181814, 0 .5rem 1.25rem -.25rem #1718181f;
  --p-shadow-layer: 0 1.9375rem 2.5625rem 0 #202a3533, 0 .125rem 1rem 0 #202a3614;
  --p-shadow-modal: 0 1.625rem 5rem #0003, 0 0 .0625rem #0003;
  --p-shadows-inset-button: inset 0 -.0625rem 0 #0003;
  --p-shadows-inset-button-pressed: inset 0 .0625rem 0 #00000026;
  --p-override-loading-z-index: 514;
  --p-choice-size: 1.25rem;
  --p-icon-size-small: .5rem;
  --p-icon-size-medium: 1.25rem;
  --p-choice-margin: .0625rem;
  --p-control-border-width: .125rem;
  --p-banner-border-default: inset 0 .0625rem 0 0 var(--p-border-neutral-subdued), inset 0 0 0 .0625rem var(--p-border-neutral-subdued);
  --p-banner-border-success: inset 0 .0625rem 0 0 var(--p-border-success-subdued), inset 0 0 0 .0625rem var(--p-border-success-subdued);
  --p-banner-border-highlight: inset 0 .0625rem 0 0 var(--p-border-highlight-subdued), inset 0 0 0 .0625rem var(--p-border-highlight-subdued);
  --p-banner-border-warning: inset 0 .0625rem 0 0 var(--p-border-warning-subdued), inset 0 0 0 .0625rem var(--p-border-warning-subdued);
  --p-banner-border-critical: inset 0 .0625rem 0 0 var(--p-border-critical-subdued), inset 0 0 0 .0625rem var(--p-border-critical-subdued);
  --p-thin-border-subdued: .0625rem solid var(--p-border-subdued);
  --p-text-field-spinner-offset: .125rem;
  --p-text-field-focus-ring-offset: -.25rem;
  --p-button-group-item-spacing: -.0625rem;
  --p-range-slider-thumb-size-base: 1rem;
  --p-range-slider-thumb-size-active: 1.5rem;
  --p-frame-offset: 0rem;
  --p-duration-0: 0ms;
  --p-duration-50: 50ms;
  --p-duration-100: 100ms;
  --p-duration-150: 150ms;
  --p-duration-200: 200ms;
  --p-duration-250: 250ms;
  --p-duration-300: 300ms;
  --p-duration-350: 350ms;
  --p-duration-400: 400ms;
  --p-duration-450: 450ms;
  --p-duration-500: 500ms;
  --p-duration-5000: 5000ms;
  --p-ease: cubic-bezier(.25, .1, .25, 1);
  --p-ease-in: cubic-bezier(.42, 0, 1, 1);
  --p-ease-out: cubic-bezier(0, 0, .58, 1);
  --p-ease-in-out: cubic-bezier(.42, 0, .58, 1);
  --p-linear: cubic-bezier(0, 0, 1, 1);
  --p-keyframes-bounce: p-keyframes-bounce;
  --p-keyframes-fade-in: p-keyframes-fade-in;
  --p-keyframes-pulse: p-keyframes-pulse;
  --p-keyframes-spin: p-keyframes-spin;
  --p-border-radius-05: .125rem;
  --p-border-radius-1: .25rem;
  --p-border-radius-2: .5rem;
  --p-border-radius-3: .75rem;
  --p-border-radius-4: 1rem;
  --p-border-radius-5: 1.25rem;
  --p-border-radius-6: 1.875rem;
  --p-border-radius-base: .1875rem;
  --p-border-radius-large: .375rem;
  --p-border-radius-half: 50%;
  --p-border-width-1: .0625rem;
  --p-border-width-2: .125rem;
  --p-border-width-3: .1875rem;
  --p-border-width-4: .25rem;
  --p-border-width-5: .3125rem;
  --p-border-base: var(--p-border-width-1) solid var(--p-border-subdued);
  --p-border-dark: var(--p-border-width-1) solid var(--p-border);
  --p-border-transparent: var(--p-border-width-1) solid transparent;
  --p-border-divider: var(--p-border-width-1) solid var(--p-divider);
  --p-border-divider-on-dark: var(--p-border-width-1) solid var(--p-divider-dark);
  --p-space-0: 0;
  --p-space-025: .0625rem;
  --p-space-05: .125rem;
  --p-space-1: .25rem;
  --p-space-2: .5rem;
  --p-space-3: .75rem;
  --p-space-4: 1rem;
  --p-space-5: 1.25rem;
  --p-space-6: 1.5rem;
  --p-space-8: 2rem;
  --p-space-10: 2.5rem;
  --p-space-12: 3rem;
  --p-space-16: 4rem;
  --p-space-20: 5rem;
  --p-space-24: 6rem;
  --p-space-28: 7rem;
  --p-space-32: 8rem;
  --p-font-family-sans: -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --p-font-family-mono: ui-monospace, SFMono-Regular, "SF Mono", Consolas, "Liberation Mono", Menlo, monospace;
  --p-font-size-1: .75rem;
  --p-font-size-2: .8125rem;
  --p-font-size-3: .875rem;
  --p-font-size-4: .9375rem;
  --p-font-size-5: 1rem;
  --p-font-size-6: 1.0625rem;
  --p-font-size-7: 1.25rem;
  --p-font-size-8: 1.3125rem;
  --p-font-size-9: 1.5rem;
  --p-font-size-10: 1.625rem;
  --p-font-size-11: 1.6875rem;
  --p-font-size-12: 1.75rem;
  --p-font-size-13: 2.625rem;
  --p-font-weight-regular: 400;
  --p-font-weight-medium: 500;
  --p-font-weight-semibold: 600;
  --p-font-weight-bold: 700;
  --p-line-height-1: 1rem;
  --p-line-height-2: 1.25rem;
  --p-line-height-3: 1.5rem;
  --p-line-height-4: 1.75rem;
  --p-line-height-5: 2rem;
  --p-line-height-6: 2.25rem;
  --p-line-height-7: 2.75rem;
  --p-z-1: 100;
  --p-z-2: 400;
  --p-z-3: 510;
  --p-z-4: 512;
  --p-z-5: 513;
  --p-z-6: 514;
  --p-z-7: 515;
  --p-z-8: 516;
  --p-z-9: 517;
  --p-z-10: 518;
  --p-z-11: 519;
  --p-z-12: 520;
}

[p-color-scheme="dark"] {
  color-scheme: dark;
  --p-background: #0b0c0d;
  --p-background-hovered: #0b0c0d;
  --p-background-pressed: #0b0c0d;
  --p-background-selected: #0b0c0d;
  --p-surface: #202123;
  --p-surface-neutral: #313335;
  --p-surface-neutral-hovered: #313335;
  --p-surface-neutral-pressed: #313335;
  --p-surface-neutral-disabled: #313335;
  --p-surface-neutral-subdued: #44474a;
  --p-surface-subdued: #1a1c1d;
  --p-surface-disabled: #1a1c1d;
  --p-surface-hovered: #2f3133;
  --p-surface-pressed: #3e4043;
  --p-surface-depressed: #505356;
  --p-surface-search-field: #2f3133;
  --p-backdrop: #00000080;
  --p-overlay: #21212180;
  --p-shadow-color-picker: #0000;
  --p-shadow-color-picker-dragger: #0000;
  --p-hint-from-direct-light: #b9b9b933;
  --p-border: #505356;
  --p-border-neutral-subdued: #82878b;
  --p-border-hovered: #505356;
  --p-border-disabled: #676b6f;
  --p-border-subdued: #82878b;
  --p-border-depressed: #8e9191;
  --p-border-shadow: #5b5f62;
  --p-border-shadow-subdued: #82878b;
  --p-divider: #454749;
  --p-icon: #a6acb2;
  --p-icon-hovered: #e1e3e5;
  --p-icon-pressed: #a6acb2;
  --p-icon-disabled: #54575a;
  --p-icon-subdued: #787d81;
  --p-text: #e3e5e7;
  --p-text-disabled: #6f7377;
  --p-text-subdued: #999fa4;
  --p-interactive: #36a3ff;
  --p-interactive-disabled: #2662b6;
  --p-interactive-hovered: #67afff;
  --p-interactive-pressed: #88bcff;
  --p-focused: #2662b6;
  --p-surface-selected: #020e23;
  --p-surface-selected-hovered: #071d3d;
  --p-surface-selected-pressed: #0d2b56;
  --p-icon-on-interactive: #fff;
  --p-text-on-interactive: #fff;
  --p-action-secondary: #4d5053;
  --p-action-secondary-disabled: #202223;
  --p-action-secondary-hovered: #54575b;
  --p-action-secondary-pressed: #606467;
  --p-action-secondary-depressed: #7b7f84;
  --p-action-primary: #008060;
  --p-action-primary-disabled: #005640;
  --p-action-primary-hovered: #009671;
  --p-action-primary-pressed: #00a47c;
  --p-action-primary-depressed: #00b388;
  --p-icon-on-primary: #e6fff4;
  --p-text-on-primary: #fff;
  --p-text-primary: #008d6a;
  --p-text-primary-hovered: #009e78;
  --p-text-primary-pressed: #00b085;
  --p-surface-primary-selected: #0c1210;
  --p-surface-primary-selected-hovered: #28302c;
  --p-surface-primary-selected-pressed: #36403b;
  --p-border-critical: #e32f0e;
  --p-border-critical-subdued: #e32f0e;
  --p-border-critical-disabled: #831704;
  --p-icon-critical: #da2d0d;
  --p-surface-critical: #450701;
  --p-surface-critical-subdued: #450701;
  --p-surface-critical-subdued-hovered: #441714;
  --p-surface-critical-subdued-pressed: #6b1003;
  --p-surface-critical-subdued-depressed: #871805;
  --p-text-critical: #e9807a;
  --p-action-critical: #cd290c;
  --p-action-critical-disabled: #bb250a;
  --p-action-critical-hovered: #e32f0e;
  --p-action-critical-pressed: #fa3511;
  --p-action-critical-depressed: #fd5749;
  --p-icon-on-critical: #fff8f7;
  --p-text-on-critical: #fff;
  --p-interactive-critical: #fd726a;
  --p-interactive-critical-disabled: #feaca8;
  --p-interactive-critical-hovered: #fd8a84;
  --p-interactive-critical-pressed: #fd9f9b;
  --p-border-warning: #997000;
  --p-border-warning-subdued: #997000;
  --p-icon-warning: #684b00;
  --p-surface-warning: #997000;
  --p-surface-warning-subdued: #4d3b1d;
  --p-surface-warning-subdued-hovered: #523f20;
  --p-surface-warning-subdued-pressed: #574322;
  --p-text-warning: #ca9500;
  --p-border-highlight: #449da7;
  --p-border-highlight-subdued: #449da7;
  --p-icon-highlight: #2c6c73;
  --p-surface-highlight: #006971;
  --p-surface-highlight-subdued: #123539;
  --p-surface-highlight-subdued-hovered: #143a3e;
  --p-surface-highlight-subdued-pressed: #184146;
  --p-text-highlight: #a2effa;
  --p-border-success: #008766;
  --p-border-success-subdued: #008766;
  --p-icon-success: #005e46;
  --p-surface-success: #005e46;
  --p-surface-success-subdued: #1c352c;
  --p-surface-success-subdued-hovered: #1f3a30;
  --p-surface-success-subdued-pressed: #234136;
  --p-text-success: #58ad8e;
  --p-icon-attention: #8a6116;
  --p-surface-attention: #ffea8a;
  --p-decorative-one-icon: #ffba43;
  --p-decorative-one-surface: #8e6609;
  --p-decorative-one-text: #fff;
  --p-decorative-two-icon: #f5b6c0;
  --p-decorative-two-surface: #ce5814;
  --p-decorative-two-text: #fff;
  --p-decorative-three-icon: #00e38d;
  --p-decorative-three-surface: #007c5a;
  --p-decorative-three-text: #fff;
  --p-decorative-four-icon: #00ddda;
  --p-decorative-four-surface: #167c79;
  --p-decorative-four-text: #fff;
  --p-decorative-five-icon: #f4b7bf;
  --p-decorative-five-surface: #c23356;
  --p-decorative-five-text: #fff;
  --p-breakpoints-xs: 0rem;
  --p-breakpoints-sm: 30.625rem;
  --p-breakpoints-md: 48rem;
  --p-breakpoints-lg: 65rem;
  --p-breakpoints-xl: 90rem;
  --p-shadow-transparent: 0 0 0 0 transparent;
  --p-shadow-faint: 0 .0625rem 0 0 #161d250d;
  --p-shadow-base: 0 0 0 .0625rem #3f3f440d, 0 .0625rem .1875rem 0 #3f3f4426;
  --p-shadow-deep: 0 0 0 .0625rem #062c521a, 0 .125rem 1rem #212b3614;
  --p-shadow-button: 0 .0625rem 0 #0000000d;
  --p-shadow-top-bar: 0 .125rem .125rem -.0625rem #00000026;
  --p-shadow-card: 0 0 .3125rem #1718180d, 0 .0625rem .125rem #00000026;
  --p-shadow-popover: 0 .1875rem .375rem -.1875rem #17181814, 0 .5rem 1.25rem -.25rem #1718181f;
  --p-shadow-layer: 0 1.9375rem 2.5625rem 0 #202a3533, 0 .125rem 1rem 0 #202a3614;
  --p-shadow-modal: 0 1.625rem 5rem #0003, 0 0 .0625rem #0003;
  --p-shadows-inset-button: inset 0 -.0625rem 0 #0003;
  --p-shadows-inset-button-pressed: inset 0 .0625rem 0 #00000026;
  --p-override-loading-z-index: 514;
  --p-choice-size: 1.25rem;
  --p-icon-size-small: .5rem;
  --p-icon-size-medium: 1.25rem;
  --p-choice-margin: .0625rem;
  --p-control-border-width: .125rem;
  --p-banner-border-default: inset 0 .0625rem 0 0 var(--p-border-neutral-subdued), inset 0 0 0 .0625rem var(--p-border-neutral-subdued);
  --p-banner-border-success: inset 0 .0625rem 0 0 var(--p-border-success-subdued), inset 0 0 0 .0625rem var(--p-border-success-subdued);
  --p-banner-border-highlight: inset 0 .0625rem 0 0 var(--p-border-highlight-subdued), inset 0 0 0 .0625rem var(--p-border-highlight-subdued);
  --p-banner-border-warning: inset 0 .0625rem 0 0 var(--p-border-warning-subdued), inset 0 0 0 .0625rem var(--p-border-warning-subdued);
  --p-banner-border-critical: inset 0 .0625rem 0 0 var(--p-border-critical-subdued), inset 0 0 0 .0625rem var(--p-border-critical-subdued);
  --p-thin-border-subdued: .0625rem solid var(--p-border-subdued);
  --p-text-field-spinner-offset: .125rem;
  --p-text-field-focus-ring-offset: -.25rem;
  --p-button-group-item-spacing: -.0625rem;
  --p-range-slider-thumb-size-base: 1rem;
  --p-range-slider-thumb-size-active: 1.5rem;
  --p-frame-offset: 0rem;
  --p-duration-0: 0ms;
  --p-duration-50: 50ms;
  --p-duration-100: 100ms;
  --p-duration-150: 150ms;
  --p-duration-200: 200ms;
  --p-duration-250: 250ms;
  --p-duration-300: 300ms;
  --p-duration-350: 350ms;
  --p-duration-400: 400ms;
  --p-duration-450: 450ms;
  --p-duration-500: 500ms;
  --p-duration-5000: 5000ms;
  --p-ease: cubic-bezier(.25, .1, .25, 1);
  --p-ease-in: cubic-bezier(.42, 0, 1, 1);
  --p-ease-out: cubic-bezier(0, 0, .58, 1);
  --p-ease-in-out: cubic-bezier(.42, 0, .58, 1);
  --p-linear: cubic-bezier(0, 0, 1, 1);
  --p-keyframes-bounce: p-keyframes-bounce;
  --p-keyframes-fade-in: p-keyframes-fade-in;
  --p-keyframes-pulse: p-keyframes-pulse;
  --p-keyframes-spin: p-keyframes-spin;
  --p-border-radius-05: .125rem;
  --p-border-radius-1: .25rem;
  --p-border-radius-2: .5rem;
  --p-border-radius-3: .75rem;
  --p-border-radius-4: 1rem;
  --p-border-radius-5: 1.25rem;
  --p-border-radius-6: 1.875rem;
  --p-border-radius-base: .1875rem;
  --p-border-radius-large: .375rem;
  --p-border-radius-half: 50%;
  --p-border-width-1: .0625rem;
  --p-border-width-2: .125rem;
  --p-border-width-3: .1875rem;
  --p-border-width-4: .25rem;
  --p-border-width-5: .3125rem;
  --p-border-base: var(--p-border-width-1) solid var(--p-border-subdued);
  --p-border-dark: var(--p-border-width-1) solid var(--p-border);
  --p-border-transparent: var(--p-border-width-1) solid transparent;
  --p-border-divider: var(--p-border-width-1) solid var(--p-divider);
  --p-border-divider-on-dark: var(--p-border-width-1) solid var(--p-divider-dark);
  --p-space-0: 0;
  --p-space-025: .0625rem;
  --p-space-05: .125rem;
  --p-space-1: .25rem;
  --p-space-2: .5rem;
  --p-space-3: .75rem;
  --p-space-4: 1rem;
  --p-space-5: 1.25rem;
  --p-space-6: 1.5rem;
  --p-space-8: 2rem;
  --p-space-10: 2.5rem;
  --p-space-12: 3rem;
  --p-space-16: 4rem;
  --p-space-20: 5rem;
  --p-space-24: 6rem;
  --p-space-28: 7rem;
  --p-space-32: 8rem;
  --p-font-family-sans: -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
  --p-font-family-mono: ui-monospace, SFMono-Regular, "SF Mono", Consolas, "Liberation Mono", Menlo, monospace;
  --p-font-size-1: .75rem;
  --p-font-size-2: .8125rem;
  --p-font-size-3: .875rem;
  --p-font-size-4: .9375rem;
  --p-font-size-5: 1rem;
  --p-font-size-6: 1.0625rem;
  --p-font-size-7: 1.25rem;
  --p-font-size-8: 1.3125rem;
  --p-font-size-9: 1.5rem;
  --p-font-size-10: 1.625rem;
  --p-font-size-11: 1.6875rem;
  --p-font-size-12: 1.75rem;
  --p-font-size-13: 2.625rem;
  --p-font-weight-regular: 400;
  --p-font-weight-medium: 500;
  --p-font-weight-semibold: 600;
  --p-font-weight-bold: 700;
  --p-line-height-1: 1rem;
  --p-line-height-2: 1.25rem;
  --p-line-height-3: 1.5rem;
  --p-line-height-4: 1.75rem;
  --p-line-height-5: 2rem;
  --p-line-height-6: 2.25rem;
  --p-line-height-7: 2.75rem;
  --p-z-1: 100;
  --p-z-2: 400;
  --p-z-3: 510;
  --p-z-4: 512;
  --p-z-5: 513;
  --p-z-6: 514;
  --p-z-7: 515;
  --p-z-8: 516;
  --p-z-9: 517;
  --p-z-10: 518;
  --p-z-11: 519;
  --p-z-12: 520;
}

@keyframes p-keyframes-bounce {
  from, 65%, 85% {
    transform: scale(1);
  }

  75% {
    transform: scale(.85);
  }

  82.5% {
    transform: scale(1.05);
  }
}

@keyframes p-keyframes-fade-in {
  to {
    opacity: 1;
  }
}

@keyframes p-keyframes-pulse {
  from, 75% {
    opacity: 1;
    transform: scale(.85);
  }

  to {
    opacity: 0;
    transform: scale(2.5);
  }
}

@keyframes p-keyframes-spin {
  to {
    transform: rotate(1turn);
  }
}

:root {
  --polaris-version-number: "9.19.0";
}

html, body {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
}

@media (min-width: 40em) {
  html, body {
    font-size: var(--p-font-size-3);
  }
}

html, body, button {
  font-family: var(--p-font-family-sans);
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  font-size: 100%;
  position: relative;
}

body {
  min-height: 100%;
  background-color: #f6f6f7;
  margin: 0;
  padding: 0;
}

@media print {
  body {
    background-color: #0000 !important;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  font-size: 1em;
  font-weight: var(--p-font-weight-regular);
  margin: 0;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
}

.Polaris-Avatar {
  --pc-avatar-extra-small-size: 1.5rem;
  --pc-avatar-small-size: 2rem;
  --pc-avatar-medium-size: 2.5rem;
  --pc-avatar-large-size: 3.75rem;
  min-width: var(--pc-avatar-extra-small-size);
  max-width: 100%;
  background: var(--p-surface-neutral);
  color: var(--p-icon-subdued);
  border-radius: var(--p-border-radius-half);
  user-select: none;
  display: block;
  position: relative;
  overflow: hidden;
}

@media (forced-colors: active) {
  .Polaris-Avatar {
    border: var(--p-border-width-1) solid transparent;
  }
}

.Polaris-Avatar:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.Polaris-Avatar--shapeSquare {
  border-radius: var(--p-border-radius-05);
}

.Polaris-Avatar--hidden {
  visibility: hidden;
}

.Polaris-Avatar--sizeExtraSmall {
  width: var(--pc-avatar-extra-small-size);
}

.Polaris-Avatar--sizeSmall {
  width: var(--pc-avatar-small-size);
}

.Polaris-Avatar--sizeMedium {
  width: var(--pc-avatar-medium-size);
}

.Polaris-Avatar--sizeLarge {
  width: var(--pc-avatar-large-size);
}

.Polaris-Avatar--styleOne {
  color: var(--p-decorative-one-text);
  background: var(--p-decorative-one-surface);
}

.Polaris-Avatar--styleTwo {
  color: var(--p-decorative-two-text);
  background: var(--p-decorative-two-surface);
}

.Polaris-Avatar--styleThree {
  color: var(--p-decorative-three-text);
  background: var(--p-decorative-three-surface);
}

.Polaris-Avatar--styleFour {
  color: var(--p-decorative-four-text);
  background: var(--p-decorative-four-surface);
}

.Polaris-Avatar--styleFive {
  color: var(--p-decorative-five-text);
  background: var(--p-decorative-five-surface);
}

.Polaris-Avatar--imageHasLoaded {
  background: none;
}

.Polaris-Avatar__Image {
  width: 100%;
  height: 100%;
  border-radius: var(--p-border-radius-half);
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Polaris-Avatar__Initials {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.Polaris-Avatar__Svg {
  width: 100%;
  height: 100%;
}

.Polaris-VisuallyHidden {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Icon {
  height: 1.25rem;
  width: 1.25rem;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  display: block;
}

.Polaris-Icon--applyColor {
  color: var(--p-surface);
}

.Polaris-Icon--hasBackdrop {
  margin: var(--p-space-1);
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-Icon--hasBackdrop:before {
  content: "";
  top: calc(var(--p-space-1) * -1);
  bottom: calc(var(--p-space-1) * -1);
  left: calc(var(--p-space-1) * -1);
  right: calc(var(--p-space-1) * -1);
  border-radius: var(--p-border-radius-half);
  position: absolute;
}

.Polaris-Icon--colorBase svg {
  fill: var(--p-icon);
}

.Polaris-Icon--colorBase:before {
  background-color: var(--p-surface-neutral);
}

.Polaris-Icon--colorSubdued svg {
  fill: var(--p-icon-subdued);
}

.Polaris-Icon--colorCritical svg {
  fill: var(--p-icon-critical);
}

.Polaris-Icon--colorCritical:before {
  background-color: var(--p-surface-critical);
}

.Polaris-Icon--colorInteractive svg {
  fill: var(--p-interactive);
}

.Polaris-Icon--colorWarning svg {
  fill: var(--p-icon-warning);
}

.Polaris-Icon--colorWarning:before {
  background-color: var(--p-surface-warning);
}

.Polaris-Icon--colorHighlight svg {
  fill: var(--p-icon-highlight);
}

.Polaris-Icon--colorHighlight:before {
  background-color: var(--p-surface-highlight);
}

.Polaris-Icon--colorSuccess svg {
  fill: var(--p-icon-success);
}

.Polaris-Icon--colorSuccess:before {
  background-color: var(--p-surface-success);
}

.Polaris-Icon--colorPrimary svg {
  fill: var(--p-action-primary);
}

.Polaris-Icon__Svg, .Polaris-Icon__Img {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: relative;
}

.Polaris-Icon__Placeholder {
  background: currentColor;
  padding-bottom: 100%;
}

.Polaris-Spinner svg {
  animation: var(--p-keyframes-spin) var(--p-duration-500) linear infinite;
  fill: var(--p-border-highlight);
}

.Polaris-Spinner--sizeSmall svg {
  height: 1.25rem;
  width: 1.25rem;
}

.Polaris-Spinner--sizeLarge svg {
  height: 2.75rem;
  width: 2.75rem;
}

.Polaris-Scrollable {
  --pc-scrollable-shadow-size: var(--p-space-5);
  --pc-scrollable-shadow-bottom: inset 0 calc(var(--pc-scrollable-shadow-size) * -1) var(--pc-scrollable-shadow-size) calc(var(--pc-scrollable-shadow-size) * -1) var(--p-hint-from-direct-light);
  --pc-scrollable-shadow-top: inset 0 var(--pc-scrollable-shadow-size) var(--pc-scrollable-shadow-size) calc(var(--pc-scrollable-shadow-size) * -1) var(--p-hint-from-direct-light);
  --pc-scrollable-max-height: none;
  -webkit-overflow-scrolling: touch;
  max-height: var(--pc-scrollable-max-height);
  position: relative;
}

.Polaris-Scrollable:focus {
  outline: var(--p-border-width-2) solid var(--p-focused);
  outline-offset: .125rem;
}

.Polaris-Scrollable--horizontal {
  overflow-x: auto;
}

.Polaris-Scrollable--vertical {
  overflow-y: auto;
}

.Polaris-Scrollable--verticalHasScrolling {
  overflow-y: scroll;
}

.Polaris-Scrollable--hasTopShadow {
  box-shadow: var(--pc-scrollable-shadow-top);
}

.Polaris-Scrollable--hasBottomShadow {
  box-shadow: var(--pc-scrollable-shadow-bottom);
}

.Polaris-Scrollable--hasTopShadow.Polaris-Scrollable--hasBottomShadow {
  box-shadow: var(--pc-scrollable-shadow-top), var(--pc-scrollable-shadow-bottom);
}

.Polaris-Popover {
  max-width: calc(100vw - var(--p-space-8));
  margin: .3125rem var(--p-space-2) var(--p-space-4);
  box-shadow: var(--p-shadow-popover);
  border-radius: var(--p-border-radius-2);
  backface-visibility: hidden;
  will-change: left, top;
}

.Polaris-Popover__PopoverOverlay {
  opacity: 0;
  transition: opacity var(--p-duration-100) var(--p-ease), transform var(--p-duration-100) var(--p-ease);
  transform: translateY(-.3125rem);
}

.Polaris-Popover__PopoverOverlay--entering {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-Popover__PopoverOverlay--open {
  opacity: 1;
  transform: none;
}

.Polaris-Popover__PopoverOverlay--exiting {
  opacity: 1;
  transition-duration: var(--p-duration-0);
  transform: translateY(0);
}

.Polaris-Popover--measuring:not(.Polaris-Popover__PopoverOverlay--exiting) {
  opacity: 0;
  transform: translateY(-.3125rem);
}

.Polaris-Popover--fullWidth {
  margin: .3125rem auto 0;
}

.Polaris-Popover--fullWidth .Polaris-Popover__Content {
  max-width: none;
}

.Polaris-Popover--positionedAbove {
  margin: var(--p-space-4) var(--p-space-2) .3125rem;
}

.Polaris-Popover--positionedAbove.Polaris-Popover--fullWidth {
  margin: 0 auto .3125rem;
}

.Polaris-Popover__Wrapper {
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-2);
  outline: var(--p-border-width-1) solid transparent;
  position: relative;
  overflow: hidden;
}

.Polaris-Popover__Content {
  border-radius: var(--p-border-radius-1);
  max-width: 25rem;
  max-height: 31.25rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Polaris-Popover__Content:focus {
  outline: none;
}

.Polaris-Popover__Content--fullHeight {
  max-height: none;
}

.Polaris-Popover__Content--fluidContent {
  max-height: none;
  max-width: none;
}

.Polaris-Popover__Pane {
  max-width: 100%;
  flex: 1;
}

.Polaris-Popover__Pane + .Polaris-Popover__Pane {
  border-top: var(--p-border-divider);
}

.Polaris-Popover__Pane:focus {
  outline: none;
}

.Polaris-Popover__Pane--fixed {
  flex: none;
  overflow: visible;
}

.Polaris-Popover__Section {
  padding: var(--p-space-4);
}

.Polaris-Popover__Section + .Polaris-Popover__Section {
  border-top: var(--p-border-divider);
}

.Polaris-Popover__FocusTracker {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

@media print {
  .Polaris-Popover__PopoverOverlay--hideOnPrint {
    display: none !important;
  }
}

.Polaris-PositionedOverlay {
  z-index: var(--p-z-2);
  position: absolute;
}

.Polaris-PositionedOverlay--fixed {
  position: fixed;
}

.Polaris-PositionedOverlay--calculating {
  visibility: hidden;
}

.Polaris-PositionedOverlay--preventInteraction {
  pointer-events: none;
}

.Polaris-Badge {
  --pc-badge-horizontal-padding: var(--p-space-2);
  --pc-badge-vertical-padding: var(--p-space-05);
  padding: var(--pc-badge-vertical-padding) var(--pc-badge-horizontal-padding);
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-5);
  font-size: var(--p-font-size-2);
  line-height: var(--p-line-height-1);
  color: var(--p-text);
  font-weight: var(--p-font-weight-regular);
  align-items: center;
  display: inline-flex;
}

@media print {
  .Polaris-Badge {
    border: solid var(--p-border-width-1) var(--p-border);
  }
}

.Polaris-Badge--sizeSmall {
  font-size: var(--p-font-size-1);
}

.Polaris-Badge--statusSuccess {
  background-color: var(--p-surface-success);
  color: var(--p-text);
}

.Polaris-Badge--statusInfo {
  background-color: var(--p-surface-highlight);
  color: var(--p-text);
}

.Polaris-Badge--statusAttention {
  background-color: var(--p-surface-attention);
  color: var(--p-text);
}

.Polaris-Badge--statusWarning {
  background-color: var(--p-surface-warning);
  color: var(--p-text);
}

.Polaris-Badge--statusCritical {
  background-color: var(--p-surface-critical);
  color: var(--p-text);
}

.Polaris-Badge--statusNew {
  color: var(--p-text);
  font-weight: var(--p-font-weight-medium);
  border: none;
}

.Polaris-Badge--withinFilter {
  border-radius: var(--p-border-radius-1);
}

.Polaris-Badge__Icon {
  margin-left: calc((var(--p-space-1)) * -1);
}

.Polaris-Badge__Icon + :not(.Polaris-Badge__Icon) {
  margin-left: var(--p-space-1);
}

.Polaris-Badge__PipContainer {
  margin-left: calc(var(--p-space-05) * -1);
  margin-right: var(--p-space-1);
  align-items: center;
  display: grid;
}

.Polaris-Badge-Pip {
  --pc-pip-size: var(--p-space-2);
  --pc-pip-color: var(--p-icon);
  color: var(--pc-pip-color);
  height: var(--pc-pip-size);
  width: var(--pc-pip-size);
  border: var(--p-border-width-2) solid var(--pc-pip-color);
  border-radius: var(--p-border-radius-half);
  flex-shrink: 0;
  display: inline-block;
}

.Polaris-Badge-Pip--statusInfo {
  --pc-pip-color: var(--p-icon-highlight);
}

.Polaris-Badge-Pip--statusSuccess {
  --pc-pip-color: var(--p-icon-success);
}

.Polaris-Badge-Pip--statusNew {
  --pc-pip-color: var(--p-icon);
}

.Polaris-Badge-Pip--statusAttention {
  --pc-pip-color: var(--p-icon-attention);
}

.Polaris-Badge-Pip--statusWarning {
  --pc-pip-color: var(--p-icon-warning);
}

.Polaris-Badge-Pip--statusCritical {
  --pc-pip-color: var(--p-icon-critical);
}

.Polaris-Badge-Pip--progressIncomplete {
  background: none;
}

.Polaris-Badge-Pip--progressPartiallyComplete {
  background: linear-gradient(to top, currentColor, currentColor 50%, #0000 50%, #0000);
}

@media print {
  .Polaris-Badge-Pip--progressPartiallyComplete {
    background: none;
    box-shadow: inset 0 -6.375rem 0 -6.25rem;
  }
}

.Polaris-Badge-Pip--progressComplete {
  background: currentColor;
}

@media print {
  .Polaris-Badge-Pip--progressComplete {
    background: none;
    box-shadow: inset 0 0 0 6.25rem;
  }
}

.Polaris-TextStyle--variationPositive {
  color: var(--p-text-success);
}

.Polaris-TextStyle--variationNegative {
  color: var(--p-text-critical);
}

.Polaris-TextStyle--variationWarning {
  color: var(--p-text-warning);
}

.Polaris-TextStyle--variationCode {
  padding: 0 var(--p-space-1);
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-surface-subdued);
  font-size: var(--p-font-size-5);
  box-shadow: inset 0 0 0 .0625rem var(--p-border-subdued);
  display: inline-block;
  position: relative;
}

.Polaris-TextStyle--variationCode:after {
  content: "";
  width: 100%;
  height: 100%;
  border: var(--p-border-width-1) solid transparent;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-TextStyle--variationStrong {
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-TextStyle--variationSubdued {
  color: var(--p-text-subdued);
}

@media print {
  .Polaris-TextStyle--variationSubdued {
    color: var(--p-text-subdued);
  }
}

.Polaris-ActionList {
  outline: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ActionList__Section--withoutTitle:not(:first-child) {
  border-top: var(--p-border-divider);
}

.Polaris-ActionList__Actions {
  border-top: var(--p-border-divider);
  padding: var(--p-space-2);
  outline: none;
  margin: 0;
  list-style: none;
}

.Polaris-ActionList > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions, .Polaris-ActionList__Section:first-child > .Polaris-ActionList__Section--withoutTitle .Polaris-ActionList__Actions {
  border-top: none;
}

.Polaris-ActionList__Title {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-1);
  text-transform: uppercase;
  padding: var(--p-space-3) var(--p-space-4) var(--p-space-3) var(--p-space-4);
}

@media (min-width: 40em) {
  .Polaris-ActionList__Title {
    font-size: var(--p-font-size-1);
  }
}

.Polaris-ActionList__Section:not(:first-child) .Polaris-ActionList__Title {
  padding-top: var(--p-space-1);
}

.Polaris-ActionList__Item {
  --pc-action-list-image-size: 1.25rem;
  --pc-action-list-item-min-height: var(--p-space-10);
  --pc-action-list-item-vertical-padding: calc((var(--pc-action-list-item-min-height)  - var(--p-line-height-2)) / 2);
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  width: 100%;
  min-height: var(--pc-action-list-item-min-height);
  text-align: left;
  cursor: pointer;
  padding: 0;
  padding: var(--pc-action-list-item-vertical-padding) var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  border: none;
  border-top: var(--p-border-width-1) solid transparent;
  color: inherit;
  background: none;
  margin: 0;
  text-decoration: none;
  display: block;
  position: relative;
}

.Polaris-ActionList__Item:focus {
  outline: none;
}

.Polaris-ActionList__Item:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

@media (forced-colors: active) {
  .Polaris-ActionList__Item {
    border: var(--p-border-width-1) solid transparent;
  }
}

.Polaris-ActionList__Item:hover {
  background-color: var(--p-surface-hovered);
  outline: var(--p-border-width-3) solid transparent;
  text-decoration: none;
}

.Polaris-ActionList__Item:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-ActionList__Item:active svg {
  fill: var(--p-interactive);
}

.Polaris-ActionList__Item:focus:not(:active) {
  outline: var(--p-border-width-3) solid transparent;
}

.Polaris-ActionList__Item:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-ActionList__Item:visited {
  color: inherit;
}

.Polaris-ActionList__Item.Polaris-ActionList--active {
  background-color: var(--p-surface-selected);
}

.Polaris-ActionList__Item.Polaris-ActionList--active svg {
  fill: var(--p-interactive);
}

.Polaris-ActionList__Item.Polaris-ActionList--active:before {
  content: "";
  background-color: var(--p-interactive);
  top: 0;
  left: calc(var(--p-space-2) * -1);
  height: 100%;
  width: var(--p-border-width-3);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
  display: block;
  position: absolute;
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive:hover {
  background-color: var(--p-surface-critical-subdued-hovered);
}

.Polaris-ActionList__Item.Polaris-ActionList--destructive:active, .Polaris-ActionList__Item.Polaris-ActionList--destructive.Polaris-ActionList--active {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-ActionList__Item.Polaris-ActionList--disabled {
  color: var(--p-text-disabled);
  background-image: none;
}

.Polaris-ActionList__Item.Polaris-ActionList--disabled .Polaris-ActionList__Prefix svg, .Polaris-ActionList__Item.Polaris-ActionList--disabled .Polaris-ActionList__Suffix svg {
  fill: var(--p-icon-disabled);
}

.Polaris-ActionList__Content {
  align-items: center;
  display: flex;
}

.Polaris-ActionList__Prefix {
  height: var(--pc-action-list-image-size);
  width: var(--pc-action-list-image-size);
  border-radius: var(--p-border-radius-base);
  margin: calc(var(--pc-action-list-image-size) * -.5) var(--p-space-4) calc(var(--pc-action-list-image-size) * -.5) 0;
  background-position: center;
  background-size: cover;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Polaris-ActionList__Prefix svg {
  fill: var(--p-icon);
}

.Polaris-ActionList__Suffix {
  margin-left: var(--p-space-4);
}

.Polaris-ActionList__Suffix svg {
  fill: var(--p-icon);
}

.Polaris-ActionList__ContentBlock, .Polaris-ActionList__ContentBlockInner {
  display: block;
}

.Polaris-ActionList__Text {
  min-width: 0;
  max-width: 100%;
  flex: auto;
}

.Polaris-Button {
  --pc-button-slim-min-height: var(--p-line-height-4);
  --pc-button-large-min-height: var(--p-line-height-7);
  --pc-button-vertical-padding: calc((var(--p-line-height-6)  - var(--p-line-height-2)  - var(--p-space-05)) / 2);
  --pc-button-slim-vertical-padding: calc((var(--pc-button-slim-min-height)  - var(--p-line-height-2)  - var(--p-space-05)) / 2);
  --pc-button-large-vertical-padding: calc((var(--pc-button-large-min-height)  - var(--p-line-height-2)  - var(--p-space-05)) / 2);
  --pc-button-spinner-size: 1.25rem;
  --pc-button-segment: 10;
  --pc-button-focused: 20;
  min-height: var(--p-line-height-6);
  min-width: var(--p-line-height-6);
  padding: calc((var(--p-line-height-6)  - var(--p-line-height-2)  - var(--p-space-05)) / 2) var(--p-space-4);
  background: var(--p-surface);
  box-shadow: var(--p-shadow-button);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.Polaris-Button svg {
  fill: var(--p-icon);
}

.Polaris-Button:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-border-width-1) * -1 + -.0625rem);
  right: calc(var(--p-border-width-1) * -1 + -.0625rem);
  bottom: calc(var(--p-border-width-1) * -1 + -.0625rem);
  left: calc(var(--p-border-width-1) * -1 + -.0625rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-1) * -1 + -.0625rem) var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
}

.Polaris-Button:hover {
  background: var(--p-action-secondary-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button:focus {
  box-shadow: var(--p-shadow-button);
  outline: 0;
}

.Polaris-Button:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-shadow-button);
}

.Polaris-Button:active:after {
  box-shadow: none;
  border: none;
}

.Polaris-Button.Polaris-Button--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-shadows-inset-button-pressed);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Button.Polaris-Button--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Button {
    border: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-Button.Polaris-Button--disabled {
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  transition: none;
}

.Polaris-Button.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button.Polaris-Button--connectedDisclosure {
  z-index: var(--pc-button-segment);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Button.Polaris-Button--connectedDisclosure:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-Button.Polaris-Button--connectedDisclosure:focus {
  z-index: var(--pc-button-focused);
}

.Polaris-Button__Content {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-line-height-1);
  text-transform: initial;
  letter-spacing: initial;
  min-width: .0625rem;
  min-height: .0625rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-Button__Content {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Button:not(.Polaris-Button--plain) .Polaris-Button__Content {
  width: 100%;
}

.Polaris-Button--textAlignLeft {
  text-align: left;
  justify-content: flex-start;
}

.Polaris-Button--textAlignLeft .Polaris-Button__Content {
  justify-content: flex-start;
}

.Polaris-Button--textAlignStart {
  text-align: start;
  justify-content: flex-start;
}

.Polaris-Button--textAlignStart .Polaris-Button__Content {
  justify-content: flex-start;
}

.Polaris-Button--textAlignCenter {
  text-align: center;
  justify-content: center;
}

.Polaris-Button--textAlignRight {
  text-align: right;
  justify-content: flex-end;
}

.Polaris-Button--textAlignRight .Polaris-Button__Content {
  justify-content: flex-end;
}

.Polaris-Button--textAlignEnd {
  text-align: end;
  justify-content: flex-end;
}

.Polaris-Button--textAlignEnd .Polaris-Button__Content {
  justify-content: flex-end;
}

.Polaris-Button__Icon {
  margin-left: calc((var(--p-space-1)) * -1);
}

.Polaris-Button__Icon:last-child {
  margin-right: calc((var(--p-space-2)) * -1);
  margin-left: var(--p-space-1);
}

.Polaris-Button--fullWidth.Polaris-Button--textAlignLeft .Polaris-Button__Icon:last-child:not(:only-child) {
  margin-left: auto;
}

.Polaris-Button__Icon + :not(.Polaris-Button__Icon) {
  margin-left: var(--p-space-1);
}

.Polaris-Button--hidden {
  visibility: hidden;
}

.Polaris-Button__Spinner {
  margin-top: calc((var(--pc-button-spinner-size) / 2) * -1);
  margin-left: calc((var(--pc-button-spinner-size) / 2) * -1);
  position: absolute;
  top: 50%;
  left: 50%;
}

.Polaris-Button--primary, .Polaris-Button--destructive {
  background: var(--pc-button-color);
  box-shadow: var(--p-shadow-button), var(--p-shadows-inset-button);
  color: var(--pc-button-text);
  border-width: 0;
  border-color: #0000;
  position: relative;
}

.Polaris-Button--primary:after, .Polaris-Button--destructive:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Button--primary:hover, .Polaris-Button--destructive:hover {
  background: var(--pc-button-color-hover);
  color: var(--pc-button-text);
  border-color: #0000;
}

.Polaris-Button--primary:focus, .Polaris-Button--destructive:focus {
  box-shadow: var(--p-shadow-button), var(--p-shadows-inset-button);
  border-color: #0000;
}

.Polaris-Button--primary:active, .Polaris-Button--destructive:active {
  background: var(--pc-button-color-active);
  box-shadow: var(--p-shadow-button), var(--p-shadows-inset-button);
  border-color: #0000;
}

.Polaris-Button--primary.Polaris-Button--pressed, .Polaris-Button--destructive.Polaris-Button--pressed {
  color: var(--pc-button-text);
  background: var(--pc-button-color-depressed);
  box-shadow: var(--p-shadow-button), var(--p-shadows-inset-button);
  border-color: #0000;
}

.Polaris-Button--primary.Polaris-Button--pressed:hover, .Polaris-Button--primary.Polaris-Button--pressed:focus, .Polaris-Button--destructive.Polaris-Button--pressed:hover, .Polaris-Button--destructive.Polaris-Button--pressed:focus {
  background: var(--pc-button-color-depressed);
  box-shadow: var(--p-shadow-button), var(--p-shadows-inset-button);
}

.Polaris-Button--primary.Polaris-Button--disabled, .Polaris-Button--destructive.Polaris-Button--disabled {
  color: var(--p-text-disabled);
  box-shadow: none;
  border-color: #0000;
}

.Polaris-Button--primary.Polaris-Button--disabled svg, .Polaris-Button--destructive.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button--primary {
  --pc-button-color: var(--p-action-primary);
  --pc-button-text: var(--p-text-on-primary);
  --pc-button-color-hover: var(--p-action-primary-hovered);
  --pc-button-color-active: var(--p-action-primary-pressed);
  --pc-button-color-depressed: var(--p-action-primary-depressed);
}

.Polaris-Button--primary svg {
  fill: var(--p-icon-on-primary);
}

.Polaris-Button--primary.Polaris-Button--disabled {
  background: var(--p-action-primary-disabled);
}

.Polaris-Button--destructive {
  --pc-button-color: var(--p-action-critical);
  --pc-button-text: var(--p-text-on-critical);
  --pc-button-color-hover: var(--p-action-critical-hovered);
  --pc-button-color-active: var(--p-action-critical-pressed);
  --pc-button-color-depressed: var(--p-action-critical-depressed);
}

.Polaris-Button--destructive svg {
  fill: var(--p-icon-on-critical);
}

.Polaris-Button--destructive.Polaris-Button--disabled {
  background: var(--p-action-critical-disabled);
}

.Polaris-Button--outline {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  color: var(--p-text);
  background: none;
  position: relative;
}

.Polaris-Button--outline:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-Button--outline:hover {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-hovered);
}

.Polaris-Button--outline:focus {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
}

.Polaris-Button--outline:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--outline:active {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-pressed);
}

.Polaris-Button--outline:active:after {
  box-shadow: none;
}

.Polaris-Button--outline.Polaris-Button--pressed {
  background: var(--p-action-secondary-pressed);
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  color: var(--pc-button-text);
}

.Polaris-Button--outline.Polaris-Button--disabled {
  border: var(--p-border-width-1) solid var(--p-border-disabled);
  box-shadow: none;
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-Button--outline.Polaris-Button--destructive {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  box-shadow: none;
  color: var(--p-interactive-critical);
  background: none;
}

.Polaris-Button--outline.Polaris-Button--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive:hover {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--outline.Polaris-Button--destructive:focus {
  border: var(--p-border-width-1) solid var(--p-border-critical);
}

.Polaris-Button--outline.Polaris-Button--destructive:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--outline.Polaris-Button--destructive:active {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--disabled {
  border: var(--p-border-width-1) solid var(--p-border-critical-disabled);
  color: var(--p-interactive-critical-disabled);
  background: none;
}

.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--pressed {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
  color: var(--p-interactive-critical);
}

.Polaris-Button--outline.Polaris-Button--disabled {
  box-shadow: none;
  background: none;
}

.Polaris-Button--destructive.Polaris-Button--outline {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  color: var(--p-text);
  background: none;
  position: relative;
}

.Polaris-Button--destructive.Polaris-Button--outline:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-Button--destructive.Polaris-Button--outline:hover {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-hovered);
}

.Polaris-Button--destructive.Polaris-Button--outline:focus {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
}

.Polaris-Button--destructive.Polaris-Button--outline:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--destructive.Polaris-Button--outline:active {
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  background: var(--p-surface-pressed);
}

.Polaris-Button--destructive.Polaris-Button--outline:active:after {
  box-shadow: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--pressed {
  background: var(--p-action-secondary-pressed);
  border: var(--p-border-width-1) solid var(--p-border);
  box-shadow: none;
  color: var(--pc-button-text);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--disabled {
  border: var(--p-border-width-1) solid var(--p-border-disabled);
  box-shadow: none;
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  box-shadow: none;
  color: var(--p-interactive-critical);
  background: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:hover {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:focus {
  border: var(--p-border-width-1) solid var(--p-border-critical);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive:active {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--disabled {
  border: var(--p-border-width-1) solid var(--p-border-critical-disabled);
  color: var(--p-interactive-critical-disabled);
  background: none;
}

.Polaris-Button--destructive.Polaris-Button--outline.Polaris-Button--destructive.Polaris-Button--pressed {
  border: var(--p-border-width-1) solid var(--p-border-critical);
  background: var(--p-surface-critical-subdued);
  color: var(--p-interactive-critical);
}

.Polaris-Button--disabled {
  cursor: default;
  pointer-events: none;
}

.Polaris-Button--loading {
  position: relative;
}

.Polaris-Button--loading, .Polaris-Button--loading:hover, .Polaris-Button--loading.Polaris-Button--disabled {
  color: #0000;
}

.Polaris-Button--loading.Polaris-Button--disabled svg {
  fill: var(--p-icon-subdued);
}

.Polaris-Button--plain {
  margin: calc(var(--pc-button-vertical-padding) * -1) calc(var(--p-space-2) * -1);
  padding-left: var(--p-space-2);
  padding-right: var(--p-space-2);
  box-shadow: none;
  color: var(--p-interactive);
  background: none;
  border: 0;
}

.Polaris-Button--plain svg {
  fill: var(--p-interactive);
}

.Polaris-Button--plain > .Polaris-Button__Content {
  font-weight: var(--p-font-weight-regular);
  position: relative;
}

.Polaris-Button--plain > .Polaris-Button__Content:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Button--plain:hover, .Polaris-Button--plain:focus {
  color: var(--p-interactive-hovered);
  box-shadow: none;
  background: none;
}

.Polaris-Button--plain:hover svg, .Polaris-Button--plain:focus svg {
  fill: var(--p-interactive-hovered);
}

.Polaris-Button--plain:hover:not(.Polaris-Button--removeUnderline), .Polaris-Button--plain:focus:not(.Polaris-Button--removeUnderline) {
  text-decoration: underline;
}

.Polaris-Button--plain.Polaris-Button--pressed, .Polaris-Button--plain:active {
  color: var(--p-interactive-pressed);
  box-shadow: none;
  background: none;
}

.Polaris-Button--plain.Polaris-Button--pressed svg, .Polaris-Button--plain:active svg {
  fill: var(--p-interactive-pressed);
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain.Polaris-Button--pressed, .Polaris-Button--plain:active {
    outline: none;
  }
}

.Polaris-Button--plain:focus:after {
  content: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain:focus {
    outline: none;
  }
}

@media (-ms-high-contrast: active) {
  .Polaris-Button--plain:focus > .Polaris-Button__Content {
    outline: var(--p-border-width-2) dotted;
  }
}

.Polaris-Button--plain:focus:not(:active) > .Polaris-Button__Content:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--plain:active:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content, .Polaris-Button--plain.Polaris-Button--pressed:not(.Polaris-Button--iconOnly) > .Polaris-Button__Content {
  padding: var(--p-space-05) var(--p-space-1);
  margin: calc(var(--p-space-05) * -1) calc(var(--p-space-1) * -1);
  border-radius: var(--p-border-radius-base);
  background: none;
}

.Polaris-Button--plain.Polaris-Button--fullWidth {
  margin-left: 0;
  margin-right: 0;
}

.Polaris-Button--plain.Polaris-Button--disabled {
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-Button--plain.Polaris-Button--disabled.Polaris-Button--loading {
  color: #0000;
}

.Polaris-Button--plain.Polaris-Button--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive svg {
  fill: var(--p-interactive-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive:hover {
  color: var(--p-interactive-critical-hovered);
}

.Polaris-Button--plain.Polaris-Button--destructive:active, .Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--pressed {
  color: var(--p-interactive-critical-pressed);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--disabled {
  color: var(--p-interactive-critical-disabled);
}

.Polaris-Button--plain.Polaris-Button--sizeSlim {
  margin-top: calc(var(--pc-button-slim-vertical-padding) * -1);
  margin-bottom: calc(var(--pc-button-slim-vertical-padding) * -1);
}

.Polaris-Button--plain.Polaris-Button--sizeLarge {
  margin: calc(var(--pc-button-large-vertical-padding) * -1) calc(var(--p-space-5) * -1);
}

.Polaris-Button--plain.Polaris-Button--iconOnly {
  margin: calc((var(--p-line-height-6)  - var(--p-icon-size-medium)) * -.5);
}

.Polaris-Button--plain.Polaris-Button--iconOnly svg {
  fill: var(--p-icon);
}

.Polaris-Button--plain.Polaris-Button--iconOnly:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Button--plain.Polaris-Button--iconOnly:active svg, .Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--pressed svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly svg {
  fill: var(--p-action-critical);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly:hover svg {
  fill: var(--p-action-critical-hovered);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly:active svg, .Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--pressed svg {
  fill: var(--p-action-critical-pressed);
}

.Polaris-Button--plain.Polaris-Button--destructive.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: var(--p-action-critical-disabled);
}

.Polaris-Button--plain .Polaris-Button__Icon {
  margin-left: 0;
  margin-right: 0;
}

.Polaris-Button--fullWidth {
  width: 100%;
  display: flex;
}

.Polaris-Button--iconOnly {
  padding-left: var(--p-space-2);
  padding-right: var(--p-space-2);
}

.Polaris-Button--iconOnly.Polaris-Button--sizeLarge {
  padding-left: var(--p-space-3);
  padding-right: var(--p-space-3);
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:first-child {
  margin-left: 0;
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:last-child {
  margin-right: calc(var(--p-space-1) * -1);
}

.Polaris-Button--iconOnly .Polaris-Button__Icon:only-child {
  margin-right: 0;
}

.Polaris-Button--sizeSlim {
  min-height: var(--pc-button-slim-min-height);
  padding: var(--pc-button-slim-vertical-padding) var(--p-space-3);
}

.Polaris-Button--sizeLarge {
  min-height: var(--pc-button-large-min-height);
  min-width: var(--pc-button-large-min-height);
  padding: var(--pc-button-large-vertical-padding) var(--p-space-6);
}

.Polaris-Button--sizeLarge .Polaris-Button__Content {
  font-size: var(--p-font-size-6);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
}

@media (min-width: 40em) {
  .Polaris-Button--sizeLarge .Polaris-Button__Content {
    font-size: var(--p-font-size-5);
  }
}

.Polaris-Button--monochrome.Polaris-Button--outline, .Polaris-Button--monochrome.Polaris-Button--plain {
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline svg, .Polaris-Button--monochrome.Polaris-Button--plain svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover, .Polaris-Button--monochrome.Polaris-Button--outline:focus, .Polaris-Button--monochrome.Polaris-Button--outline:active, .Polaris-Button--monochrome.Polaris-Button--plain:hover, .Polaris-Button--monochrome.Polaris-Button--plain:focus, .Polaris-Button--monochrome.Polaris-Button--plain:active {
  color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover svg, .Polaris-Button--monochrome.Polaris-Button--outline:focus svg, .Polaris-Button--monochrome.Polaris-Button--outline:active svg, .Polaris-Button--monochrome.Polaris-Button--plain:hover svg, .Polaris-Button--monochrome.Polaris-Button--plain:focus svg, .Polaris-Button--monochrome.Polaris-Button--plain:active svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled {
  color: currentColor;
  opacity: .4;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--disabled svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--loading .Polaris-Button__Text, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--loading .Polaris-Button__Text {
  visibility: hidden;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:focus svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly:active svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:focus svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly:active svg, .Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--iconOnly.Polaris-Button--disabled svg, .Polaris-Button--monochrome.Polaris-Button--plain.Polaris-Button--iconOnly.Polaris-Button--disabled svg {
  fill: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--plain .Polaris-Button__Text:not(.Polaris-Button--removeUnderline) {
  text-decoration: underline;
}

.Polaris-Button--monochrome.Polaris-Button--outline {
  box-shadow: 0 0 0 var(--p-border-width-1) currentColor;
  border-color: currentColor;
  position: relative;
}

.Polaris-Button--monochrome.Polaris-Button--outline:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.1875rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.1875rem;
}

.Polaris-Button--monochrome.Polaris-Button--outline:before {
  content: "";
  opacity: 0;
  z-index: 0;
  background-color: currentColor;
  position: absolute;
  inset: 0;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed {
  box-shadow: none;
  color: currentColor;
  background: none;
}

.Polaris-Button--monochrome.Polaris-Button--outline.Polaris-Button--pressed:before {
  opacity: .2;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover, .Polaris-Button--monochrome.Polaris-Button--outline:focus, .Polaris-Button--monochrome.Polaris-Button--outline:active {
  background-color: #0000;
  border-color: currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover:before, .Polaris-Button--monochrome.Polaris-Button--outline:focus:before, .Polaris-Button--monochrome.Polaris-Button--outline:active:before {
  opacity: .07;
}

.Polaris-Button--monochrome.Polaris-Button--outline:focus {
  box-shadow: 0 0 0 var(--p-border-width-1) currentColor;
}

.Polaris-Button--monochrome.Polaris-Button--outline:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Button--monochrome.Polaris-Button--outline:active:after {
  box-shadow: none;
}

.Polaris-Button--monochrome.Polaris-Button--outline:hover:before, .Polaris-Button--monochrome.Polaris-Button--outline:active:before {
  opacity: .05;
}

.Polaris-Button__ConnectedDisclosureWrapper {
  display: flex;
}

.Polaris-Button__ConnectedDisclosure {
  z-index: var(--pc-button-segment);
  margin-left: calc(var(--p-space-025) * -1);
  height: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Polaris-Button__ConnectedDisclosure:focus, .Polaris-Button__ConnectedDisclosure:active {
  z-index: var(--pc-button-focused);
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--primary, .Polaris-Button__ConnectedDisclosure.Polaris-Button--destructive {
  margin-left: var(--p-space-025);
}

.Polaris-Button__ConnectedDisclosure.Polaris-Button--primary.Polaris-Button--outline, .Polaris-Button__ConnectedDisclosure.Polaris-Button--destructive.Polaris-Button--outline {
  border-left: 0;
  margin-left: 0;
}

.Polaris-Button__ConnectedDisclosure:after {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

[data-buttongroup-segmented="true"] .Polaris-Button, [data-buttongroup-segmented="true"] .Polaris-Button:after {
  border-radius: 0;
}

[data-buttongroup-segmented="true"] > :first-child .Polaris-Button, [data-buttongroup-segmented="true"] > :first-child .Polaris-Button:after {
  border-radius: 0;
  border-top-left-radius: var(--p-border-radius-1);
  border-bottom-left-radius: var(--p-border-radius-1);
}

[data-buttongroup-segmented="true"] > :last-child .Polaris-Button, [data-buttongroup-segmented="true"] > :last-child .Polaris-Button:after {
  border-radius: 0;
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

[data-buttongroup-segmented="true"] > :last-child:first-child .Polaris-Button, [data-buttongroup-segmented="true"] > :last-child:first-child .Polaris-Button:after {
  border-radius: var(--p-border-radius-1);
}

[data-buttongroup-connected-top="true"] > :first-child .Polaris-Button, [data-buttongroup-connected-top="true"] > :first-child .Polaris-Button:after {
  border-top-left-radius: 0;
}

[data-buttongroup-connected-top="true"] > :last-child .Polaris-Button, [data-buttongroup-connected-top="true"] > :last-child .Polaris-Button:after {
  border-top-right-radius: 0;
}

[data-buttongroup-full-width="true"] .Polaris-Button {
  width: 100%;
  display: flex;
}

.Polaris-ButtonGroup {
  --pc-button-group-item: 10;
  --pc-button-group-focused: 20;
  margin-top: calc(var(--p-space-2) * -1);
  margin-left: calc(var(--p-space-2) * -1);
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.Polaris-ButtonGroup__Item {
  margin-top: var(--p-space-2);
  margin-left: var(--p-space-2);
}

.Polaris-ButtonGroup__Item--plain:not(:first-child) {
  margin-left: var(--p-space-4);
}

.Polaris-ButtonGroup__Item--plain:not(:last-child) {
  margin-right: var(--p-space-2);
}

.Polaris-ButtonGroup--segmented {
  flex-wrap: nowrap;
  margin-top: 0;
  margin-left: 0;
  display: flex;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item {
  z-index: var(--pc-button-group-item);
  margin-top: 0;
  margin-left: 0;
  line-height: normal;
  position: relative;
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item:not(:first-child) {
  margin-left: calc(var(--p-space-025) * -1);
}

.Polaris-ButtonGroup--segmented .Polaris-ButtonGroup__Item--focused {
  z-index: var(--pc-button-group-focused);
}

.Polaris-ButtonGroup--fullWidth .Polaris-ButtonGroup__Item {
  flex: auto;
}

.Polaris-ButtonGroup--extraTight {
  margin-top: calc(var(--p-space-1) * -1);
  margin-left: calc(var(--p-space-1) * -1);
}

.Polaris-ButtonGroup--extraTight .Polaris-ButtonGroup__Item {
  margin-top: var(--p-space-1);
  margin-left: var(--p-space-1);
}

.Polaris-ButtonGroup--tight {
  margin-top: calc(var(--p-space-2) * -1);
  margin-left: calc(var(--p-space-2) * -1);
}

.Polaris-ButtonGroup--tight .Polaris-ButtonGroup__Item {
  margin-top: var(--p-space-2);
  margin-left: var(--p-space-2);
}

.Polaris-ButtonGroup--loose {
  margin-top: calc(var(--p-space-5) * -1);
  margin-left: calc(var(--p-space-5) * -1);
}

.Polaris-ButtonGroup--loose .Polaris-ButtonGroup__Item {
  margin-top: var(--p-space-5);
  margin-left: var(--p-space-5);
}

.Polaris-Stack {
  --pc-stack-spacing: var(--p-space-4);
  margin-top: calc(var(--pc-stack-spacing) * -1);
  margin-left: calc(var(--pc-stack-spacing) * -1);
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
}

.Polaris-Stack > .Polaris-Stack__Item {
  margin-top: var(--pc-stack-spacing);
  margin-left: var(--pc-stack-spacing);
  max-width: 100%;
}

.Polaris-Stack--noWrap {
  flex-wrap: nowrap;
}

.Polaris-Stack--spacingNone {
  --pc-stack-spacing: var(--p-space-0);
}

.Polaris-Stack--spacingExtraTight {
  --pc-stack-spacing: var(--p-space-1);
}

.Polaris-Stack--spacingTight {
  --pc-stack-spacing: var(--p-space-2);
}

.Polaris-Stack--spacingBaseTight {
  --pc-stack-spacing: var(--p-space-3);
}

.Polaris-Stack--spacingLoose {
  --pc-stack-spacing: var(--p-space-5);
}

.Polaris-Stack--spacingExtraLoose {
  --pc-stack-spacing: var(--p-space-8);
}

.Polaris-Stack--distributionLeading {
  justify-content: flex-start;
}

.Polaris-Stack--distributionTrailing {
  justify-content: flex-end;
}

.Polaris-Stack--distributionCenter {
  justify-content: center;
}

.Polaris-Stack--distributionEqualSpacing {
  justify-content: space-between;
}

.Polaris-Stack--distributionFill > .Polaris-Stack__Item, .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
  flex: auto;
}

@supports ((min-width: -webkit-fit-content) or (min-width: -moz-fit-content) or (min-width: fit-content)) {
  .Polaris-Stack--distributionFillEvenly > .Polaris-Stack__Item {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    flex: 1 0;
  }
}

.Polaris-Stack--alignmentLeading {
  align-items: flex-start;
}

.Polaris-Stack--alignmentTrailing {
  align-items: flex-end;
}

.Polaris-Stack--alignmentCenter {
  align-items: center;
}

.Polaris-Stack--alignmentFill {
  align-items: stretch;
}

.Polaris-Stack--alignmentBaseline {
  align-items: baseline;
}

.Polaris-Stack--vertical {
  margin-left: var(--p-space-0);
  flex-direction: column;
}

.Polaris-Stack--vertical > .Polaris-Stack__Item {
  margin-left: var(--p-space-0);
}

.Polaris-Stack__Item {
  min-width: 0;
  flex: none;
}

.Polaris-Stack__Item--fill {
  flex: auto;
}

.Polaris-Heading {
  font-size: var(--p-font-size-6);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-3);
  margin: 0;
}

@media (min-width: 40em) {
  .Polaris-Heading {
    font-size: var(--p-font-size-5);
  }
}

@media print {
  .Polaris-Heading {
    font-size: var(--p-font-size-3);
    line-height: var(--p-line-height-1);
  }
}

.Polaris-Card {
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-card);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Card + .Polaris-Card {
  margin-top: var(--p-space-4);
}

@media print {
  .Polaris-Card + .Polaris-Card {
    margin-top: calc(var(--p-space-2) * -1);
  }
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card {
    border-radius: var(--p-border-radius-2);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card {
    border-radius: var(--p-border-radius-2);
  }
}

@media print {
  .Polaris-Card {
    box-shadow: none;
  }
}

.Polaris-Card--subdued {
  background-color: var(--p-surface-subdued);
}

@media print {
  .Polaris-Card__Section--hideOnPrint, .Polaris-Card--hideOnPrint {
    display: none !important;
  }
}

.Polaris-Card__Header {
  padding: var(--p-space-4) var(--p-space-4) 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Header {
    padding: var(--p-space-5) var(--p-space-5) 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Header {
    padding: var(--p-space-5) var(--p-space-5) 0;
  }
}

@media print and (max-width: 48.0625em) and (min-width: 30.625em), print and (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Header {
    padding: var(--p-space-2) var(--p-space-4) var(--p-space-0);
  }
}

@media print and (min-width: 30.625em) {
  .Polaris-Card__Header {
    padding: var(--p-space-2) var(--p-space-4) var(--p-space-0);
  }
}

.Polaris-Card__Section {
  padding: var(--p-space-4);
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section {
    padding: var(--p-space-5);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section {
    padding: var(--p-space-5);
  }
}

.Polaris-Card__Section + .Polaris-Card__Section {
  border-top: var(--p-border-divider);
}

@media print {
  .Polaris-Card__Section + .Polaris-Card__Section {
    border-top: 0;
  }
}

@media print {
  .Polaris-Card__Section {
    padding-top: var(--p-space-1);
    padding-bottom: var(--p-space-1);
  }
}

.Polaris-Card__Section:first-child {
  border-top-left-radius: var(--p-border-radius-2);
  border-top-right-radius: var(--p-border-radius-2);
}

.Polaris-Card__Section:last-child {
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-Card__Section--fullWidth {
  padding: var(--p-space-4) var(--p-space-0);
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--fullWidth {
    padding: var(--p-space-5) var(--p-space-0);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--fullWidth {
    padding: var(--p-space-5) var(--p-space-0);
  }
}

.Polaris-Card__Section--flush {
  padding: var(--p-space-0);
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--flush {
    padding: var(--p-space-0);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--flush {
    padding: var(--p-space-0);
  }
}

.Polaris-Card__Section--subdued {
  background-color: var(--p-surface-subdued);
}

@media (-ms-high-contrast: active) {
  .Polaris-Card__Section--subdued {
    background-color: #0000;
  }
}

.Polaris-Card__Header + .Polaris-Card__Section--subdued {
  border-top: var(--p-border-divider);
  margin-top: var(--p-space-5);
}

.Polaris-Card__SectionHeader {
  padding-bottom: var(--p-space-2);
}

.Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
  padding-left: var(--p-space-4);
  padding-right: var(--p-space-4);
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
    padding-left: var(--p-space-5);
    padding-right: var(--p-space-5);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Section--fullWidth .Polaris-Card__SectionHeader {
    padding-left: var(--p-space-5);
    padding-right: var(--p-space-5);
  }
}

.Polaris-Card__Subsection + .Polaris-Card__Subsection {
  margin-top: var(--p-space-4);
  padding-top: var(--p-space-4);
  border-top: var(--p-border-divider);
}

@media print {
  .Polaris-Card__Subsection + .Polaris-Card__Subsection {
    border-top: 0;
  }
}

@media print {
  .Polaris-Card__Subsection {
    padding-top: var(--p-space-1);
    padding-bottom: var(--p-space-1);
  }
}

.Polaris-Card__Footer {
  padding: 0 var(--p-space-4) var(--p-space-4);
  justify-content: flex-end;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Card__Footer {
    padding: 0 var(--p-space-5) var(--p-space-5);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Card__Footer {
    padding: 0 var(--p-space-5) var(--p-space-5);
  }
}

.Polaris-Card__Footer.Polaris-Card__LeftJustified {
  justify-content: flex-start;
}

.Polaris-Card__Section--subdued + .Polaris-Card__Footer {
  border-top: var(--p-border-divider);
  padding: var(--p-space-5);
}

.Polaris-Subheading {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-1);
  text-transform: uppercase;
  margin: 0;
}

@media (min-width: 40em) {
  .Polaris-Subheading {
    font-size: var(--p-font-size-1);
  }
}

@media print {
  .Polaris-Subheading {
    font-size: var(--p-font-size-1);
  }
}

.Polaris-SettingAction {
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-4) * -1);
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.Polaris-SettingAction__Setting, .Polaris-SettingAction__Action {
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-4);
  max-width: calc(100% - var(--p-space-4));
  min-width: 0;
  flex: none;
}

.Polaris-SettingAction__Setting {
  flex: 1 0 21.875rem;
}

.Polaris-AccountConnection__TermsOfService {
  margin-top: var(--p-space-5);
}

.Polaris-AccountConnection__Content > * + * {
  margin-top: var(--p-space-2);
}

.Polaris-ActionMenu-SecondaryAction {
  --pc-secondary-action-button-spacing: var(--p-space-3);
}

.Polaris-ActionMenu-SecondaryAction a, .Polaris-ActionMenu-SecondaryAction button {
  padding-left: var(--pc-secondary-action-button-spacing);
  padding-right: var(--pc-secondary-action-button-spacing);
  position: relative;
  box-shadow: none !important;
  border-radius: var(--p-border-radius-1) !important;
  background: none !important;
}

.Polaris-ActionMenu-SecondaryAction a:after, .Polaris-ActionMenu-SecondaryAction button:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-ActionMenu-SecondaryAction a:hover, .Polaris-ActionMenu-SecondaryAction button:hover {
  background: var(--p-background-hovered) !important;
}

.Polaris-ActionMenu-SecondaryAction a:active, .Polaris-ActionMenu-SecondaryAction button:active {
  background: var(--p-background-pressed) !important;
}

@media (min-width: 48em) {
  .Polaris-ActionMenu-SecondaryAction a, .Polaris-ActionMenu-SecondaryAction button {
    position: relative;
    border: none !important;
  }

  .Polaris-ActionMenu-SecondaryAction a:after, .Polaris-ActionMenu-SecondaryAction button:after {
    content: "";
    z-index: 1;
    pointer-events: none;
    box-shadow: 0 0 0 -.0625rem var(--p-focused);
    transition: box-shadow var(--p-duration-100) var(--p-ease);
    border-radius: calc(var(--p-border-radius-1)  + .0625rem);
    display: block;
    position: absolute;
    inset: -.0625rem;
  }
}

.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive a, .Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive button {
  color: var(--p-interactive-critical);
}

.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive a svg, .Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive button svg {
  fill: var(--p-icon-critical);
}

.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive a:hover, .Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive button:hover {
  background-color: var(--p-surface-critical-subdued-hovered) !important;
}

.Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive a:active, .Polaris-ActionMenu-SecondaryAction.Polaris-ActionMenu-SecondaryAction--destructive button:active {
  background-color: var(--p-surface-critical-subdued-pressed) !important;
}

.Polaris-ActionMenu-MenuGroup__Details {
  margin-top: calc(var(--p-space-4) * -1);
  padding: var(--p-space-4);
}

.Polaris-ActionMenu-Actions__ActionsLayout {
  flex-wrap: wrap;
  flex: auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Polaris-ActionMenu-Actions__ActionsLayout > * {
  flex: none;
}

.Polaris-ActionMenu-RollupActions__RollupActivator {
  text-align: right;
}

.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"] {
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  margin: 0;
}

.Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:hover, .Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:active, .Polaris-ActionMenu-RollupActions__RollupActivator button[type="button"]:focus {
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
}

.Polaris-ActionMenu {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

@media print {
  .Polaris-ActionMenu {
    display: none !important;
  }
}

.Polaris-Combobox__Listbox {
  padding: var(--p-space-2) 0;
  overflow: visible;
}

.Polaris-Label {
  -webkit-tap-highlight-color: transparent;
}

.Polaris-Label--hidden {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Label__Text {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  color: currentColor;
  -webkit-tap-highlight-color: transparent;
  flex: auto;
  display: block;
}

@media (min-width: 40em) {
  .Polaris-Label__Text {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Label__RequiredIndicator:after {
  content: "*";
  color: var(--p-text-critical);
  margin-left: var(--p-space-1);
}

.Polaris-InlineError {
  color: var(--p-text-critical);
  fill: var(--p-icon-critical);
  display: flex;
}

.Polaris-InlineError__Icon {
  fill: currentColor;
  margin-left: calc(var(--p-space-05) * -1);
  margin-right: calc(var(--p-space-05)  + var(--p-space-1));
}

.Polaris-Labelled--hidden > .Polaris-Labelled__LabelWrapper {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Labelled__LabelWrapper {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: var(--p-space-1);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

.Polaris-Labelled__HelpText {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: var(--p-space-1);
}

@media (min-width: 40em) {
  .Polaris-Labelled__HelpText {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Labelled__Error {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: var(--p-space-1);
}

.Polaris-Labelled__Action {
  flex: none;
}

.Polaris-Connected {
  --pc-connected-item: 10;
  --pc-connected-primary: 20;
  --pc-connected-focused: 30;
  display: flex;
  position: relative;
}

.Polaris-Connected__Item {
  z-index: var(--pc-connected-item);
  flex: none;
  position: relative;
}

.Polaris-Connected__Item:not(:first-child) {
  margin-left: var(--p-space-1);
}

.Polaris-Connected__Item--primary {
  z-index: var(--pc-connected-primary);
  flex: auto;
}

.Polaris-Connected__Item--focused {
  z-index: var(--pc-connected-focused);
}

.Polaris-TextField {
  --pc-text-field-contents: 20;
  --pc-text-field-backdrop: 10;
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  cursor: text;
  border: none;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-TextField {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-TextField svg {
  fill: var(--p-icon);
}

.Polaris-TextField--multiline {
  flex-wrap: wrap;
  padding: 0;
}

.Polaris-TextField--multiline > .Polaris-TextField__Input {
  padding-left: var(--p-space-3);
  padding-right: var(--p-space-3);
  resize: none;
  overflow: auto;
}

.Polaris-TextField--hasValue {
  color: var(--p-text);
}

.Polaris-TextField--focus > .Polaris-TextField__Input, .Polaris-TextField--focus > .Polaris-TextField__VerticalContent, .Polaris-TextField__Input:focus {
  outline: none;
}

.Polaris-TextField--focus > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop:after, .Polaris-TextField--focus > .Polaris-TextField__VerticalContent ~ .Polaris-TextField__Backdrop:after, .Polaris-TextField__Input:focus ~ .Polaris-TextField__Backdrop:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-TextField--error .Polaris-TextField__Input::placeholder {
  color: #9c9798;
}

.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop {
  background-color: var(--p-surface-critical-subdued);
  border-color: var(--p-border-critical);
}

.Polaris-TextField--error > .Polaris-TextField__Input ~ .Polaris-TextField__Backdrop:after {
  border-color: var(--p-focused);
}

.Polaris-TextField--readOnly > .Polaris-TextField__Backdrop {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-TextField--disabled {
  color: var(--p-text-disabled);
  cursor: initial;
}

.Polaris-TextField--disabled > .Polaris-TextField__Backdrop {
  background-color: var(--p-surface-disabled);
  border-top-color: var(--p-border-disabled);
}

.Polaris-TextField--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-TextField__Input {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  text-transform: initial;
  letter-spacing: initial;
  z-index: var(--pc-text-field-contents);
  width: 100%;
  min-width: 0;
  min-height: 2.25rem;
  padding: calc((var(--p-line-height-6)  - var(--p-line-height-3)  - var(--p-space-05)) / 2) var(--p-space-3);
  border: none;
  border: var(--p-border-transparent);
  font-family: var(--p-font-family-sans);
  appearance: none;
  caret-color: var(--p-text);
  color: var(--p-text);
  background: none;
  flex: 1;
  margin: 0;
  display: block;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-TextField__Input {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-TextField__Prefix + .Polaris-TextField__Input {
  padding-left: 0;
}

.Polaris-TextField__Input:disabled {
  opacity: 1;
  color: var(--p-text-disabled);
  -webkit-text-fill-color: var(--p-text-disabled);
  background: none;
}

.Polaris-TextField__Input:invalid {
  box-shadow: none;
}

.Polaris-TextField__Input::placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TextField__Input[type="number"] {
  appearance: textfield;
}

.Polaris-TextField__Input[type="number"]::-webkit-outer-spin-button, .Polaris-TextField__Input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.Polaris-TextField__Input:-webkit-autofill {
  border-radius: var(--p-border-radius-1);
}

.Polaris-TextField__Input.Polaris-TextField--suggestion::selection {
  color: var(--p-text-disabled);
  background: none;
}

.Polaris-TextField__Input--hasClearButton[type="search"]::-webkit-search-cancel-button {
  appearance: none;
}

.Polaris-TextField__Input--suffixed {
  padding-right: 0;
}

.Polaris-TextField__Input--alignRight {
  text-align: right;
}

.Polaris-TextField__Input--alignLeft {
  text-align: left;
}

.Polaris-TextField__Input--alignCenter {
  text-align: center;
}

.Polaris-TextField__Backdrop {
  z-index: var(--pc-text-field-backdrop);
  background-color: var(--p-surface);
  border: var(--p-border-width-1) solid var(--p-border-subdued);
  border-top-color: var(--p-border-shadow);
  border-radius: var(--p-border-radius-1);
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.Polaris-TextField__Backdrop:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-TextField__Prefix, .Polaris-TextField__Suffix {
  z-index: var(--pc-text-field-contents);
  color: var(--p-text-subdued);
  user-select: none;
  flex: none;
  position: relative;
}

.Polaris-TextField__Prefix {
  margin-left: var(--p-space-3);
  margin-right: var(--p-space-2);
}

.Polaris-TextField__Suffix {
  margin-left: var(--p-space-1);
  margin-right: var(--p-space-3);
}

.Polaris-TextField__VerticalContent {
  z-index: var(--pc-text-field-contents);
  color: var(--p-text-subdued);
  padding: var(--p-space-2) var(--p-space-2) 0 var(--p-space-2);
  max-height: 8.75rem;
  border: var(--p-border-transparent);
  width: 100%;
  position: relative;
  overflow: scroll;
}

.Polaris-TextField__VerticalContent > .Polaris-TextField__Input {
  padding-left: var(--p-space-0);
  padding-right: var(--p-space-0);
}

@media (min-width: 30.625em) {
  .Polaris-TextField__VerticalContent {
    max-height: 20.5rem;
  }
}

.Polaris-TextField__CharacterCount {
  color: var(--p-text-subdued);
  z-index: var(--pc-text-field-contents);
  margin: 0 var(--p-space-3) 0 var(--p-space-1);
  pointer-events: none;
  text-align: right;
}

.Polaris-TextField__AlignFieldBottom {
  width: 100%;
  padding-bottom: var(--p-space-2);
  align-self: flex-end;
}

.Polaris-TextField__ClearButton {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  z-index: var(--pc-text-field-contents);
  margin: 0;
  margin: 0 var(--p-space-3) 0 var(--p-space-1);
  background: none;
  border: none;
  padding: 0;
  position: relative;
}

.Polaris-TextField__ClearButton:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-TextField__ClearButton:focus {
  outline: none;
}

.Polaris-TextField__ClearButton:focus:enabled:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-TextField__ClearButton:disabled {
  cursor: default;
}

.Polaris-TextField__Spinner {
  --pc-text-field-spinner-offset-large: calc(var(--p-text-field-spinner-offset)  + var(--p-border-width-1));
  z-index: var(--pc-text-field-contents);
  margin: var(--pc-text-field-spinner-offset-large);
  color: var(--p-icon);
  width: 1.375rem;
  cursor: pointer;
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.Polaris-TextField__SpinnerIcon {
  height: .75rem;
  width: .75rem;
}

.Polaris-TextField__Resizer {
  height: 0;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.Polaris-TextField__DummyInput {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  text-transform: initial;
  letter-spacing: initial;
  padding: calc((var(--p-line-height-6)  - var(--p-line-height-3)  - var(--p-space-05)) / 2) var(--p-space-3);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  border: none;
}

@media (min-width: 40em) {
  .Polaris-TextField__DummyInput {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-TextField__Segment {
  --pc-text-field-spinner-border-radius: calc(var(--p-border-radius-1)  - var(--p-text-field-spinner-offset));
  background: var(--p-surface-neutral);
  border-radius: var(--pc-text-field-spinner-border-radius);
  appearance: none;
  border: none;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Polaris-TextField__Segment:focus {
  outline: none;
}

.Polaris-TextField__Segment:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-TextField__Segment:first-child {
  border-top-right-radius: var(--pc-text-field-spinner-border-radius);
  margin-bottom: var(--p-text-field-spinner-offset);
}

.Polaris-TextField__Segment:last-child {
  border-bottom-right-radius: var(--pc-text-field-spinner-border-radius);
}

.Polaris-TextField__Segment:not(:first-child) {
  margin-top: 0;
}

.Polaris-TextField--monospaced {
  font-family: var(--p-font-family-mono);
}

.Polaris-Listbox-Section__SectionGroup {
  border-bottom: var(--p-border-divider);
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.Polaris-Listbox-Section--noDivider {
  border-bottom: none;
}

.Polaris-Choice {
  padding: var(--p-space-1) 0;
  cursor: pointer;
  justify-content: flex-start;
  display: inline-flex;
}

.Polaris-Choice--labelHidden {
  padding: 0;
}

.Polaris-Choice--labelHidden > .Polaris-Choice__Label {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Choice--labelHidden .Polaris-Choice__Control {
  margin-top: 0;
  margin-right: 0;
}

.Polaris-Choice--disabled {
  cursor: default;
}

.Polaris-Choice--disabled > .Polaris-Choice__Label {
  color: var(--p-text-disabled);
}

.Polaris-Choice--disabled > .Polaris-Choice__Label:hover {
  cursor: default;
}

@media (-ms-high-contrast: active) {
  .Polaris-Choice--disabled > .Polaris-Choice__Label {
    color: grayText;
  }
}

.Polaris-Choice__Control {
  width: var(--p-choice-size);
  height: var(--p-choice-size);
  margin-right: var(--p-space-2);
  flex: none;
  align-items: stretch;
  display: flex;
}

@media (max-width: 40em) {
  .Polaris-Choice__Control {
    --p-choice-size: 1.375rem;
  }
}

.Polaris-Choice__Control > * {
  width: 100%;
}

.Polaris-Choice__Label {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 40em) {
  .Polaris-Choice__Label {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Choice__Label:hover {
  cursor: pointer;
}

.Polaris-Choice__Descriptions {
  padding-left: calc(var(--p-space-2)  + var(--p-choice-size));
}

@media (max-width: 40em) {
  .Polaris-Choice__Descriptions {
    --p-choice-size: 1.375rem;
  }
}

.Polaris-Choice__HelpText {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  margin-bottom: var(--p-space-1);
}

@media (min-width: 40em) {
  .Polaris-Choice__HelpText {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Checkbox {
  margin: var(--p-choice-margin);
  position: relative;
}

.Polaris-Checkbox__Input {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Checkbox__Input.Polaris-Checkbox--keyFocused + .Polaris-Checkbox__Backdrop:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop:before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .Polaris-Checkbox__Input:active:not(:disabled) + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop:before {
    border: var(--p-border-width-2) solid windowText;
  }
}

.Polaris-Checkbox__Input:active:not(:disabled) ~ .Polaris-Checkbox__Icon, .Polaris-Checkbox__Input:checked ~ .Polaris-Checkbox__Icon, .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate ~ .Polaris-Checkbox__Icon {
  transition: opacity var(--p-duration-150) var(--p-ease), transform var(--p-duration-150) var(--p-ease);
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop:before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop:hover {
  cursor: default;
}

.Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop, .Polaris-Checkbox__Input:disabled:checked + .Polaris-Checkbox__Backdrop:before {
  background: var(--p-border-disabled);
}

.Polaris-Checkbox__Backdrop {
  border: var(--p-control-border-width) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-1);
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.Polaris-Checkbox__Backdrop:before {
  content: "";
  top: calc(var(--p-control-border-width) * -1);
  right: calc(var(--p-control-border-width) * -1);
  bottom: calc(var(--p-control-border-width) * -1);
  left: calc(var(--p-control-border-width) * -1);
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-interactive);
  opacity: 0;
  transition: opacity var(--p-duration-100) var(--p-ease), transform var(--p-duration-100) var(--p-ease);
  position: absolute;
  transform: scale(.25);
}

.Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover, .Polaris-Checkbox__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-Checkbox__Backdrop:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -.0625rem);
  right: calc(var(--p-control-border-width) * -1 + -.0625rem);
  bottom: calc(var(--p-control-border-width) * -1 + -.0625rem);
  left: calc(var(--p-control-border-width) * -1 + -.0625rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -.0625rem) var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
}

.Polaris-Checkbox__Icon {
  transform-origin: 50%;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--p-duration-100) var(--p-ease), transform var(--p-duration-100) var(--p-ease);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.25);
}

.Polaris-Checkbox__Icon svg {
  fill: var(--p-icon-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-Checkbox__Icon {
    fill: windowText;
  }
}

.Polaris-Checkbox--error .Polaris-Checkbox__Icon svg {
  fill: var(--p-icon-on-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop.Polaris-Checkbox--hover, .Polaris-Checkbox--error .Polaris-Checkbox__Backdrop:hover {
  border-color: var(--p-border-critical);
}

.Polaris-Checkbox--error .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox--error .Polaris-Checkbox__Input:checked + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox--error .Polaris-Checkbox__Input:active + .Polaris-Checkbox__Backdrop:before, .Polaris-Checkbox--error .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate + .Polaris-Checkbox__Backdrop:before {
  background-color: var(--p-border-critical);
}

.Polaris-Listbox-TextOption {
  margin: var(--p-space-1) var(--p-space-2) 0;
  border-radius: var(--p-border-radius-1);
  padding: var(--p-space-2) var(--p-space-2);
  cursor: pointer;
  flex: 1;
  display: flex;
  position: relative;
}

.Polaris-Listbox-TextOption:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--allowMultiple {
  margin: var(--p-space-1) var(--p-space-2) 0;
  padding: var(--p-space-1) var(--p-space-2);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--isAction {
  padding: var(--p-space-2);
  margin-top: 0;
}

.Polaris-Listbox-TextOption:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Listbox-TextOption:focus {
  outline: none;
}

.Polaris-Listbox-TextOption:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--selected:before {
  content: "";
  top: 0;
  bottom: 0;
  left: calc(var(--p-space-1) * -1);
  height: 100%;
  width: var(--p-border-radius-1);
  background-color: var(--p-interactive);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
  position: absolute;
  transform: translateX(-100%);
}

.Polaris-Listbox-TextOption.Polaris-Listbox-TextOption--disabled {
  background-color: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: default;
}

li:first-of-type > .Polaris-Listbox-TextOption {
  margin-top: 0;
}

[data-focused] .Polaris-Listbox-TextOption:not(.Polaris-Listbox-TextOption--disabled) {
  background-color: var(--p-surface-selected);
  transition: background-color var(--p-duration-400);
  outline: none;
}

[data-focused]:not(:focus) .Polaris-Listbox-TextOption:not(.Polaris-Listbox-TextOption--disabled):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
  transition: box-shadow var(--p-duration-200) var(--p-ease-in);
}

.Polaris-Listbox-TextOption__Content {
  flex: auto;
  display: flex;
}

.Polaris-Listbox-TextOption__Checkbox {
  pointer-events: none;
}

.Polaris-Listbox-Option {
  margin: 0;
  padding: 0;
  display: flex;
}

.Polaris-Listbox-Option:focus {
  outline: none;
}

.Polaris-Listbox-Option--divider {
  border-bottom: var(--p-border-divider);
}

.Polaris-Listbox-Loading__ListItem {
  margin: 0;
  padding: 0;
}

.Polaris-Listbox-Loading {
  padding: var(--p-space-2) var(--p-space-4);
  place-items: center;
  display: grid;
}

.Polaris-Listbox-Header {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-1);
  text-transform: uppercase;
  padding: var(--p-space-2) var(--p-space-4);
  color: var(--p-text-subdued);
}

@media (min-width: 40em) {
  .Polaris-Listbox-Header {
    font-size: var(--p-font-size-1);
  }
}

.Polaris-Listbox-Action {
  flex: 1;
  display: flex;
}

.Polaris-Listbox-Action__ActionDivider {
  margin-bottom: var(--p-space-1);
}

.Polaris-Listbox-Action__Icon {
  padding-right: var(--p-space-2);
}

.Polaris-Listbox {
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Listbox:focus {
  outline: none;
}

.Polaris-Autocomplete-MappedOption__Content {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1;
  display: flex;
}

.Polaris-Autocomplete-MappedOption__Media {
  padding: 0 var(--p-space-2);
}

.Polaris-Autocomplete-MappedOption__Media svg {
  fill: var(--p-icon);
  color: var(--p-icon-on-interactive);
}

.Polaris-Autocomplete-MappedOption--singleSelectionMedia {
  padding: 0 var(--p-space-2) 0 0;
}

.Polaris-Autocomplete-MappedOption--disabledMedia svg {
  fill: var(--p-icon-disabled);
  color: var(--p-text-on-interactive);
}

.Polaris-Autocomplete-MappedAction__ActionContainer {
  --pc-mapped-actions-image-size: 1.25rem;
  --pc-mapped-actions-item-min-height: var(--p-space-10);
  --pc-mapped-actions-item-vertical-padding: calc((var(--pc-mapped-actions-item-min-height)  - var(--p-line-height-2)) / 2);
  margin-bottom: var(--p-space-3);
}

[data-focused] .Polaris-Autocomplete-MappedAction__Action svg {
  fill: var(--p-interactive);
}

[data-focused] .Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Autocomplete-MappedAction__Action {
  width: 100%;
  min-height: var(--pc-mapped-actions-item-min-height);
  text-align: left;
  cursor: pointer;
  padding: var(--pc-mapped-actions-item-vertical-padding) var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  border-top: var(--p-border-width-1) solid var(--p-surface);
  display: block;
  position: relative;
}

.Polaris-Autocomplete-MappedAction__Action:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Autocomplete-MappedAction__Action:hover {
  background-color: var(--p-surface-hovered);
  text-decoration: none;
}

@media (-ms-high-contrast: active) {
  .Polaris-Autocomplete-MappedAction__Action:hover {
    outline: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--selected svg {
  fill: var(--p-interactive);
}

.Polaris-Autocomplete-MappedAction__Action:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Autocomplete-MappedAction__Action:active svg {
  fill: var(--p-interactive);
}

.Polaris-Autocomplete-MappedAction__Action:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive {
  color: var(--p-interactive-critical);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive svg {
  fill: var(--p-icon-critical);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive:hover {
  background-color: var(--p-surface-critical-subdued-hovered);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive:active, .Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--destructive.Polaris-Autocomplete-MappedAction--selected {
  background-color: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled {
  color: var(--p-text-disabled);
  background-image: none;
}

.Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled .Polaris-Autocomplete-MappedAction__Prefix svg, .Polaris-Autocomplete-MappedAction__Action.Polaris-Autocomplete-MappedAction--disabled .Polaris-Autocomplete-MappedAction__Suffix svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Autocomplete-MappedAction__Content {
  align-items: center;
  display: flex;
}

.Polaris-Autocomplete-MappedAction__Prefix {
  height: var(--pc-mapped-actions-image-size);
  width: var(--pc-mapped-actions-image-size);
  border-radius: var(--p-border-radius-base);
  margin: calc(var(--pc-mapped-actions-image-size) * -.5) var(--p-space-4) calc(var(--pc-mapped-actions-image-size) * -.5) 0;
  background-position: center;
  background-size: cover;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Polaris-Autocomplete-MappedAction__Prefix svg {
  fill: var(--p-icon);
}

.Polaris-Autocomplete-MappedAction__Suffix {
  margin-left: var(--p-space-4);
}

.Polaris-Autocomplete-MappedAction__Suffix svg {
  fill: var(--p-icon);
}

.Polaris-Autocomplete-MappedAction__Text {
  min-width: 0;
  max-width: 100%;
  flex: auto;
}

.Polaris-Autocomplete-MappedAction__Text .Polaris-Autocomplete-MappedAction__ContentWrap {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}

.Polaris-Autocomplete__Loading {
  width: 100%;
  padding: var(--p-space-2) var(--p-space-4);
  justify-content: center;
  align-items: center;
  display: flex;
}

.Polaris-Autocomplete__SectionWrapper > :not(:first-child) {
  margin-top: var(--p-space-2);
}

[data-lock-scrolling] {
  margin: 0;
  overflow-y: scroll;
}

[data-lock-scrolling] [data-lock-scrolling-wrapper] {
  height: 100%;
  overflow: hidden;
}

.Polaris-Backdrop {
  z-index: var(--p-z-10);
  background-color: var(--p-backdrop);
  animation: var(--p-keyframes-fade-in) var(--p-duration-200) 1 forwards;
  opacity: 0;
  backface-visibility: hidden;
  will-change: opacity;
  display: block;
  position: fixed;
  inset: 0;
}

.Polaris-Backdrop--transparent {
  background-color: #0000;
}

.Polaris-Backdrop--belowNavigation {
  z-index: var(--p-z-7);
}

.Polaris-Banner {
  --pc-banner-secondary-action-horizontal-padding: var(--p-space-3);
  --pc-banner-secondary-action-vertical-padding: calc((var(--p-line-height-6)  - var(--p-line-height-2)) * .5);
  display: flex;
  position: relative;
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusCritical .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-critical-subdued);
  background: var(--p-surface-critical-subdued);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusWarning .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-warning-subdued);
  background: var(--p-surface-warning-subdued);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusInfo .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-highlight-subdued);
  background: var(--p-surface-highlight-subdued);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:hover {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued-hovered);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:active {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued-pressed);
}

.Polaris-Banner.Polaris-Banner--statusSuccess .Polaris-Banner__PrimaryAction .Polaris-Banner__Button:focus:not(:active) {
  border-color: var(--p-border-success-subdued);
  background: var(--p-surface-success-subdued);
}

.Polaris-Banner__ContentWrapper {
  margin-top: calc(var(--p-space-05) * -1);
  flex: auto;
}

.Polaris-Banner--withinContentContainer {
  padding: var(--p-space-4);
  --pc-banner-background: var(--p-background);
  --pc-banner-border: var(--p-banner-border-default);
  transition: box-shadow var(--p-duration-200) var(--p-ease);
  transition-delay: var(--p-duration-100);
  box-shadow: var(--pc-banner-border);
  outline: var(--p-border-width-1) solid transparent;
  border-radius: var(--p-border-radius-1);
  background-color: var(--pc-banner-background);
  position: relative;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Dismiss {
  top: var(--p-space-4);
  right: var(--p-space-3);
  position: absolute;
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Ribbon {
  padding-right: var(--p-space-4);
}

.Polaris-Banner--withinContentContainer:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Banner--withinContentContainer:focus {
  outline: none;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused {
  box-shadow: var(--pc-banner-border);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--keyFocused:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess {
  --pc-banner-background: var(--p-surface-success-subdued);
  --pc-banner-border: var(--p-banner-border-success);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusInfo {
  --pc-banner-background: var(--p-surface-highlight-subdued);
  --pc-banner-border: var(--p-banner-border-highlight);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusWarning {
  --pc-banner-background: var(--p-surface-warning-subdued);
  --pc-banner-border: var(--p-banner-border-warning);
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusCritical {
  --pc-banner-background: var(--p-surface-critical-subdued);
  --pc-banner-border: var(--p-banner-border-critical);
}

.Polaris-Banner--withinContentContainer + .Polaris-Banner {
  margin-top: var(--p-space-2);
}

.Polaris-Banner--withinContentContainer .Polaris-Banner__Actions {
  padding: var(--p-space-3) 0 var(--p-space-1) 0;
}

.Polaris-Banner--withinPage {
  border-radius: 0 0 var(--p-border-radius-base) var(--p-border-radius-base);
  padding: var(--p-space-5);
  --pc-banner-background: var(--p-background);
  --pc-banner-border: var(--p-banner-border-default);
  transition: box-shadow var(--p-duration-200) var(--p-ease);
  transition-delay: var(--p-duration-100);
  box-shadow: var(--pc-banner-border);
  outline: var(--p-border-width-1) solid transparent;
  border-radius: var(--p-border-radius-2);
  background-color: var(--pc-banner-background);
  position: relative;
}

.Polaris-Banner--withinPage:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-2)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Banner--withinPage:focus {
  outline: none;
}

.Polaris-Banner--withinPage.Polaris-Banner--keyFocused {
  box-shadow: var(--pc-banner-border);
}

.Polaris-Banner--withinPage.Polaris-Banner--keyFocused:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner--withinPage.Polaris-Banner--statusSuccess {
  --pc-banner-background: var(--p-surface-success-subdued);
  --pc-banner-border: var(--p-banner-border-success);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusInfo {
  --pc-banner-background: var(--p-surface-highlight-subdued);
  --pc-banner-border: var(--p-banner-border-highlight);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusWarning {
  --pc-banner-background: var(--p-surface-warning-subdued);
  --pc-banner-border: var(--p-banner-border-warning);
}

.Polaris-Banner--withinPage.Polaris-Banner--statusCritical {
  --pc-banner-background: var(--p-surface-critical-subdued);
  --pc-banner-border: var(--p-banner-border-critical);
}

.Polaris-Banner--withinPage + .Polaris-Banner {
  margin-top: var(--p-space-5);
}

.Polaris-Banner--withinPage .Polaris-Banner__Ribbon {
  padding-right: var(--p-space-4);
}

.Polaris-Banner--withinPage .Polaris-Banner__Actions {
  padding-top: var(--p-space-4);
}

.Polaris-Banner--withinPage .Polaris-Banner__Dismiss {
  right: var(--p-space-4);
  top: var(--p-space-5);
  position: absolute;
}

.Polaris-Banner--hasDismiss {
  padding-right: calc(var(--p-space-8)  + var(--p-icon-size-small));
}

.Polaris-Banner__Heading {
  word-break: break-word;
}

.Polaris-Banner__Content {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  padding: var(--p-space-05) 0;
}

.Polaris-Banner__Ribbon {
  flex: 0 0 var(--p-space-8);
}

.Polaris-Banner__PrimaryAction {
  margin-right: var(--p-space-2);
}

.Polaris-Banner__SecondaryAction {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  text-align: left;
  margin: 0;
  margin: calc(var(--pc-banner-secondary-action-vertical-padding) * -1) calc(var(--pc-banner-secondary-action-horizontal-padding) * -.5);
  padding: 0;
  padding: var(--pc-banner-secondary-action-vertical-padding) var(--pc-banner-secondary-action-horizontal-padding);
  color: var(--p-text);
  padding-left: var(--p-space-2);
  background: none;
  border: none;
  text-decoration: none;
  display: inline-block;
}

.Polaris-Banner__SecondaryAction:focus {
  outline: none;
}

.Polaris-Banner__SecondaryAction:hover > .Polaris-Banner__Text, .Polaris-Banner__SecondaryAction:active > .Polaris-Banner__Text {
  text-decoration: underline;
}

.Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text {
  padding: var(--p-space-05) var(--p-space-1);
  margin: calc(var(--p-space-05) * -1) calc(var(--p-space-1) * -1);
  border-radius: var(--p-border-radius-base);
  box-shadow: none;
  background: none;
  text-decoration: underline;
}

@media (-ms-high-contrast: active) {
  .Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text {
    outline: var(--p-border-width-2) dotted;
  }
}

.Polaris-Banner__SecondaryAction:focus > .Polaris-Banner__Text:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner__SecondaryAction:visited {
  color: inherit;
}

.Polaris-Banner__Text {
  position: relative;
}

.Polaris-Banner__Text:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Banner__Button {
  min-height: var(--p-line-height-6);
  min-width: var(--p-line-height-6);
  padding: calc((var(--p-line-height-6)  - var(--p-line-height-2)  - var(--p-space-05)) / 2) var(--p-space-4);
  background: var(--p-surface);
  box-shadow: var(--p-shadow-button);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  line-height: 1;
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-line-height-1);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  justify-content: center;
  align-items: center;
  margin: 0;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.Polaris-Banner__Button svg {
  fill: var(--p-icon);
}

.Polaris-Banner__Button:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-border-width-1) * -1 + -.0625rem);
  right: calc(var(--p-border-width-1) * -1 + -.0625rem);
  bottom: calc(var(--p-border-width-1) * -1 + -.0625rem);
  left: calc(var(--p-border-width-1) * -1 + -.0625rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-1) * -1 + -.0625rem) var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
}

.Polaris-Banner__Button:hover {
  background: var(--p-action-secondary-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner__Button:focus {
  box-shadow: var(--p-shadow-button);
  outline: 0;
}

.Polaris-Banner__Button:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner__Button:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-shadow-button);
}

.Polaris-Banner__Button:active:after {
  box-shadow: none;
  border: none;
}

.Polaris-Banner__Button.Polaris-Banner--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-shadows-inset-button-pressed);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Banner__Button.Polaris-Banner--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Banner__Button {
    border: var(--p-border-width-1) solid windowText;
  }
}

@media (min-width: 40em) {
  .Polaris-Banner__Button {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Banner__Button:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.1875rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.1875rem;
}

.Polaris-Banner__Button:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Banner--loading {
  color: #0000;
  pointer-events: none;
  position: relative;
}

.Polaris-Banner__Spinner {
  --pc-spinner-size: var(--p-space-5);
  margin-top: calc((var(--pc-spinner-size) / 2) * -1);
  margin-left: calc((var(--pc-spinner-size) / 2) * -1);
  position: absolute;
  top: 50%;
  left: 50%;
}

.Polaris-Breadcrumbs__Breadcrumb {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  min-height: 2.25rem;
  min-width: 2.25rem;
  color: var(--p-text-subdued);
  padding: 0;
  padding: var(--p-space-05);
  border-radius: var(--p-border-radius-1);
  border: none;
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  background: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-Breadcrumbs__Breadcrumb {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Breadcrumbs__Breadcrumb:focus {
  outline: none;
}

.Polaris-Breadcrumbs__Breadcrumb:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-Breadcrumbs__Breadcrumb:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Breadcrumbs__Breadcrumb:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-Breadcrumbs__Breadcrumb:hover, .Polaris-Breadcrumbs__Breadcrumb:active {
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Breadcrumbs__Breadcrumb:hover .Polaris-Breadcrumbs__Icon svg, .Polaris-Breadcrumbs__Breadcrumb:active .Polaris-Breadcrumbs__Icon svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Breadcrumbs__Breadcrumb:focus {
  outline: none;
}

.Polaris-Breadcrumbs__Breadcrumb:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Breadcrumbs__Content {
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  overflow: hidden;
}

.Polaris-Breadcrumbs__Icon {
  --pc-breadcrumbs-icon-size: 1.25rem;
  width: var(--pc-breadcrumbs-icon-size);
  height: var(--pc-breadcrumbs-icon-size);
  margin: calc(var(--pc-breadcrumbs-icon-size) * -.5) 0 calc(var(--pc-breadcrumbs-icon-size) * -.5) calc(var(--p-space-2) * -1);
  margin: 0;
}

.Polaris-Breadcrumbs__Icon svg {
  fill: var(--p-icon);
}

.Polaris-CheckableButton {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-line-height-1);
  text-transform: initial;
  letter-spacing: initial;
  min-height: var(--p-line-height-6);
  min-width: var(--p-line-height-6);
  padding: calc((var(--p-line-height-6)  - var(--p-line-height-2)  - var(--p-space-05)) / 2) var(--p-space-4);
  cursor: pointer;
  user-select: none;
  text-align: left;
  border-radius: var(--p-border-radius-1);
  width: 100%;
  background: var(--p-surface);
  box-shadow: var(--p-shadow-button);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  align-items: center;
  margin: 0;
  line-height: 1;
  text-decoration: none;
  display: flex;
}

@media (min-width: 40em) {
  .Polaris-CheckableButton {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-CheckableButton svg {
  fill: var(--p-icon-on-interactive);
}

[data-buttongroup-segmented="true"] .Polaris-CheckableButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Polaris-CheckableButton:hover {
  background: var(--p-action-secondary-hovered);
}

.Polaris-CheckableButton:active {
  background: var(--p-action-secondary-pressed);
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--measuring {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-bold);
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--measuring:before {
  content: "";
  width: var(--p-font-size-4);
  display: inline-block;
}

@media (min-width: 28.625em) {
  .Polaris-CheckableButton {
    flex: 0 auto;
  }
}

.Polaris-CheckableButton:focus {
  outline: none;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--plain {
  border-radius: var(--p-border-radius-base);
  box-shadow: none;
  background: none;
  border: none;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--plain:hover {
  background: none;
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--selectMode {
  color: var(--p-text-subdued);
  font-weight: var(--p-font-weight-medium);
}

.Polaris-CheckableButton.Polaris-CheckableButton__CheckableButton--selected {
  color: var(--p-text);
}

.Polaris-CheckableButton__Checkbox {
  pointer-events: none;
  height: var(--p-choice-size);
  width: var(--p-choice-size);
  margin-left: calc((var(--p-space-2)  + var(--p-space-025)) * -1 - var(--p-control-border-width));
}

.Polaris-CheckableButton__Label {
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  padding: var(--p-space-025) 0;
  margin-left: calc(var(--p-space-5)  - var(--p-control-border-width));
  flex: 1;
  overflow: hidden;
}

.Polaris-Indicator {
  --pc-indicator-size: .625rem;
  --pc-indicator-base-position: calc(var(--p-space-1) * -1);
}

.Polaris-Indicator:before, .Polaris-Indicator:after {
  content: "";
  background-color: var(--p-border-highlight);
  right: var(--pc-indicator-base-position);
  top: var(--pc-indicator-base-position);
  width: var(--pc-indicator-size);
  height: var(--pc-indicator-size);
  border-radius: var(--p-border-radius-half);
  border: calc(var(--pc-indicator-size) / 2) solid transparent;
  position: absolute;
}

.Polaris-Indicator--pulseIndicator:before {
  z-index: 1;
  animation: var(--p-keyframes-bounce) var(--p-duration-5000) ease infinite;
}

.Polaris-Indicator--pulseIndicator:after {
  right: var(--pc-indicator-base-position);
  top: var(--pc-indicator-base-position);
  animation: var(--p-keyframes-pulse) var(--p-duration-5000) ease infinite;
}

.Polaris-BulkActions__Group {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  text-transform: initial;
  letter-spacing: initial;
  width: 100%;
  opacity: 0;
  border: none;
  flex-wrap: wrap;
  align-items: center;
  display: none;
}

@media (min-width: 40em) {
  .Polaris-BulkActions__Group {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-BulkActions__Group.Polaris-BulkActions__Group--measuring {
  opacity: 0;
  transition: none;
  display: flex;
}

.Polaris-BulkActions__Group--entering, .Polaris-BulkActions__Group--exiting {
  opacity: 0;
  display: flex;
}

.Polaris-BulkActions__Group--entered {
  opacity: 1;
  display: flex;
}

.Polaris-BulkActions__Group--exited {
  opacity: 0;
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__Group--smallScreen {
    display: none;
  }
}

.Polaris-BulkActions__Group--largeScreen {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__Group--largeScreen {
    display: flex;
  }

  .Polaris-BulkActions__Group--largeScreen.Polaris-BulkActions__Group--exiting {
    transition: none;
  }

  .Polaris-BulkActions__Group--largeScreen.Polaris-BulkActions__Group--exited {
    opacity: 0;
    display: none;
  }
}

.Polaris-BulkActions__ButtonGroupWrapper {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 28.625em) {
  .Polaris-BulkActions__ButtonGroupWrapper > div > div:first-child {
    flex: auto;
  }
}

@media (min-width: 28.625em) {
  .Polaris-BulkActions__ButtonGroupWrapper {
    width: auto;
    margin-right: var(--p-space-2);
    justify-content: flex-start;
  }
}

.Polaris-BulkActions__Group--measuring .Polaris-BulkActions__ButtonGroupWrapper {
  width: auto;
  position: absolute;
}

.Polaris-BulkActions__BulkActionButton {
  white-space: nowrap;
}

.Polaris-BulkActions__CheckableContainer {
  flex: 1;
}

.Polaris-BulkActions--disabled {
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: default;
  pointer-events: none;
  transition: none;
}

.Polaris-BulkActions--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-BulkActions__PaginatedSelectAll {
  padding: var(--p-space-1) var(--p-space-0);
}

.Polaris-BulkActions__Slide {
  backface-visibility: hidden;
  will-change: transform;
  transition: transform var(--p-ease) var(--p-duration-200);
  transform: translateY(0);
}

.Polaris-BulkActions__Slide--appear, .Polaris-BulkActions__Slide--enter, .Polaris-BulkActions__Slide--exit {
  transform: translateX(calc(var(--p-space-10) * -1) );
}

.Polaris-BulkActions__Slide--appearing, .Polaris-BulkActions__Slide--entering {
  transform: translateY(0);
}

.Polaris-TextContainer {
  --pc-text-container-spacing: var(--p-space-4);
}

.Polaris-TextContainer > :not(:first-child) {
  margin-top: var(--pc-text-container-spacing);
}

.Polaris-TextContainer--spacingTight {
  --pc-text-container-spacing: var(--p-space-2);
}

.Polaris-TextContainer--spacingLoose {
  --pc-text-container-spacing: var(--p-space-5);
}

.Polaris-CalloutCard {
  align-items: center;
  display: flex;
}

.Polaris-CalloutCard__Image {
  width: 6.25rem;
  flex: none;
  display: none;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-CalloutCard__Image {
    margin-left: var(--p-space-5);
    display: block;
  }
}

@media (min-width: 30.625em) {
  .Polaris-CalloutCard__Image {
    margin-left: var(--p-space-5);
    display: block;
  }
}

.Polaris-CalloutCard__DismissImage {
  margin-right: var(--p-space-5);
}

.Polaris-CalloutCard__Content {
  flex: auto;
}

.Polaris-CalloutCard__Title {
  margin-bottom: var(--p-space-5);
}

.Polaris-CalloutCard__Buttons {
  margin-top: var(--p-space-5);
}

.Polaris-CalloutCard__Container {
  position: relative;
}

.Polaris-CalloutCard__Dismiss {
  right: var(--p-space-4);
  top: var(--p-space-4);
  position: absolute;
}

.Polaris-CalloutCard--hasDismiss {
  padding-right: calc(var(--p-space-8)  + var(--p-icon-size-small));
}

.Polaris-Caption {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  margin: 0;
}

@media (min-width: 40em) {
  .Polaris-Caption {
    font-size: var(--p-font-size-1);
    line-height: var(--p-line-height-1);
  }
}

.Polaris-RadioButton {
  margin: var(--p-choice-margin);
  position: relative;
}

.Polaris-RadioButton__Input {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-RadioButton__Input.Polaris-RadioButton--keyFocused + .Polaris-RadioButton__Backdrop:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
  border-radius: var(--p-border-radius-half);
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-RadioButton__Input:checked + .Polaris-RadioButton__Backdrop:before {
  transition: opacity var(--p-duration-150) var(--p-ease), transform var(--p-duration-150) var(--p-ease);
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

.Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop {
  border-color: var(--p-border-disabled);
  cursor: default;
}

.Polaris-RadioButton__Input:disabled + .Polaris-RadioButton__Backdrop:before {
  background-color: var(--p-border-disabled);
}

.Polaris-RadioButton__Backdrop {
  width: 100%;
  height: 100%;
  border: var(--p-control-border-width) solid var(--p-border);
  border-radius: var(--p-border-radius-half);
  background-color: var(--p-surface);
  transition: border-color var(--p-duration-100) var(--p-ease);
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

@media (max-width: 40em) {
  .Polaris-RadioButton__Backdrop {
    --p-icon-size-small: .625rem;
  }
}

.Polaris-RadioButton__Backdrop:before {
  content: "";
  opacity: 0;
  transform-origin: 50%;
  height: var(--p-icon-size-small);
  width: var(--p-icon-size-small);
  background-color: var(--p-interactive);
  border-radius: var(--p-border-radius-half);
  transition: opacity var(--p-duration-100) var(--p-ease), transform var(--p-duration-100) var(--p-ease);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.1);
}

@media (forced-colors: active) {
  .Polaris-RadioButton__Backdrop:before {
    border: var(--p-border-width-5) solid transparent;
  }
}

.Polaris-RadioButton__Backdrop:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -.0625rem);
  right: calc(var(--p-control-border-width) * -1 + -.0625rem);
  bottom: calc(var(--p-control-border-width) * -1 + -.0625rem);
  left: calc(var(--p-control-border-width) * -1 + -.0625rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -.0625rem) var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  border-radius: var(--p-border-radius-half);
  display: block;
  position: absolute;
}

.Polaris-RadioButton__Backdrop.Polaris-RadioButton--hover, .Polaris-RadioButton__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-ChoiceList {
  border: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 40em) {
  .Polaris-ChoiceList__ChoiceItem:not(:last-child) {
    margin-bottom: var(--p-space-4);
  }
}

.Polaris-ChoiceList--titleHidden > .Polaris-ChoiceList__Title {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-ChoiceList__Choices {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ChoiceList__ChoiceChildren {
  margin-bottom: var(--p-space-2);
  padding-left: calc(var(--p-space-2)  + var(--p-choice-size));
}

@media (max-width: 40em) {
  .Polaris-ChoiceList__ChoiceChildren {
    margin-top: var(--p-space-4);
  }
}

.Polaris-ChoiceList__ChoiceError {
  margin-top: var(--p-space-1);
  margin-bottom: var(--p-space-2);
}

@media (max-width: 40em) {
  .Polaris-ChoiceList__ChoiceError {
    margin-top: var(--p-space-4);
  }
}

.Polaris-ChoiceList__Title {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  margin: 0 0 var(--p-space-1);
  padding: 0;
  display: block;
}

@media (min-width: 40em) {
  .Polaris-ChoiceList__Title {
    font-size: var(--p-font-size-3);
  }
}

@media (max-width: 40em) {
  .Polaris-ChoiceList__Title {
    margin-bottom: var(--p-space-5);
  }
}

.Polaris-Collapsible {
  max-height: 0;
  backface-visibility: hidden;
  will-change: max-height;
  transition-property: max-height;
  transition-duration: var(--p-duration-100);
  transition-timing-function: var(--p-ease-out);
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.Polaris-Collapsible--isFullyClosed {
  display: none;
}

@media print {
  .Polaris-Collapsible--expandOnPrint {
    display: block;
    overflow: visible;
    max-height: none !important;
  }
}

.Polaris-ColorPicker {
  --pc-color-picker-size: 10rem;
  --pc-color-picker-dragger-size: 1.125rem;
  --pc-color-picker-z-index: 10;
  --pc-color-picker-adjustments: 20;
  --pc-color-picker-dragger: 30;
  --pc-color-picker-inner-shadow: inset 0 0 .125rem 0 var(--p-shadow-color-picker);
  --pc-color-picker-dragger-shadow: inset 0 .0625rem .125rem 0 var(--p-shadow-color-picker-dragger), 0 .0625rem .125rem 0 var(--p-shadow-color-picker-dragger);
  user-select: none;
  display: flex;
}

.Polaris-ColorPicker__MainColor {
  background: repeating-conic-gradient(var(--p-surface) 0% 25%, var(--p-surface-neutral-subdued) 0% 50%) 50% / var(--p-space-4) var(--p-space-4);
  height: var(--pc-color-picker-size);
  width: var(--pc-color-picker-size);
  border-radius: var(--p-border-radius-1);
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.Polaris-ColorPicker--fullWidth .Polaris-ColorPicker__MainColor {
  width: auto;
  flex-grow: 1;
}

.Polaris-ColorPicker__MainColor .Polaris-ColorPicker__Dragger {
  right: calc(var(--pc-color-picker-dragger-size) * .5);
  box-shadow: var(--pc-color-picker-dragger-shadow);
  margin: 0;
}

.Polaris-ColorPicker__MainColor .Polaris-ColorPicker__ColorLayer {
  border-radius: var(--p-border-radius-1);
}

.Polaris-ColorPicker__MainColor:after, .Polaris-ColorPicker__MainColor:before {
  content: "";
  z-index: var(--pc-color-picker-adjustments);
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: var(--p-border-radius-1);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-ColorPicker__MainColor:before {
  background: linear-gradient(to right, #fff, #0000);
}

.Polaris-ColorPicker__MainColor:after {
  box-shadow: var(--pc-color-picker-inner-shadow);
  background-image: linear-gradient(to top, #000, #0000);
}

@media (-ms-high-contrast: active) {
  .Polaris-ColorPicker__MainColor {
    outline: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-ColorPicker__Dragger {
  z-index: var(--pc-color-picker-dragger);
  bottom: calc(var(--pc-color-picker-dragger-size) * .5);
  height: var(--pc-color-picker-dragger-size);
  width: var(--pc-color-picker-dragger-size);
  backface-visibility: hidden;
  will-change: transform;
  border: var(--p-border-radius-1) solid var(--p-surface);
  border-radius: var(--p-border-radius-half);
  pointer-events: none;
  box-shadow: var(--pc-color-picker-dragger-shadow);
  background: none;
  margin: 0 auto;
  position: relative;
  transform: none;
}

.Polaris-ColorPicker__HuePicker, .Polaris-ColorPicker__AlphaPicker {
  height: var(--pc-color-picker-size);
  width: var(--p-space-6);
  margin-left: var(--p-space-2);
  border-width: var(--p-border-radius-1);
  border-radius: calc(var(--pc-color-picker-size) * .5);
  position: relative;
  overflow: hidden;
}

.Polaris-ColorPicker__HuePicker:after, .Polaris-ColorPicker__AlphaPicker:after {
  content: "";
  z-index: var(--pc-color-picker-adjustments);
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: calc(var(--pc-color-picker-size) * .5);
  box-shadow: var(--pc-color-picker-inner-shadow);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media (-ms-high-contrast: active) {
  .Polaris-ColorPicker__HuePicker, .Polaris-ColorPicker__AlphaPicker {
    outline: var(--p-border-width-1) solid windowText;
  }
}

.Polaris-ColorPicker__HuePicker {
  background-image: linear-gradient(to bottom, red var(--pc-color-picker-dragger-size), yellow, lime, cyan, blue, magenta, red calc(var(--pc-color-picker-size)  - var(--pc-color-picker-dragger-size)) );
}

.Polaris-ColorPicker__AlphaPicker {
  background: repeating-conic-gradient(var(--p-surface) 0% 25%, var(--p-surface-neutral-subdued) 0% 50%) 50% / var(--p-space-4) var(--p-space-4);
}

.Polaris-ColorPicker__ColorLayer {
  z-index: var(--pc-color-picker-z-index);
  height: 100%;
  width: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-ColorPicker__Slidable {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.Polaris-DataTable {
  --pc-data-table-first-column-width: 9.0625rem;
  max-width: 100vw;
  position: relative;
}

.Polaris-DataTable--condensed .Polaris-DataTable__Navigation {
  width: 100%;
  padding: var(--p-space-4) var(--p-space-2);
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 48em) {
  .Polaris-DataTable--condensed .Polaris-DataTable__Navigation {
    justify-content: flex-end;
  }
}

.Polaris-DataTable__Navigation {
  display: none;
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Navigation {
  padding: var(--p-space-2) var(--p-space-2) 0 var(--p-space-2);
}

.Polaris-DataTable__Pip {
  height: .375rem;
  width: .375rem;
  background: var(--p-text-subdued);
  border-radius: var(--p-border-radius-1);
}

.Polaris-DataTable__Pip:not(:last-of-type) {
  margin-right: var(--p-space-1);
}

.Polaris-DataTable__Pip--visible {
  background: var(--p-text);
}

.Polaris-DataTable__ScrollContainer {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  overflow-x: auto;
}

.Polaris-DataTable__Table {
  width: 100%;
  border-spacing: 0;
}

.Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell {
  border-top: var(--p-border-divider);
}

.Polaris-DataTable__Cell {
  padding: var(--p-space-4);
  white-space: nowrap;
  text-align: left;
  transition: background-color var(--p-duration-200) var(--p-ease-in-out);
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell {
  padding: var(--p-space-2) var(--p-space-4);
}

.Polaris-DataTable__ZebraStripingOnData .Polaris-DataTable__TableRow:nth-child(2n+1) .Polaris-DataTable__Cell, .Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__RowCountIsEven .Polaris-DataTable__TableRow:nth-child(2n) .Polaris-DataTable__Cell, .Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter .Polaris-DataTable__TableRow:nth-child(2n) .Polaris-DataTable__Cell, .Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter.Polaris-DataTable__RowCountIsEven .Polaris-DataTable__TableRow:nth-child(2n+1) .Polaris-DataTable__Cell {
  background: none;
}

.Polaris-DataTable__ZebraStripingOnData .Polaris-DataTable__TableRow:nth-child(2n) .Polaris-DataTable__Cell, .Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__RowCountIsEven .Polaris-DataTable__TableRow:nth-child(2n+1) .Polaris-DataTable__Cell, .Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter .Polaris-DataTable__TableRow:nth-child(2n+1) .Polaris-DataTable__Cell, .Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter.Polaris-DataTable__RowCountIsEven .Polaris-DataTable__TableRow:nth-child(2n) .Polaris-DataTable__Cell {
  background: var(--p-surface-subdued);
}

.Polaris-DataTable__Cell--firstColumn {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  text-align: left;
  white-space: normal;
}

.Polaris-DataTable__Cell--numeric {
  text-align: right;
}

.Polaris-DataTable__Cell--truncated {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: var(--pc-data-table-first-column-width);
  overflow-x: hidden;
}

.Polaris-DataTable__Cell--header {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  border-bottom: var(--p-border-divider);
  border-top: 0;
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--header {
  font-weight: var(--p-font-weight-medium);
  font-size: var(--p-font-size-1);
}

.Polaris-DataTable__Cell--sortable {
  padding: 0;
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--sortable {
  padding: var(--p-space-2) var(--p-space-3);
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--sortable:first-child {
  padding-left: var(--p-space-3);
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--sortable:last-child {
  padding-right: var(--p-space-3);
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Cell--sortable .Polaris-DataTable__Heading--left {
  padding-right: 0;
  padding-left: var(--p-space-1);
}

.Polaris-DataTable__Cell--verticalAlignTop {
  vertical-align: top;
}

.Polaris-DataTable__Cell--verticalAlignBottom {
  vertical-align: bottom;
}

.Polaris-DataTable__Cell--verticalAlignMiddle {
  vertical-align: middle;
}

.Polaris-DataTable__Cell--verticalAlignBaseline {
  vertical-align: baseline;
}

@media (min-width: 48em) {
  .Polaris-DataTable--hoverable .Polaris-DataTable__Cell--hovered {
    background: var(--p-surface-hovered);
  }
}

.Polaris-DataTable__Icon {
  opacity: 0;
  transition: opacity var(--p-duration-200) var(--p-ease), fill var(--p-duration-200) var(--p-ease);
  align-self: flex-end;
  display: flex;
}

.Polaris-DataTable__Heading {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: var(--p-text);
  transition: color var(--p-duration-200) var(--p-ease);
  cursor: pointer;
  padding: 0;
  padding: var(--p-space-2);
  margin: 0;
  margin: var(--p-space-2);
  background: none;
  border: none;
  justify-content: flex-end;
  align-items: baseline;
  display: inline-flex;
  position: relative;
}

.Polaris-DataTable__Heading:focus {
  outline: none;
}

.Polaris-DataTable__Heading:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-DataTable__Heading svg {
  fill: var(--p-icon);
}

.Polaris-DataTable__StickyHeaderEnabled [data-sticky-active] .Polaris-DataTable__Heading {
  visibility: hidden;
}

.Polaris-DataTable__StickyHeaderEnabled [data-sticky-active] .Polaris-DataTable__StickyTable .Polaris-DataTable__Heading {
  visibility: visible;
}

.Polaris-DataTable__IncreasedTableDensity .Polaris-DataTable__Heading {
  font-weight: var(--p-font-weight-medium);
  font-size: var(--p-font-size-1);
  padding: 0;
  padding-right: var(--p-space-1);
  margin: 0;
}

.Polaris-DataTable__Heading:hover {
  color: var(--p-interactive-hovered);
}

.Polaris-DataTable__Heading:hover svg {
  fill: var(--p-interactive-hovered);
}

.Polaris-DataTable__Heading:hover .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Heading:hover .Polaris-DataTable__Icon svg {
  fill: var(--p-icon-disabled);
}

.Polaris-DataTable__Heading:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DataTable__Heading:focus:not(:active) .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Heading:focus:not(:active) .Polaris-DataTable__Icon svg {
  fill: var(--p-icon-disabled);
}

.Polaris-DataTable__Heading--left {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.Polaris-DataTable__Cell--sorted .Polaris-DataTable__Icon {
  opacity: 1;
}

.Polaris-DataTable__Cell--sorted .Polaris-DataTable__Heading:focus:not(:active) svg {
  fill: var(--p-icon);
}

.Polaris-DataTable__Cell--total {
  font-weight: var(--p-font-weight-semibold);
  background: var(--p-surface-subdued);
  border-bottom: var(--p-border-divider);
}

.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotals .Polaris-DataTable__Cell--total {
  background: var(--p-surface-subdued);
}

.Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotals.Polaris-DataTable__RowCountIsEven .Polaris-DataTable__Cell--total, .Polaris-DataTable__ZebraStripingOnData.Polaris-DataTable__ShowTotalsInFooter .Polaris-DataTable__Cell--total {
  background: none;
}

.Polaris-DataTable--cellTotalFooter {
  border-top: var(--p-border-divider);
  border-bottom: none;
}

.Polaris-DataTable--cellTotalFooter:first-child {
  border-bottom-left-radius: var(--p-border-radius-2);
}

.Polaris-DataTable--cellTotalFooter:last-child {
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-DataTable__Footer {
  padding: var(--p-space-4);
  background: var(--p-surface-subdued);
  color: var(--p-text-subdued);
  text-align: center;
  border-top: var(--p-border-divider);
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-DataTable__ZebraStripingOnData .Polaris-DataTable__Footer {
  background: none;
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyTable {
  visibility: hidden;
  z-index: var(--p-z-1);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyTableHeader {
  width: 100%;
  border-spacing: 0;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyTableHeader:not(.Polaris-DataTable__StickyTableHeader--isSticky) {
  top: -624.938rem;
  left: -624.938rem;
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyTableColumnHeader--isScrolling {
  box-shadow: .0625rem .0625rem 0 0 var(--p-border-divider), .0625rem 0 .0625rem .0625rem #3f3f440d, .0625rem 0 .1875rem 0 #3f3f4426;
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyTableHeadingsRow {
  background-color: var(--p-surface);
  scrollbar-width: none;
  overflow-x: auto;
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyTableHeadingsRow::-webkit-scrollbar {
  appearance: none;
  height: 0;
  width: 0;
}

.Polaris-DataTable__StickyHeaderEnabled .Polaris-DataTable__StickyTableHeader--isSticky {
  visibility: visible;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-base);
}

.Polaris-DataTable__FixedFirstColumn {
  background: var(--p-surface);
  z-index: 3;
  border-spacing: 0;
  position: absolute;
}

@media (max-width: 47.9969em) {
  .Polaris-DataTable__FixedFirstColumn {
    z-index: 1;
  }
}

.Polaris-DataTable__FixedFirstColumn.Polaris-DataTable--separate th {
  border-right: var(--p-border-divider);
}

.Polaris-DataTable__TooltipContent {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.Polaris-Tooltip-TooltipOverlay {
  --pc-tooltip-overlay-offset: var(--p-space-1);
  margin: var(--pc-tooltip-overlay-offset) var(--p-space-4) var(--p-space-4);
  opacity: 1;
  box-shadow: var(--p-shadow-popover);
  border-radius: var(--p-border-radius-1);
  pointer-events: none;
  backface-visibility: hidden;
  will-change: opacity, left, top;
  transition: opacity var(--p-duration-200) var(--p-ease-in) var(--p-duration-100);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Tooltip-TooltipOverlay {
    border: var(--p-border-width-2) solid windowText;
  }
}

.Polaris-Tooltip-TooltipOverlay--measuring {
  opacity: 0;
}

.Polaris-Tooltip-TooltipOverlay--positionedAbove {
  margin: var(--p-space-4) var(--p-space-4) var(--pc-tooltip-overlay-offset);
}

.Polaris-Tooltip-TooltipOverlay__Content {
  border-radius: var(--p-border-radius-base);
  background-color: var(--p-surface);
  color: var(--p-text);
  max-width: 12.5rem;
  padding: var(--p-space-1) var(--p-space-2);
  word-break: break-word;
  position: relative;
}

[data-polaris-tooltip-activator] {
  outline: 0;
  position: relative;
}

[data-polaris-tooltip-activator]:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

[data-polaris-tooltip-activator]:focus-visible:after, [data-polaris-tooltip-activator][data-focus-visible-added]:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DatePicker {
  --pc-date-picker-range-end-border-radius: var(--p-border-radius-6);
  position: relative;
}

.Polaris-DatePicker__MonthLayout {
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-4) * -1);
  flex-wrap: wrap;
  display: flex;
}

.Polaris-DatePicker__MonthContainer {
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-4);
  max-width: calc(100% - var(--p-space-4));
  min-width: 14.375rem;
  flex: 14.375rem;
}

.Polaris-DatePicker__Month {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}

.Polaris-DatePicker__Month--current {
  font-weight: var(--p-font-weight-bold);
}

.Polaris-DatePicker__DayCell {
  width: 14.2857%;
  border-radius: var(--p-border-radius-1);
  background: none;
  margin: 0;
  padding: 0;
}

.Polaris-DatePicker__DayCell--inRange {
  border-radius: 0;
}

.Polaris-DatePicker__Day {
  height: 100%;
  width: 100%;
  padding: var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  font-size: var(--p-font-size-1);
  text-align: center;
  color: var(--p-text);
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  display: block;
  position: relative;
}

.Polaris-DatePicker__Day:hover {
  background: var(--p-interactive-hovered);
  color: var(--p-text-on-interactive);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DatePicker__Day:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-DatePicker__Day:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DatePicker__Day--today {
  font-weight: var(--p-font-weight-bold);
}

.Polaris-DatePicker__Day--inRange {
  background: var(--p-surface-selected);
  border-radius: 0;
}

@media (-ms-high-contrast: active) {
  .Polaris-DatePicker__Day--inRange {
    -ms-high-contrast-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }

  .Polaris-DatePicker__Day--inRange:hover {
    background-color: HighlightText;
    color: Highlight;
    outline: var(--p-border-width-2) solid Highlight;
  }
}

.Polaris-DatePicker__Day--selected {
  background: var(--p-interactive);
  color: var(--p-text-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-DatePicker__Day--selected {
    -ms-high-contrast-adjust: none;
    background-color: Highlight;
    color: HighlightText;
  }

  .Polaris-DatePicker__Day--selected:hover {
    background-color: HighlightText;
    color: Highlight;
    outline: var(--p-border-width-2) solid Highlight;
  }
}

.Polaris-DatePicker__Day--disabled, .Polaris-DatePicker__Day--disabled:hover {
  color: var(--p-text-disabled);
  background-color: #0000;
}

@media (-ms-high-contrast) {
  .Polaris-DatePicker__Day--disabled {
    -ms-high-contrast-adjust: none;
    color: grayText;
  }

  .Polaris-DatePicker__Day--disabled:hover {
    color: grayText;
    outline: none;
  }
}

.Polaris-DatePicker__Day--disabled:focus:after {
  content: none;
}

.Polaris-DatePicker__EmptyDayCell {
  width: 14.2857%;
  margin: 0;
  padding: 0;
}

.Polaris-DatePicker__Weekday {
  padding: var(--p-space-2);
  font-size: var(--p-font-size-1);
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text-subdued);
  text-align: center;
  background: none;
}

.Polaris-DatePicker__Weekday--current {
  font-weight: var(--p-font-weight-bold);
  color: var(--p-text);
}

.Polaris-DatePicker__Header {
  top: var(--p-space-4);
  width: 100%;
  justify-content: space-between;
  display: flex;
  position: absolute;
}

.Polaris-DatePicker__Title {
  margin-top: var(--p-space-05);
  padding-bottom: var(--p-space-1);
  text-align: center;
  flex: auto;
}

.Polaris-DatePicker__Day--firstInRange {
  border-radius: var(--p-border-radius-1);
}

.Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange, .Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight, .Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hasRange:after, .Polaris-DatePicker__Day--firstInRange.Polaris-DatePicker__Day--hoverRight:after {
  border-radius: var(--pc-date-picker-range-end-border-radius) 0 0 var(--pc-date-picker-range-end-border-radius);
}

.Polaris-DatePicker__Day--lastInRange, .Polaris-DatePicker__Day--lastInRange:after {
  border-radius: 0 var(--pc-date-picker-range-end-border-radius) var(--pc-date-picker-range-end-border-radius) 0;
}

.Polaris-DatePicker__Week {
  margin-bottom: var(--p-space-05);
}

.Polaris-DatePicker__Week > .Polaris-DatePicker__Day--inRange:first-child:not(.Polaris-DatePicker__Day--firstInRange):not(.Polaris-DatePicker__Day--lastInRange) {
  border-radius: var(--p-border-radius-1) 0 0 var(--p-border-radius-1);
}

.Polaris-DatePicker__Week > .Polaris-DatePicker__Day--inRange:last-child:not(.Polaris-DatePicker__Day--firstInRange):not(.Polaris-DatePicker__Day--lastInRange) {
  border-radius: 0 var(--p-border-radius-1) var(--p-border-radius-1) 0;
}

.Polaris-DatePicker__Day--inRange:after, .Polaris-DatePicker__Day--inRange:not(:hover) + .Polaris-DatePicker__Day:after {
  border-radius: 0 var(--pc-date-picker-range-end-border-radius) var(--pc-date-picker-range-end-border-radius) 0;
}

.Polaris-DescriptionList {
  word-break: break-word;
  margin: 0;
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList {
    flex-wrap: wrap;
    align-items: flex-start;
    display: flex;
  }
}

.Polaris-DescriptionList__Term {
  font-weight: var(--p-font-weight-semibold);
  padding: var(--p-space-4) 0 var(--p-space-2);
}

.Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
  padding: var(--p-space-2) 0 var(--p-space-1);
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList__Term {
    padding: var(--p-space-4) var(--p-space-4) var(--p-space-4) 0;
    flex: 0 25%;
  }

  .Polaris-DescriptionList--spacingTight [data-has-navigation] .Polaris-DescriptionList__Term {
    padding: var(--p-space-2) var(--p-space-2) var(--p-space-2) 0;
  }

  .Polaris-DescriptionList__Description + [data-has-navigation] .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: var(--p-border-divider);
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList__Term {
    padding: var(--p-space-4) var(--p-space-4) var(--p-space-4) 0;
    flex: 0 25%;
  }

  .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Term {
    padding: var(--p-space-2) var(--p-space-2) var(--p-space-2) 0;
  }

  .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: var(--p-border-divider);
  }
}

.Polaris-DescriptionList__Description {
  padding: 0 0 var(--p-space-4);
  margin-left: 0;
}

.Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
  padding: 0 0 var(--p-space-2);
}

.Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term {
  border-top: var(--p-border-divider);
}

@media (max-width: 48.0625em) and (min-width: 36.875em), (min-width: 51.875em) {
  [data-has-navigation] .Polaris-DescriptionList__Description {
    padding: var(--p-space-4) 0;
    flex: 51%;
  }

  .Polaris-DescriptionList--spacingTight [data-has-navigation] .Polaris-DescriptionList__Description {
    padding: var(--p-space-2) 0;
  }

  [data-has-navigation] .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: var(--p-border-divider);
  }
}

@media (min-width: 36.875em) {
  .Polaris-DescriptionList__Description {
    padding: var(--p-space-4) 0;
    flex: 51%;
  }

  .Polaris-DescriptionList--spacingTight .Polaris-DescriptionList__Description {
    padding: var(--p-space-2) 0;
  }

  .Polaris-DescriptionList__Description + .Polaris-DescriptionList__Term + .Polaris-DescriptionList__Description {
    border-top: var(--p-border-divider);
  }
}

.Polaris-DisplayText {
  margin: 0;
}

.Polaris-DisplayText--sizeSmall {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeSmall {
    font-size: var(--p-font-size-7);
    line-height: var(--p-line-height-4);
  }
}

.Polaris-DisplayText--sizeMedium {
  font-size: var(--p-font-size-8);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-4);
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeMedium {
    font-size: var(--p-font-size-10);
    line-height: var(--p-line-height-5);
  }
}

.Polaris-DisplayText--sizeLarge {
  font-size: var(--p-font-size-9);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-4);
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeLarge {
    font-size: var(--p-font-size-12);
    line-height: var(--p-line-height-5);
  }
}

.Polaris-DisplayText--sizeExtraLarge {
  font-size: var(--p-font-size-11);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-6);
}

@media (min-width: 40em) {
  .Polaris-DisplayText--sizeExtraLarge {
    font-size: var(--p-font-size-13);
    line-height: var(--p-line-height-7);
  }
}

.Polaris-DropZone-FileUpload {
  padding: var(--p-space-4);
  text-align: center;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Polaris-DropZone-FileUpload--large {
  padding: var(--p-space-8);
}

.Polaris-DropZone-FileUpload--small {
  padding: var(--p-space-3);
}

.Polaris-DropZone-FileUpload img {
  vertical-align: bottom;
}

.Polaris-DropZone-FileUpload__Action {
  border-radius: var(--p-border-radius-base);
  padding: var(--p-space-1) var(--p-space-2);
  color: var(--p-interactive-hovered);
  background: var(--p-surface-selected-pressed);
  font-size: var(--p-font-size-1);
  font-weight: var(--p-font-weight-semibold);
  cursor: pointer;
  text-align: center;
  appearance: none;
  border: none;
  flex: none;
  margin: 0;
  line-height: 1;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.Polaris-DropZone-FileUpload__Action:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-DropZone-FileUpload__Action:hover {
  color: var(--p-interactive-pressed);
}

.Polaris-DropZone-FileUpload__Action.Polaris-DropZone-FileUpload--disabled {
  box-shadow: none;
  border-color: var(--p-border-disabled);
  background: var(--p-surface-disabled);
  color: var(--p-text-disabled);
  cursor: not-allowed;
  box-shadow: none;
  transition: none;
}

.Polaris-DropZone-FileUpload__Action.Polaris-DropZone-FileUpload--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-DropZone-FileUpload__ActionTitle {
  color: var(--p-interactive);
  text-decoration: none;
}

.Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled) {
  cursor: pointer;
}

.Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):hover, .Polaris-DropZone-FileUpload__ActionTitle:not(.Polaris-DropZone-FileUpload__ActionTitle--disabled):active {
  color: var(--p-interactive-pressed);
  text-decoration: underline;
}

.Polaris-DropZone-FileUpload__ActionTitle--focused {
  text-decoration: underline;
}

.Polaris-DropZone-FileUpload__ActionTitle--disabled {
  color: var(--p-interactive-disabled);
}

.Polaris-DropZone {
  --pc-drop-zone-outline: 29;
  --pc-drop-zone-overlay: 30;
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-1);
  justify-content: center;
  display: flex;
  position: relative;
}

.Polaris-DropZone:after {
  content: "";
  z-index: var(--pc-drop-zone-outline);
  border: var(--p-border-width-1) dashed transparent;
  border-radius: var(--p-border-radius-1);
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.Polaris-DropZone:not(.Polaris-DropZone--focused):after {
  opacity: 1;
  border: var(--p-border-width-1) dashed transparent;
  inset: 0;
  transform: scale(1);
}

.Polaris-DropZone:hover {
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-DropZone--hasOutline {
  padding: var(--p-space-025);
}

.Polaris-DropZone--hasOutline:after {
  border-color: var(--p-border-neutral-subdued);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover {
  cursor: pointer;
  background-color: var(--p-surface-subdued);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--isDisabled):hover:after {
  border-color: var(--p-interactive-hovered);
}

.Polaris-DropZone--hasOutline:not(.Polaris-DropZone--focused):after {
  opacity: 1;
  border: var(--p-border-width-1) dashed transparent;
  border-radius: var(--p-border-radius-large);
  border-color: var(--p-border-neutral-subdued);
  inset: 0;
  transform: scale(1);
}

.Polaris-DropZone--isDragging:not(.Polaris-DropZone--isDisabled) {
  background-color: var(--p-surface-hovered);
}

.Polaris-DropZone--isDisabled {
  cursor: not-allowed;
}

.Polaris-DropZone--isDisabled:after {
  border-color: var(--p-border-disabled);
}

.Polaris-DropZone--sizeLarge {
  min-height: 7.5rem;
}

.Polaris-DropZone--sizeMedium {
  min-height: 6.25rem;
  align-items: center;
}

.Polaris-DropZone--sizeSmall {
  min-height: 3.125rem;
  align-items: center;
  padding: 0;
}

.Polaris-DropZone--measuring {
  visibility: hidden;
  min-height: 0;
}

.Polaris-DropZone__Container {
  flex: 1;
  position: relative;
}

.Polaris-DropZone__Container:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-DropZone__Overlay {
  border-radius: var(--p-border-radius-large);
  z-index: var(--pc-drop-zone-overlay);
  padding: var(--p-space-4);
  border: var(--p-border-width-1) dashed var(--p-interactive);
  text-align: center;
  color: var(--p-interactive);
  background-color: var(--p-surface-selected);
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.Polaris-DropZone--hasError .Polaris-DropZone__Overlay {
  border-color: var(--p-border-critical);
  color: var(--p-text-critical);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-DropZone--sizeSmall .Polaris-DropZone__Overlay {
  padding: 0;
}

.Polaris-DropZone--focused:not(.Polaris-DropZone--isDisabled) .Polaris-DropZone__Container:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-EmptyState {
  width: 100%;
  padding: var(--p-space-5) 0;
  padding-top: var(--p-space-5);
  padding-bottom: calc(var(--p-space-5) * 3);
  max-width: 62.375rem;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.Polaris-EmptyState__Section {
  width: 100%;
  flex-direction: column-reverse;
  flex: auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState__Section {
    left: var(--p-space-5);
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState__Section {
    left: var(--p-space-5);
  }
}

.Polaris-EmptyState__Details {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-EmptyState__Details {
    max-width: 25rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-EmptyState__Details {
    max-width: 25rem;
  }
}

.Polaris-EmptyState__Image {
  width: initial;
  margin: 0;
}

.Polaris-EmptyState__ImageContainer, .Polaris-EmptyState__DetailsContainer {
  flex: auto;
  margin: 0;
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState__ImageContainer, [data-has-navigation] .Polaris-EmptyState__DetailsContainer {
    flex-basis: 50%;
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState__ImageContainer, .Polaris-EmptyState__DetailsContainer {
    flex-basis: 50%;
  }
}

.Polaris-EmptyState--withinContentContainer {
  padding-top: var(--p-space-5);
  padding-bottom: calc(var(--p-space-5) * 3);
  margin: 0 auto;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Section {
  position: unset;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
    max-width: 25rem;
  }
}

@media (min-width: 30.625em) {
  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Details {
    max-width: 25rem;
  }
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Image {
  width: initial;
  margin: 0;
}

.Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Content {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  padding-bottom: var(--p-space-2);
}

@media (min-width: 40em) {
  .Polaris-EmptyState--withinContentContainer .Polaris-EmptyState__Content {
    font-size: var(--p-font-size-3);
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image {
    position: initial;
    width: 100%;
  }
}

@media (min-width: 46.5em) {
  .Polaris-EmptyState--imageContained .Polaris-EmptyState__Image {
    position: initial;
    width: 100%;
  }
}

.Polaris-EmptyState--fullWidth .Polaris-EmptyState__Details {
  max-width: 100%;
}

.Polaris-EmptyState__Content {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  padding-bottom: var(--p-space-2);
}

@media (min-width: 40em) {
  .Polaris-EmptyState__Content {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-EmptyState__Actions {
  margin-top: var(--p-space-4);
}

.Polaris-EmptyState__FooterContent {
  color: var(--p-text-subdued);
  margin-top: var(--p-space-4);
}

.Polaris-Truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.Polaris-ExceptionList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-ExceptionList__Item {
  padding-left: var(--p-space-6);
  color: var(--p-text-subdued);
  position: relative;
}

.Polaris-ExceptionList__Item + .Polaris-ExceptionList__Item {
  margin-top: var(--p-space-1);
}

.Polaris-ExceptionList__Icon {
  min-width: var(--p-space-5);
  height: var(--p-space-5);
  margin-right: var(--p-space-1);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-subdued);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Icon svg {
  fill: var(--p-icon-critical);
}

.Polaris-ExceptionList__Bullet {
  width: .375rem;
  height: .375rem;
  border-radius: var(--p-border-radius-half);
  background-color: var(--p-icon-subdued);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Bullet {
  background-color: var(--p-icon-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Bullet {
  background-color: var(--p-icon-critical);
}

.Polaris-ExceptionList__Title + .Polaris-ExceptionList__Description:before {
  content: "–";
  margin: 0 var(--p-space-1);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title, .Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title {
  font-weight: var(--p-font-weight-medium);
}

.Polaris-ExceptionList--statusWarning .Polaris-ExceptionList__Title {
  color: var(--p-text-warning);
}

.Polaris-ExceptionList--statusCritical .Polaris-ExceptionList__Title {
  color: var(--p-text-critical);
}

.Polaris-Tag {
  max-width: 100%;
  min-height: 1.75rem;
  padding: 0 var(--p-space-2);
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  align-items: center;
  display: inline-flex;
}

.Polaris-Tag.Polaris-Tag--disabled {
  background: var(--p-surface-neutral-disabled);
  color: var(--p-text-disabled);
  transition: none;
}

.Polaris-Tag.Polaris-Tag--disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Tag.Polaris-Tag--clickable {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  cursor: pointer;
  padding: 0;
  padding: var(--p-space-1) var(--p-space-2);
  background: none;
  background-color: var(--p-surface-neutral);
  outline: var(--p-border-width-1) solid transparent;
  font-size: var(--p-font-size-2);
  line-height: var(--p-line-height-2);
  border: none;
  margin: 0;
  position: relative;
}

.Polaris-Tag.Polaris-Tag--clickable:focus {
  outline: none;
}

.Polaris-Tag.Polaris-Tag--clickable:hover {
  background: var(--p-surface-neutral-hovered);
}

.Polaris-Tag.Polaris-Tag--clickable:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Tag.Polaris-Tag--clickable:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tag.Polaris-Tag--clickable:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-Tag.Polaris-Tag--clickable:disabled {
  background: var(--p-surface-neutral-disabled);
  cursor: default;
  pointer-events: none;
  color: var(--p-text-disabled);
}

@media (min-width: 30.625em) {
  .Polaris-Tag.Polaris-Tag--clickable {
    padding: var(--p-space-05) var(--p-space-2);
    font-size: var(--p-font-size-1);
  }
}

.Polaris-Tag.Polaris-Tag--removable {
  padding-right: 0;
}

.Polaris-Tag.Polaris-Tag--linkable {
  padding: 0;
}

@media (min-width: 30.625em) {
  .Polaris-Tag {
    min-height: 1.5rem;
  }
}

.Polaris-Tag__TagText {
  font-size: var(--p-font-size-2);
  line-height: var(--p-line-height-2);
  min-height: 1.25rem;
  padding: var(--p-space-1) 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
}

@media (min-width: 30.625em) {
  .Polaris-Tag__TagText {
    font-size: var(--p-font-size-1);
    padding: var(--p-space-05) 0;
  }
}

.Polaris-Tag__Button {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
  margin: 0;
  margin: 0 var(--p-space-1);
  border-radius: var(--p-border-radius-half);
  background: none;
  border: none;
  flex-shrink: 0;
  padding: 0;
  display: block;
  position: relative;
}

.Polaris-Tag__Button svg {
  fill: var(--p-icon);
}

.Polaris-Tag__Button:focus {
  outline: none;
}

.Polaris-Tag__Button:hover {
  background: var(--p-surface-neutral-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tag__Button:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Tag__Button:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tag__Button:active {
  background: var(--p-surface-neutral-pressed);
}

.Polaris-Tag__Button:disabled {
  cursor: default;
  pointer-events: none;
}

.Polaris-Tag__Button:disabled svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Tag__Button.Polaris-Tag--segmented {
  margin-left: calc(var(--p-space-1) * -1);
}

.Polaris-Tag__Link {
  color: var(--p-text);
  border-radius: var(--p-border-radius-base);
  min-height: 1.25rem;
  padding: 0 var(--p-space-2);
  outline: none;
  text-decoration: none;
  display: inline-grid;
  position: relative;
}

.Polaris-Tag__Link .Polaris-Tag__LinkText {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--p-font-size-2);
  line-height: var(--p-line-height-2);
  padding-top: var(--p-space-1);
  padding-bottom: var(--p-space-1);
  overflow: hidden;
}

@media (min-width: 30.625em) {
  .Polaris-Tag__Link .Polaris-Tag__LinkText {
    font-size: var(--p-font-size-1);
    padding-top: var(--p-space-05);
    padding-bottom: var(--p-space-05);
  }
}

.Polaris-Tag__Link:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Tag__Link:focus:not(:active) {
  text-decoration: underline;
}

.Polaris-Tag__Link:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tag__Link:hover {
  background: var(--p-surface-neutral-hovered);
  text-decoration: underline;
}

.Polaris-Tag__Link.Polaris-Tag--segmented:hover {
  background: none;
}

.Polaris-Tag__Link.Polaris-Tag--segmented:after {
  margin-right: var(--p-space-1);
}

.Polaris-Sheet {
  width: 100%;
  height: 100%;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-modal);
  position: fixed;
  bottom: 0;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Sheet {
    border-left: var(--p-border-base);
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Sheet {
    width: 23.75rem;
    right: 0;
  }
}

.Polaris-Sheet:focus {
  outline: 0;
}

.Polaris-Sheet__Container {
  z-index: var(--p-z-11);
  position: fixed;
  inset: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Sheet__Container {
    width: 23.75rem;
    left: auto;
  }
}

.Polaris-Sheet__Bottom {
  backface-visibility: hidden;
  will-change: transform;
  transition: transform var(--p-duration-300) var(--p-ease);
  transform-origin: bottom;
}

.Polaris-Sheet--enterBottom {
  transform: translateY(100%);
}

.Polaris-Sheet--enterBottomActive, .Polaris-Sheet--exitBottom {
  transform: translateY(0%);
}

.Polaris-Sheet--exitBottomActive {
  transform: translateY(100%);
}

.Polaris-Sheet__Right {
  backface-visibility: hidden;
  will-change: transform;
  transition: transform var(--p-duration-300) var(--p-ease);
  transform-origin: 100%;
}

.Polaris-Sheet--enterRight {
  transform: translateX(100%);
}

.Polaris-Sheet--enterRightActive, .Polaris-Sheet--exitRight {
  transform: translateX(0%);
}

.Polaris-Sheet--exitRightActive {
  transform: translateX(100%);
}

.Polaris-Filters-ConnectedFilterControl {
  --pc-connceted-filter-control-item: 10;
  --pc-connceted-filter-control-focused: 20;
  flex-grow: 1;
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl .Polaris-Filters-ConnectedFilterControl__CenterContainer {
  min-width: 6.25rem;
  flex: auto;
}

.Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right .Polaris-Filters-ConnectedFilterControl__CenterContainer * {
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__Item {
  z-index: var(--pc-connceted-filter-control-item);
  position: relative;
}

.Polaris-Filters-ConnectedFilterControl__Item--focused {
  z-index: var(--pc-connceted-filter-control-focused);
}

.Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer {
  width: 100%;
  height: 0;
  visibility: hidden;
  display: flex;
  position: absolute;
  top: -62.5rem;
  left: -62.5rem;
  overflow: hidden;
}

.Polaris-Filters-ConnectedFilterControl__ProxyButtonContainer > * {
  flex-shrink: 0;
}

.Polaris-Filters-ConnectedFilterControl__CenterContainer + .Polaris-Filters-ConnectedFilterControl__RightContainer, .Polaris-Filters-ConnectedFilterControl__CenterContainer + .Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  margin-left: var(--p-space-2);
}

.Polaris-Filters-ConnectedFilterControl__RightContainer {
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item > div > button {
  margin-right: var(--p-button-group-item-spacing);
  border-radius: 0;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item {
  flex-shrink: 0;
}

.Polaris-Filters-ConnectedFilterControl__RightContainer .Polaris-Filters-ConnectedFilterControl__Item:first-of-type > div > button, .Polaris-Filters-ConnectedFilterControl__RightContainer.Polaris-Filters-ConnectedFilterControl--queryFieldHidden .Polaris-Filters-ConnectedFilterControl__Item:first-of-type > div > button {
  border-top-left-radius: var(--p-border-radius-1);
  border-bottom-left-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__RightContainerWithoutMoreFilters .Polaris-Filters-ConnectedFilterControl__Item:last-child > div > button {
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer .Polaris-Filters-ConnectedFilterControl__Item > div > button {
  white-space: nowrap;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer.Polaris-Filters-ConnectedFilterControl--onlyButtonVisible .Polaris-Filters-ConnectedFilterControl__Item > div > button {
  border-radius: var(--p-border-radius-1);
}

.Polaris-Filters-ConnectedFilterControl__Wrapper {
  display: flex;
}

.Polaris-Filters-ConnectedFilterControl__AuxiliaryContainer {
  flex-grow: 0;
}

.Polaris-Filters {
  position: relative;
}

.Polaris-Filters__FiltersContainer {
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
}

.Polaris-Filters__FiltersContainerHeader {
  width: 100%;
  padding: var(--p-space-4) var(--p-space-5);
  border-bottom: var(--p-border-divider);
  height: 3.5rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  display: flex;
  top: 0;
}

.Polaris-Filters__FiltersDesktopContainerContent {
  width: 100%;
  height: calc(100% - 7.875rem);
  padding: var(--p-space-2);
}

.Polaris-Filters__FiltersMobileContainerContent {
  width: 100%;
  height: calc(100% - 3.5rem);
  padding: var(--p-space-2);
}

.Polaris-Filters__FiltersContainerFooter {
  width: 100%;
  padding: var(--p-space-4) var(--p-space-5);
  border-top: var(--p-border-divider);
  height: 4.375rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
}

.Polaris-Filters__FiltersMobileContainerFooter {
  width: 100%;
  padding: var(--p-space-4) var(--p-space-4);
  height: 4.375rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Polaris-Filters__EmptyFooterState {
  border-top: var(--p-border-divider);
  padding-top: var(--p-space-4);
  width: 100%;
  justify-content: center;
  display: flex;
}

.Polaris-Filters__FilterTriggerContainer {
  position: relative;
}

.Polaris-Filters__FilterTrigger {
  width: 100%;
  padding: var(--p-space-4) var(--p-space-5);
  color: var(--p-text);
  border-radius: var(--p-border-radius-1);
  background: none;
  border: none;
  outline: none;
  margin: 0;
  position: relative;
}

.Polaris-Filters__FilterTrigger:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Filters__FilterTrigger:focus {
  box-shadow: none;
}

.Polaris-Filters__FilterTrigger:hover {
  cursor: pointer;
  background-color: var(--p-surface-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Filters__FilterTrigger:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Filters__FilterTriggerTitle {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-semibold);
}

.Polaris-Filters__AppliedFilterBadgeContainer {
  padding-top: var(--p-space-1);
  display: flex;
}

.Polaris-Filters--open .Polaris-Filters__AppliedFilterBadgeContainer {
  display: none;
}

.Polaris-Filters__FilterTriggerLabelContainer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Polaris-Filters--open:before, .Polaris-Filters--open:after {
  content: "";
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
  position: relative;
}

.Polaris-Filters--open:before {
  top: 0;
}

.Polaris-Filters--open:after {
  bottom: 0;
}

.Polaris-Filters--open.Polaris-Filters--first:after {
  content: "";
  bottom: 0;
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
  position: relative;
}

.Polaris-Filters--open.Polaris-Filters--first:before, .Polaris-Filters--open ~ .Polaris-Filters--open:before {
  display: none;
}

.Polaris-Filters--open.Polaris-Filters--last:before {
  content: "";
  top: 0;
  left: var(--p-space-4);
  width: calc(100% - var(--p-space-8));
  height: var(--p-space-025);
  background-color: var(--p-surface-subdued);
  display: block;
  position: relative;
}

.Polaris-Filters--open.Polaris-Filters--last:after, .Polaris-Filters--open + .Polaris-Filters--last:before {
  display: none;
}

.Polaris-Filters__FilterNodeContainer {
  padding: var(--p-space-2) var(--p-space-5) var(--p-space-5) var(--p-space-5);
}

.Polaris-Filters__SearchIcon {
  fill: currentColor;
}

.Polaris-Filters__Backdrop {
  z-index: var(--p-z-10);
  opacity: 0;
  display: block;
  position: fixed;
  inset: 0;
}

.Polaris-Filters__HelpText {
  margin-top: var(--p-space-2);
}

.Polaris-Filters__TagsContainer {
  padding-top: var(--p-space-2);
  flex-wrap: wrap;
  display: flex;
}

.Polaris-Filters__TagsContainer > * {
  margin-right: var(--p-space-2);
  margin-bottom: var(--p-space-2);
}

.Polaris-FooterHelp {
  margin: var(--p-space-5) 0;
  width: 100%;
  justify-content: center;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-FooterHelp {
    margin: var(--p-space-5);
    width: auto;
  }
}

@media (min-width: 30.625em) {
  .Polaris-FooterHelp {
    margin: var(--p-space-5);
    width: auto;
  }
}

.Polaris-FooterHelp__Text {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  text-transform: initial;
  letter-spacing: initial;
  border: none;
}

@media (min-width: 40em) {
  .Polaris-FooterHelp__Text {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-FormLayout {
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-5) * -1);
}

.Polaris-FormLayout__Title {
  margin-bottom: calc(var(--p-space-2) * -1);
  padding: var(--p-space-4) var(--p-space-5) 0;
}

.Polaris-FormLayout__Items {
  flex-wrap: wrap;
  display: flex;
}

.Polaris-FormLayout__HelpText {
  color: var(--p-text-subdued);
  padding: var(--p-space-2) var(--p-space-5) 0;
}

.Polaris-FormLayout__Item {
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-5);
  max-width: calc(100% - var(--p-space-5));
  flex: 13.75rem;
}

.Polaris-FormLayout--grouped .Polaris-FormLayout__Item {
  min-width: 13.75rem;
}

.Polaris-FormLayout--condensed .Polaris-FormLayout__Item {
  min-width: 6.875rem;
  flex-basis: 6.875rem;
}

.Polaris-Frame-Toast {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  max-width: 31.25rem;
  padding: var(--p-space-2) var(--p-space-4);
  border-radius: var(--p-border-radius-2);
  background: var(--p-surface);
  color: var(--p-text);
  margin-bottom: var(--p-space-5);
  display: inline-flex;
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast {
    font-size: var(--p-font-size-7);
    line-height: var(--p-line-height-4);
  }
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast {
    padding: var(--p-space-4);
  }
}

@media (forced-colors: active) {
  .Polaris-Frame-Toast {
    border: var(--p-border-width-2) solid transparent;
  }
}

.Polaris-Frame-Toast__Action {
  margin-left: var(--p-space-8);
  margin-right: var(--p-space-4);
}

.Polaris-Frame-Toast--error {
  background: var(--p-action-critical);
  color: var(--p-text-on-critical);
}

.Polaris-Frame-Toast--error .Polaris-Frame-Toast__CloseButton {
  fill: var(--p-icon-on-critical);
}

.Polaris-Frame-Toast__CloseButton {
  margin-right: calc(var(--p-space-4) * -1);
  padding: 0 var(--p-space-4);
  appearance: none;
  color: currentColor;
  cursor: pointer;
  background: none;
  border: none;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
  display: flex;
}

.Polaris-Frame-Toast__CloseButton svg {
  fill: currentColor;
}

@media (min-width: 40em) {
  .Polaris-Frame-Toast__CloseButton {
    margin: calc(var(--p-space-2) * -1) calc(var(--p-space-4) * -1) calc(var(--p-space-2) * -1) 0;
    padding: var(--p-space-3) var(--p-space-4) var(--p-space-2);
    align-self: flex-start;
  }
}

.Polaris-Frame-Toast__CloseButton:focus {
  outline: none;
}

:root {
  --pc-toast-manager-translate-y-out: 9.375rem;
  --pc-toast-manager-translate-y-in: 0;
}

.Polaris-Frame-ToastManager {
  z-index: var(--p-z-12);
  text-align: center;
  left: 0;
  right: 0;
  bottom: var(--pc-frame-global-ribbon-height);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
}

.Polaris-Frame-ToastManager__ToastWrapper {
  opacity: 0;
  transition: transform var(--p-duration-400) ease, opacity var(--p-duration-400) ease;
  transform: translateY(var(--pc-toast-manager-translate-y-out));
  display: inline-flex;
  position: absolute;
}

.Polaris-Frame-ToastManager__ToastWrapper--enter, .Polaris-Frame-ToastManager__ToastWrapper--exit {
  transform: translateY(var(--pc-toast-manager-translate-y-out));
  opacity: 0;
}

.Polaris-Frame-ToastManager--toastWrapperEnterDone {
  transform: translateY(var(--pc-toast-manager-translate-y-in));
  opacity: 1;
}

.Polaris-Frame-Loading {
  height: .1875rem;
  background-color: var(--p-surface);
  opacity: 1;
  overflow: hidden;
}

.Polaris-Frame-Loading__Level {
  width: 100%;
  height: 100%;
  transform-origin: 0;
  background-color: var(--p-icon-success);
  transition: transform var(--p-duration-500) linear;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-Frame-Loading__Level {
    background-color: highlight;
  }
}

.Polaris-Modal-Dialog__Container {
  z-index: var(--p-z-11);
  pointer-events: none;
  flex-direction: column;
  justify-content: flex-end;
  display: flex;
  position: fixed;
  inset: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Container {
    justify-content: center;
  }
}

.Polaris-Modal-Dialog:focus {
  outline: 0;
}

.Polaris-Modal-Dialog__Modal {
  pointer-events: initial;
  width: 100%;
  max-height: calc(100vh - 3.75rem);
  background: var(--p-surface);
  box-shadow: var(--p-shadow-modal);
  flex-direction: column;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (forced-colors: active) {
  .Polaris-Modal-Dialog__Modal {
    border: var(--p-border-width-1) solid transparent;
  }
}

@media (max-width: 48.0525em) {
  .Polaris-Modal-Dialog__Modal {
    max-height: 100%;
    bottom: 0;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal {
    max-width: 38.75rem;
    border-radius: var(--p-border-radius-2);
    margin: 0 auto;
    position: relative;
  }
}

@media (min-width: 48.0625em) and (min-height: 660px) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--limitHeight {
    max-height: 37.5rem;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
    max-width: calc(100% - var(--p-space-16));
  }
}

@media (min-width: 27.75em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeSmall {
    max-width: 23.75rem;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
    max-width: calc(100% - var(--p-space-16));
  }
}

@media (min-width: 65.25em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge {
    max-width: 61.25rem;
  }
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--fullScreen {
  height: 100%;
}

@media (min-width: 48.0625em) {
  .Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--fullScreen {
    height: unset;
  }
}

.Polaris-Modal-Dialog--animateFadeUp {
  backface-visibility: hidden;
  will-change: transform, opacity;
  opacity: 1;
  transition: transform var(--p-ease) var(--p-duration-200), opacity var(--p-ease) var(--p-duration-200);
  transform: translateY(0);
}

.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entering, .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exiting, .Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--exited {
  opacity: 0;
  transform: translateY(12.5rem);
}

.Polaris-Modal-Dialog--animateFadeUp.Polaris-Modal-Dialog--entered {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-Modal-Footer {
  width: 100%;
  min-height: var(--p-space-16);
  padding: var(--p-space-4);
  border-top: var(--p-border-divider);
  align-self: flex-end;
  align-items: center;
  display: flex;
}

.Polaris-Modal-Footer__FooterContent {
  width: 100%;
}

.Polaris-Modal-CloseButton {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  margin: 0;
  margin-left: var(--p-space-5);
  margin-right: calc(var(--p-space-2) * -1);
  padding: 0;
  padding: var(--p-space-2);
  border-radius: var(--p-border-radius-large);
  background: none;
  border: none;
  position: relative;
}

.Polaris-Modal-CloseButton:focus {
  outline: none;
}

.Polaris-Modal-CloseButton:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Modal-CloseButton:hover {
  background: var(--p-surface-hovered);
}

.Polaris-Modal-CloseButton:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Modal-CloseButton:active {
  background: var(--p-surface-pressed);
}

.Polaris-Modal-CloseButton:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Modal-CloseButton.Polaris-Modal-CloseButton--titleHidden {
  margin: var(--p-space-2);
}

.Polaris-Modal-Header {
  padding: var(--p-space-4) var(--p-space-5);
  border-bottom: var(--p-border-divider);
  flex-shrink: 0;
  align-items: flex-start;
  display: flex;
}

.Polaris-Modal-Header--titleHidden {
  z-index: 1;
  width: 100%;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  right: 0;
}

.Polaris-Modal-Header--titleHidden .Polaris-Modal-Header__Title {
  display: none;
}

.Polaris-Modal-Header__Title {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-top: var(--p-space-1);
  flex: 1;
}

.Polaris-Modal-Section {
  padding: var(--p-space-5);
  flex: none;
}

.Polaris-Modal-Section:not(:last-of-type) {
  border-bottom: var(--p-border-divider);
}

.Polaris-Modal-Section.Polaris-Modal-Section--subdued {
  background: var(--p-surface-subdued);
}

.Polaris-Modal-Section.Polaris-Modal-Section--flush {
  padding: 0;
}

.Polaris-Modal-Section.Polaris-Modal-Section--titleHidden {
  padding-right: calc(var(--p-space-12)  + var(--p-space-1));
}

.Polaris-Modal__BodyWrapper {
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  display: flex;
  overflow-x: hidden;
}

.Polaris-Modal__Body {
  width: 100%;
}

.Polaris-Modal__IFrame {
  width: 38.75rem;
  max-width: 100vw;
  border: none;
  display: block;
}

@media (min-width: 48.0625em) {
  .Polaris-Modal__IFrame {
    max-width: 38.75rem;
  }
}

.Polaris-Modal__Spinner {
  margin: var(--p-space-4);
  text-align: center;
}

.Polaris-Frame-ContextualSaveBar {
  height: 3.5rem;
  background: var(--p-surface);
  box-shadow: 0 .125rem .25rem var(--p-hint-from-direct-light);
  display: flex;
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__LogoContainer {
  border-right: none;
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__ContextControl {
  opacity: .3;
  pointer-events: none;
}

.Polaris-Frame-ContextualSaveBar .Polaris-Frame-ContextualSaveBar__Message {
  color: var(--p-text);
}

@media (forced-colors: active) {
  .Polaris-Frame-ContextualSaveBar {
    border: var(--p-border-width-1) solid transparent;
  }
}

.Polaris-Frame-ContextualSaveBar__LogoContainer {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame-ContextualSaveBar__LogoContainer {
    height: 100%;
    padding: 0 var(--p-space-4);
    background-color: #0000;
    flex: 0 0 15rem;
    align-items: center;
    display: flex;
  }
}

.Polaris-Frame-ContextualSaveBar__Contents {
  min-width: .0625rem;
  max-width: 62.375rem;
  height: 100%;
  padding: 0 var(--p-space-4);
  flex: auto;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 var(--p-space-5);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 var(--p-space-5);
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 var(--p-space-8);
  }
}

@media (min-width: 46.5em) {
  .Polaris-Frame-ContextualSaveBar__Contents {
    padding: 0 var(--p-space-8);
  }
}

.Polaris-Frame-ContextualSaveBar--fullWidth {
  max-width: none;
  padding: 0 var(--p-space-4);
}

.Polaris-Frame-ContextualSaveBar__Message {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--p-font-size-6);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-3);
  color: var(--p-text-subdued);
  overflow: hidden;
}

@media (min-width: 40em) {
  .Polaris-Frame-ContextualSaveBar__Message {
    font-size: var(--p-font-size-5);
  }
}

.Polaris-Frame-ContextualSaveBar__ActionContainer {
  flex-shrink: 0;
}

.Polaris-Frame-ContextualSaveBar__Action {
  margin-left: var(--p-space-2);
}

.Polaris-Frame-ContextualSaveBar__ContextControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame-ContextualSaveBar__ContextControl {
    width: 15rem;
    display: block;
  }
}

.Polaris-Frame-CSSAnimation--startFade {
  opacity: 0;
  backface-visibility: hidden;
  will-change: opacity;
  transition: opacity var(--p-duration-300) var(--p-ease-out);
  pointer-events: none;
}

.Polaris-Frame-CSSAnimation--endFade {
  opacity: 1;
  pointer-events: auto;
}

.Polaris-Frame {
  --pc-frame-button-size: var(--p-space-8);
  width: 100%;
  min-height: 100vh;
  background-color: var(--p-background);
  display: flex;
}

@media print {
  .Polaris-Frame {
    background-color: #0000;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame {
    width: calc(100% - var(--pc-frame-offset));
    margin-left: var(--pc-frame-offset);
  }
}

.Polaris-Frame__Navigation {
  z-index: var(--p-z-8);
  height: 100%;
  outline: none;
  flex: none;
  align-items: stretch;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(0%);
}

@media print {
  .Polaris-Frame__Navigation {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__Navigation {
    z-index: 1;
    left: var(--pc-frame-offset);
    display: flex;
  }

  .Polaris-Frame--hasTopBar .Polaris-Frame__Navigation {
    height: calc(100% - 3.5rem);
    top: 3.5rem;
  }
}

.Polaris-Frame__Navigation:focus {
  outline: none;
}

.Polaris-Frame__Navigation--enter, .Polaris-Frame__Navigation--enterActive, .Polaris-Frame__Navigation--exit, .Polaris-Frame__Navigation--exitActive {
  display: flex;
}

.Polaris-Frame__Navigation--enter {
  transform: translateX(-100%);
}

.Polaris-Frame__Navigation--enterActive {
  transition: transform var(--p-duration-300) var(--p-ease-out);
  transform: translateX(0%);
}

.Polaris-Frame__Navigation--exit {
  transform: translateX(0%);
}

.Polaris-Frame__Navigation--exitActive {
  transition: transform var(--p-duration-300) var(--p-ease-out);
  transform: translateX(-100%);
}

.Polaris-Frame__NavigationDismiss {
  width: var(--pc-frame-button-size);
  height: var(--pc-frame-button-size);
  margin: var(--p-space-4);
  border-radius: var(--p-border-radius-half);
  opacity: 0;
  pointer-events: none;
  backface-visibility: hidden;
  will-change: opacity;
  cursor: pointer;
  transition: opacity var(--p-duration-100) var(--p-ease);
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  left: 100%;
}

.Polaris-Frame__NavigationDismiss svg {
  fill: var(--p-surface);
}

.Polaris-Frame__NavigationDismiss:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

@media print {
  .Polaris-Frame__NavigationDismiss {
    display: none !important;
  }
}

.Polaris-Frame__Navigation--visible .Polaris-Frame__NavigationDismiss {
  pointer-events: all;
  opacity: 1;
}

.Polaris-Frame__NavigationDismiss:focus {
  border-radius: var(--p-border-radius-base);
  outline: none;
  position: absolute;
}

.Polaris-Frame__NavigationDismiss:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__NavigationDismiss {
    display: none;
  }
}

.Polaris-Frame__NavigationDismiss:hover, .Polaris-Frame__NavigationDismiss:active {
  background-color: var(--p-overlay);
}

.Polaris-Frame__TopBar {
  z-index: var(--p-z-4);
  width: 100%;
  height: 3.5rem;
  position: fixed;
  top: 0;
  left: 0;
}

@media print {
  .Polaris-Frame__TopBar {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__TopBar {
    left: var(--pc-frame-offset);
    width: calc(100% - var(--pc-frame-offset));
  }
}

.Polaris-Frame__ContextualSaveBar {
  z-index: var(--p-z-5);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__ContextualSaveBar {
    left: var(--pc-frame-offset);
    width: calc(100% - var(--pc-frame-offset));
  }
}

.Polaris-Frame__Main {
  min-width: 0;
  max-width: 100%;
  padding-right: 0;
  padding-right: calc(constant(safe-area-inset-right) );
  padding-right: calc(env(safe-area-inset-right) );
  padding-left: 0;
  padding-left: calc(constant(safe-area-inset-left) );
  padding-left: calc(env(safe-area-inset-left) );
  padding-bottom: 0;
  padding-bottom: calc(constant(safe-area-inset-bottom) );
  padding-bottom: calc(env(safe-area-inset-bottom) );
  flex: 1;
  align-items: stretch;
  display: flex;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 240px;
    padding-left: calc(15rem + constant(safe-area-inset-left) );
    padding-left: calc(15rem + env(safe-area-inset-left) );
  }
}

@media print and (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__Main {
    padding-left: 0;
  }
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 3.5rem;
}

@media print {
  .Polaris-Frame--hasTopBar .Polaris-Frame__Main {
    padding-top: 0;
  }
}

.Polaris-Frame__Content {
  padding-bottom: var(--pc-frame-global-ribbon-height, 0);
  min-width: 0;
  max-width: 100%;
  flex: 1;
  position: relative;
}

.Polaris-Frame__GlobalRibbonContainer {
  z-index: var(--p-z-3);
  width: 100%;
  position: fixed;
  bottom: 0;
}

@media (min-width: 48.0625em) {
  .Polaris-Frame__GlobalRibbonContainer {
    left: var(--pc-frame-offset);
  }

  .Polaris-Frame--hasNav .Polaris-Frame__GlobalRibbonContainer {
    left: calc(15rem + var(--pc-frame-offset));
    left: calc(240px + var(--pc-frame-offset));
    left: calc(15rem + var(--pc-frame-offset)  + constant(safe-area-inset-left) );
    left: calc(15rem + var(--pc-frame-offset)  + env(safe-area-inset-left) );
    width: calc(100% - 15rem - var(--pc-frame-offset));
  }
}

.Polaris-Frame__LoadingBar {
  z-index: var(--p-z-6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media print {
  .Polaris-Frame__LoadingBar {
    display: none !important;
  }
}

@media (min-width: 48.0625em) {
  .Polaris-Frame--hasNav .Polaris-Frame__LoadingBar {
    left: var(--pc-frame-offset);
  }

  .Polaris-Frame--hasTopBar .Polaris-Frame__LoadingBar {
    z-index: var(--p-z-6);
  }
}

.Polaris-Frame__Skip {
  --pc-frame-skip-vertical-offset: .625rem;
  z-index: var(--p-z-9);
  top: var(--pc-frame-skip-vertical-offset);
  left: calc(var(--p-space-2)  + var(--pc-frame-offset));
  opacity: 0;
  pointer-events: none;
  position: fixed;
}

.Polaris-Frame__Skip.Polaris-Frame--focused {
  pointer-events: all;
  opacity: 1;
}

.Polaris-Frame__Skip.Polaris-Frame--focused > a:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Frame__Skip > a {
  min-height: var(--p-line-height-6);
  min-width: var(--p-line-height-6);
  padding: calc((var(--p-line-height-6)  - var(--p-line-height-2)  - var(--p-space-05)) / 2) var(--p-space-4);
  background: var(--p-surface);
  box-shadow: var(--p-shadow-button);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued);
  border-top-color: var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  text-align: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  line-height: 1;
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-line-height-1);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text);
  justify-content: center;
  align-items: center;
  margin: 0;
  text-decoration: none;
  display: inline-flex;
  position: relative;
}

.Polaris-Frame__Skip > a svg {
  fill: var(--p-icon);
}

.Polaris-Frame__Skip > a:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-border-width-1) * -1 + -.0625rem);
  right: calc(var(--p-border-width-1) * -1 + -.0625rem);
  bottom: calc(var(--p-border-width-1) * -1 + -.0625rem);
  left: calc(var(--p-border-width-1) * -1 + -.0625rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-border-width-1) * -1 + -.0625rem) var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
}

.Polaris-Frame__Skip > a:hover {
  background: var(--p-action-secondary-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Frame__Skip > a:focus {
  box-shadow: var(--p-shadow-button);
  outline: 0;
}

.Polaris-Frame__Skip > a:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Frame__Skip > a:active {
  background: var(--p-action-secondary-pressed);
  box-shadow: var(--p-shadow-button);
}

.Polaris-Frame__Skip > a:active:after {
  box-shadow: none;
  border: none;
}

.Polaris-Frame__Skip > a.Polaris-Frame--pressed {
  background: var(--p-action-secondary-depressed);
  box-shadow: var(--p-shadows-inset-button-pressed);
  color: var(--p-text-on-primary);
  border-color: var(--p-border-depressed);
}

.Polaris-Frame__Skip > a.Polaris-Frame--pressed svg {
  fill: currentColor;
}

@media (-ms-high-contrast: active) {
  .Polaris-Frame__Skip > a {
    border: var(--p-border-width-1) solid windowText;
  }
}

@media (min-width: 40em) {
  .Polaris-Frame__Skip > a {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Frame__Skip > a:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-Frame__Skip > a:focus {
  border-color: none;
  box-shadow: none;
}

.Polaris-FullscreenBar {
  height: 3.5rem;
  box-shadow: var(--p-shadow-top-bar);
  background-color: var(--p-surface);
  display: flex;
  position: relative;
}

.Polaris-FullscreenBar__BackAction {
  padding-left: var(--p-space-3);
  padding-right: var(--p-space-3);
  border-width: 0;
  border-right: var(--p-border-base);
  background-color: var(--p-surface);
  font-weight: var(--p-font-weight-medium);
  cursor: pointer;
  flex: 0 auto;
  align-items: center;
  display: flex;
}

.Polaris-FullscreenBar__BackAction :first-child {
  padding-right: var(--p-space-05);
}

.Polaris-Grid-Cell {
  --pc-row-xs: initial;
  --pc-row-sm: var(--pc-row-xs);
  --pc-row-md: var(--pc-row-sm);
  --pc-row-lg: var(--pc-row-md);
  --pc-row-xl: var(--pc-row-lg);
  --pc-column-xs: initial;
  --pc-column-sm: var(--pc-column-xs);
  --pc-column-md: var(--pc-column-sm);
  --pc-column-lg: var(--pc-column-md);
  --pc-column-xl: var(--pc-column-lg);
  min-width: 0;
  grid-row: var(--pc-row-xs);
  grid-column: var(--pc-column-xs);
}

@media (min-width: 30.625em) {
  .Polaris-Grid-Cell {
    grid-row: var(--pc-row-sm);
    grid-column: var(--pc-column-sm);
  }
}

@media (min-width: 48em) {
  .Polaris-Grid-Cell {
    grid-row: var(--pc-row-md);
    grid-column: var(--pc-column-md);
  }
}

@media (min-width: 65em) {
  .Polaris-Grid-Cell {
    grid-row: var(--pc-row-lg);
    grid-column: var(--pc-column-lg);
  }
}

@media (min-width: 90em) {
  .Polaris-Grid-Cell {
    grid-row: var(--pc-row-xl);
    grid-column: var(--pc-column-xl);
  }
}

.Polaris-Grid-Cell--cell_1ColumnXs {
  grid-column-end: span 1;
}

.Polaris-Grid-Cell--cell_2ColumnXs {
  grid-column-end: span 2;
}

.Polaris-Grid-Cell--cell_3ColumnXs {
  grid-column-end: span 3;
}

.Polaris-Grid-Cell--cell_4ColumnXs {
  grid-column-end: span 4;
}

.Polaris-Grid-Cell--cell_5ColumnXs {
  grid-column-end: span 5;
}

.Polaris-Grid-Cell--cell_6ColumnXs {
  grid-column-end: span 6;
}

@media (min-width: 30.625em) {
  .Polaris-Grid-Cell--cell_1ColumnSm {
    grid-column-end: span 1;
  }

  .Polaris-Grid-Cell--cell_2ColumnSm {
    grid-column-end: span 2;
  }

  .Polaris-Grid-Cell--cell_3ColumnSm {
    grid-column-end: span 3;
  }

  .Polaris-Grid-Cell--cell_4ColumnSm {
    grid-column-end: span 4;
  }

  .Polaris-Grid-Cell--cell_5ColumnSm {
    grid-column-end: span 5;
  }

  .Polaris-Grid-Cell--cell_6ColumnSm {
    grid-column-end: span 6;
  }
}

@media (min-width: 48em) {
  .Polaris-Grid-Cell--cell_1ColumnMd {
    grid-column-end: span 1;
  }

  .Polaris-Grid-Cell--cell_2ColumnMd {
    grid-column-end: span 2;
  }

  .Polaris-Grid-Cell--cell_3ColumnMd {
    grid-column-end: span 3;
  }

  .Polaris-Grid-Cell--cell_4ColumnMd {
    grid-column-end: span 4;
  }

  .Polaris-Grid-Cell--cell_5ColumnMd {
    grid-column-end: span 5;
  }

  .Polaris-Grid-Cell--cell_6ColumnMd {
    grid-column-end: span 6;
  }
}

@media (min-width: 65em) {
  .Polaris-Grid-Cell--cell_1ColumnLg {
    grid-column-end: span 1;
  }

  .Polaris-Grid-Cell--cell_2ColumnLg {
    grid-column-end: span 2;
  }

  .Polaris-Grid-Cell--cell_3ColumnLg {
    grid-column-end: span 3;
  }

  .Polaris-Grid-Cell--cell_4ColumnLg {
    grid-column-end: span 4;
  }

  .Polaris-Grid-Cell--cell_5ColumnLg {
    grid-column-end: span 5;
  }

  .Polaris-Grid-Cell--cell_6ColumnLg {
    grid-column-end: span 6;
  }

  .Polaris-Grid-Cell--cell_7ColumnLg {
    grid-column-end: span 7;
  }

  .Polaris-Grid-Cell--cell_8ColumnLg {
    grid-column-end: span 8;
  }

  .Polaris-Grid-Cell--cell_9ColumnLg {
    grid-column-end: span 9;
  }

  .Polaris-Grid-Cell--cell_10ColumnLg {
    grid-column-end: span 10;
  }

  .Polaris-Grid-Cell--cell_11ColumnLg {
    grid-column-end: span 11;
  }

  .Polaris-Grid-Cell--cell_12ColumnLg {
    grid-column-end: span 12;
  }
}

@media (min-width: 90em) {
  .Polaris-Grid-Cell--cell_1ColumnXl {
    grid-column-end: span 1;
  }

  .Polaris-Grid-Cell--cell_2ColumnXl {
    grid-column-end: span 2;
  }

  .Polaris-Grid-Cell--cell_3ColumnXl {
    grid-column-end: span 3;
  }

  .Polaris-Grid-Cell--cell_4ColumnXl {
    grid-column-end: span 4;
  }

  .Polaris-Grid-Cell--cell_5ColumnXl {
    grid-column-end: span 5;
  }

  .Polaris-Grid-Cell--cell_6ColumnXl {
    grid-column-end: span 6;
  }

  .Polaris-Grid-Cell--cell_7ColumnXl {
    grid-column-end: span 7;
  }

  .Polaris-Grid-Cell--cell_8ColumnXl {
    grid-column-end: span 8;
  }

  .Polaris-Grid-Cell--cell_9ColumnXl {
    grid-column-end: span 9;
  }

  .Polaris-Grid-Cell--cell_10ColumnXl {
    grid-column-end: span 10;
  }

  .Polaris-Grid-Cell--cell_11ColumnXl {
    grid-column-end: span 11;
  }

  .Polaris-Grid-Cell--cell_12ColumnXl {
    grid-column-end: span 12;
  }
}

.Polaris-Grid {
  --pc-grid-areas-xs: initial;
  --pc-grid-areas-sm: var(--pc-grid-areas-xs);
  --pc-grid-areas-md: var(--pc-grid-areas-sm);
  --pc-grid-areas-lg: var(--pc-grid-areas-md);
  --pc-grid-areas-xl: var(--pc-grid-areas-lg);
  --pc-grid-columns-xs: 6;
  --pc-grid-columns-sm: var(--pc-grid-columns-xs);
  --pc-grid-columns-md: var(--pc-grid-columns-sm);
  --pc-grid-columns-lg: 12;
  --pc-grid-columns-xl: var(--pc-grid-columns-lg);
  gap: var(--pc-grid-gap-xs, var(--p-space-4));
  grid-template-areas: var(--pc-grid-areas-xs);
  grid-template-columns: repeat(var(--pc-grid-columns-xs), minmax(0, 1fr) );
  display: grid;
}

@media (min-width: 30.625em) {
  .Polaris-Grid {
    gap: var(--pc-grid-gap-sm, var(--p-space-4));
    grid-template-areas: var(--pc-grid-areas-sm);
    grid-template-columns: repeat(var(--pc-grid-columns-sm), minmax(0, 1fr) );
  }
}

@media (min-width: 48em) {
  .Polaris-Grid {
    gap: var(--pc-grid-gap-md, var(--p-space-4));
    grid-template-areas: var(--pc-grid-areas-md);
    grid-template-columns: repeat(var(--pc-grid-columns-md), minmax(0, 1fr) );
  }
}

@media (min-width: 65em) {
  .Polaris-Grid {
    gap: var(--pc-grid-gap-lg, var(--p-space-4));
    grid-template-areas: var(--pc-grid-areas-lg);
    grid-template-columns: repeat(var(--pc-grid-columns-lg), minmax(0, 1fr) );
  }
}

@media (min-width: 90em) {
  .Polaris-Grid {
    gap: var(--pc-grid-gap-xl, var(--p-space-4));
    grid-template-areas: var(--pc-grid-areas-xl);
    grid-template-columns: repeat(var(--pc-grid-columns-xl), minmax(0, 1fr) );
  }
}

.Polaris-IndexTable {
  --pc-index-table-translate-offset: 2.1875rem;
  --pc-index-table-table-header-offset: 3.25rem;
  --pc-index-table-cell: 1;
  --pc-index-table-sticky-cell: 31;
  --pc-index-table-scroll-bar: 35;
  --pc-index-table-bulk-actions: 36;
  --pc-index-table-loading-panel: 37;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}

.Polaris-IndexTable__LoadingContainer--enter {
  opacity: 0;
  transform: translateY(-100%);
}

.Polaris-IndexTable--loadingContainerEnterActive {
  opacity: 1;
  transition: opacity var(--p-duration-100) var(--p-ease-out), transform var(--p-duration-100) var(--p-ease-out);
  transform: translateY(0);
}

.Polaris-IndexTable__LoadingContainer--exit {
  opacity: 1;
  transform: translateY(0);
}

.Polaris-IndexTable--loadingContainerExitActive {
  opacity: 0;
  transition: opacity var(--p-duration-100) var(--p-ease-in), transform var(--p-duration-100) var(--p-ease-in);
  transform: translateY(-100%);
}

.Polaris-IndexTable__LoadingPanel {
  z-index: var(--pc-index-table-loading-panel);
  width: 100%;
  background: var(--p-surface);
  padding: var(--p-space-2) var(--p-space-4);
  box-shadow: var(--p-shadow-base);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-IndexTable__LoadingPanel .Polaris-IndexTable__LoadingPanelRow {
  width: 100%;
  background: var(--p-surface-highlight-subdued);
  padding: var(--p-space-2);
  padding-bottom: var(--p-space-1);
  border-radius: var(--p-border-radius-1);
  flex-wrap: nowrap;
  display: flex;
}

.Polaris-IndexTable__LoadingPanelText {
  margin-left: var(--p-space-3);
  color: var(--p-text);
}

.Polaris-IndexTable__Table {
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
}

.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--first, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--second {
  visibility: visible;
  background-color: var(--p-surface);
}

.Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--first {
  filter: drop-shadow(.0625rem 0 0 var(--p-divider));
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell, .Polaris-IndexTable__Table--scrolling .Polaris-IndexTable__TableHeading--second {
    filter: drop-shadow(.0625rem 0 0 var(--p-divider));
  }
}

.Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableHeading--second, .Polaris-IndexTable__Table--scrolling.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableCell:first-child {
  visibility: visible;
  filter: drop-shadow(.0625rem 0 0 var(--p-divider));
}

.Polaris-IndexTable__TableRow {
  background-color: var(--p-surface);
  cursor: pointer;
  filter: drop-shadow(0 -.0625rem 0 var(--p-divider));
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--unclickable {
  cursor: auto;
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess, .Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued, .Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableHeading--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableHeading--second, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-selected);
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first, .Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered.Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  background-color: var(--p-surface-selected-hovered);
}

.Polaris-IndexTable__TableRow--subdued {
  color: var(--p-text-subdued);
}

.Polaris-IndexTable__TableHeading {
  padding: var(--p-space-4);
  text-align: left;
  font-weight: var(--p-font-weight-medium);
  white-space: nowrap;
  border: 0;
}

.Polaris-IndexTable__TableHeading--flush {
  padding: 0;
}

.Polaris-IndexTable__TableHeading--first {
  --pc-index-table-checkbox-offset-left: 1rem;
  --pc-index-table-checkbox-offset-right: 1.125rem;
  padding-left: var(--pc-index-table-checkbox-offset-left);
  padding-right: var(--pc-index-table-checkbox-offset-right);
  width: var(--p-space-5);
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
  display: flex;
}

.Polaris-IndexTable__FirstStickyHeaderElement {
  margin-left: calc(var(--p-space-05) * -1);
  padding-right: var(--p-space-05);
}

.Polaris-IndexTable__TableHeading--second:not(.Polaris-IndexTable__TableHeading--unselectable) {
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__TableHeading--second:not(.Polaris-IndexTable__TableHeading--unselectable) {
    z-index: auto;
    position: sticky;
    left: 0;
  }
}

.Polaris-IndexTable__TableHeading--second.Polaris-IndexTable__TableHeading--unselectable {
  z-index: auto;
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__TableCell {
  z-index: var(--pc-index-table-cell);
  text-align: left;
  padding: var(--p-space-2) var(--p-space-4);
  white-space: nowrap;
}

.Polaris-IndexTable__TableCell--flush {
  padding: 0;
}

.Polaris-IndexTable__TableCell--first {
  z-index: var(--pc-index-table-sticky-cell);
  padding: var(--p-space-2) 0;
  vertical-align: middle;
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
  left: var(--pc-checkbox-offset);
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__TableCell--first + .Polaris-IndexTable__TableCell {
    z-index: var(--pc-index-table-sticky-cell);
    position: sticky;
  }
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface);
  z-index: var(--pc-index-table-sticky-cell);
  position: sticky;
  left: 0;
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable__Table--unselectable .Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell:first-child {
  background-color: var(--p-surface-hovered);
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyScrolling .Polaris-IndexTable__TableCell:last-child, .Polaris-IndexTable--tableStickyScrolling .Polaris-IndexTable__TableHeading--last {
    filter: drop-shadow(-.0625rem 0 0 var(--p-border-divider));
  }
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableCell:last-child {
    background-color: var(--p-surface);
    z-index: var(--pc-index-table-sticky-cell);
    position: sticky;
    right: 0;
  }
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableHeading--last {
    background-color: var(--p-surface);
    z-index: auto;
    position: sticky;
    right: 0;
  }
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable--statusSuccess .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-primary-selected);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable--statusSubdued .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-subdued);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableRow--hovered .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-hovered);
}

.Polaris-IndexTable--tableStickyLast .Polaris-IndexTable__TableRow--selected .Polaris-IndexTable__TableCell:last-child {
  background-color: var(--p-surface-selected);
}

.Polaris-IndexTable__StickyTable {
  visibility: hidden;
  z-index: var(--pc-index-table-loading-panel);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.Polaris-IndexTable__StickyTableHeader {
  width: 100%;
  display: flex;
  position: absolute;
}

.Polaris-IndexTable__StickyTableHeader:not(.Polaris-IndexTable__StickyTableHeader--isSticky) {
  top: -62.5rem;
  left: -62.5rem;
}

.Polaris-IndexTable__StickyTableColumnHeader {
  flex: none;
}

.Polaris-IndexTable__StickyTableColumnHeader--isScrolling {
  box-shadow: .0625rem .0625rem 0 0 var(--p-border-divider), .0625rem 0 .0625rem .0625rem #3f3f440d, .0625rem 0 .1875rem 0 #3f3f4426;
}

.Polaris-IndexTable__StickyTableHeadings {
  flex: auto;
  display: flex;
  overflow: hidden;
}

.Polaris-IndexTable__StickyTableHeading--second {
  padding-left: 0;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable__StickyTableHeading--second {
    display: none;
  }
}

.Polaris-IndexTable__StickyTableHeading--second.Polaris-IndexTable--unselectable {
  display: none;
}

.Polaris-IndexTable--stickyTableHeadingSecondScrolling {
  padding: 0 var(--p-space-025) 0 var(--p-space-4);
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-IndexTable--stickyTableHeadingSecondScrolling {
    display: block;
  }
}

.Polaris-IndexTable__StickyTableHeader--isSticky {
  visibility: visible;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-base);
}

.Polaris-IndexTable:hover .Polaris-IndexTable__ScrollLeft, .Polaris-IndexTable:hover .Polaris-IndexTable__ScrollRight, .Polaris-IndexTable .Polaris-IndexTable__ScrollRight--onboarding {
  display: block;
}

.Polaris-IndexTable__BulkActionsWrapper {
  visibility: visible;
  z-index: var(--pc-index-table-bulk-actions);
  padding: var(--p-space-2);
  background: var(--p-surface);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.Polaris-IndexTable__ScrollBarContainer {
  z-index: var(--pc-index-table-scroll-bar);
  padding: var(--p-space-05);
  background-color: var(--p-surface);
  border-bottom-right-radius: var(--p-border-radius-2);
  border-bottom-left-radius: var(--p-border-radius-2);
  position: sticky;
  bottom: 0;
}

.Polaris-IndexTable--scrollBarContainerCondensed {
  visibility: hidden;
  pointer-events: none;
}

.Polaris-IndexTable--scrollBarContainerHidden {
  display: none;
}

.Polaris-IndexTable__ScrollBar {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: scroll;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar-track {
  border-radius: var(--p-border-radius-1);
  background-color: #0000;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar {
  appearance: none;
  height: var(--p-space-2);
  width: var(--p-space-2);
  background-color: #0000;
}

.Polaris-IndexTable__ScrollBar::-webkit-scrollbar-thumb {
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-border-neutral-subdued);
  -webkit-transition: background-color var(--p-duration-100) var(--p-ease-out);
  transition: background-color var(--p-duration-100) var(--p-ease-out);
}

.Polaris-IndexTable__ScrollBar:hover::-webkit-scrollbar-thumb {
  background-color: var(--p-border-depressed);
}

.Polaris-IndexTable--disableTextSelection {
  user-select: none;
}

.Polaris-IndexTable--selectMode {
  transform: translateY(calc(var(--pc-index-table-table-header-offset) * -1) );
  margin-bottom: calc(var(--pc-index-table-table-header-offset) * -1);
}

.Polaris-IndexTable__EmptySearchResultWrapper {
  padding: var(--p-space-4);
}

.Polaris-IndexTable--condensedRow {
  width: calc(100% + var(--pc-index-table-translate-offset));
  transform: translateX(calc(var(--pc-index-table-translate-offset) * -1) );
  transition: transform var(--p-ease) var(--p-duration-200);
  border-top: var(--p-border-divider);
  filter: none;
  align-items: center;
  display: flex;
}

[data-selectmode="true"] .Polaris-IndexTable--condensedRow {
  transform: none;
}

.Polaris-IndexTable__CondensedList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
}

.Polaris-IndexTable__HeaderWrapper {
  width: 100%;
  min-height: 3.5rem;
  padding: var(--p-space-2) var(--p-space-4);
  background-color: var(--p-surface);
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-IndexTable__HeaderWrapper.Polaris-IndexTable--unselectable {
  min-height: auto;
  padding: 0;
}

.Polaris-IndexTable__StickyTable--condensed {
  visibility: visible;
}

.Polaris-IndexTable__StickyTableHeader--condensed {
  padding: var(--p-space-4) var(--p-space-4) var(--p-space-2);
}

.Polaris-IndexTable__ScrollBarContent {
  height: .0625rem;
  width: var(--pc-index-table-scroll-bar-content-width);
}

.Polaris-IndexTable-Checkbox__TableCellContentContainer {
  align-items: center;
  display: flex;
}

.Polaris-IndexTable-Checkbox__Wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Polaris-IndexTable-Checkbox--expanded {
  width: 2.25rem;
  height: 1.25rem;
  padding-left: var(--p-space-4);
}

.Polaris-IndexTable-Checkbox--condensed {
  min-width: 3.125rem;
  min-height: 2.75rem;
  margin-right: calc(var(--p-space-4) * -1);
}

.Polaris-IndexTable-ScrollContainer {
  overscroll-behavior-x: contain;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: auto;
}

.Polaris-IndexTable-ScrollContainer::-webkit-scrollbar {
  display: none;
}

.Polaris-KeyboardKey {
  height: 1.5rem;
  margin: 0 var(--p-space-05) var(--p-space-05);
  padding: 0 var(--p-space-1);
  background-color: var(--p-surface);
  box-shadow: 0 0 0 .0625rem var(--p-border-subdued), 0 var(--p-space-05) 0 0 var(--p-surface), 0 var(--p-space-05) 0 .0625rem var(--p-border-subdued);
  border-radius: var(--p-border-radius-1);
  font-family: var(--p-font-family-sans);
  font-size: var(--p-font-size-1);
  font-weight: var(--p-font-weight-medium);
  color: var(--p-text-subdued);
  text-align: center;
  min-width: 1.5rem;
  user-select: none;
  line-height: 1.5rem;
  display: inline-block;
}

.Polaris-Layout {
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-5) * -1);
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

@media print {
  body .Polaris-Layout {
    font-size: var(--p-font-size-1);
    line-height: var(--p-line-height-1);
  }

  .Polaris-Layout a, .Polaris-Layout button {
    color: var(--p-text);
  }
}

.Polaris-Layout__Section {
  min-width: 51%;
  flex: 2 2 30rem;
}

@media print {
  .Polaris-Layout__Section {
    flex: 2 2 22.5rem;
  }
}

.Polaris-Layout__Section--secondary {
  min-width: 0;
  flex: 15rem;
}

.Polaris-Layout__Section--fullWidth {
  flex: 100%;
}

.Polaris-Layout__Section--oneHalf {
  min-width: 0;
  flex: 28.125rem;
}

.Polaris-Layout__Section--oneThird {
  min-width: 0;
  flex: 15rem;
}

.Polaris-Layout__AnnotatedSection {
  min-width: 0;
  flex: 100%;
}

.Polaris-Layout__Section, .Polaris-Layout__AnnotatedSection {
  max-width: calc(100% - var(--p-space-5));
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-5);
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection, [data-has-navigation] .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
    padding-top: var(--p-space-4);
    border-top: var(--p-border-divider);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Layout__Section + .Polaris-Layout__AnnotatedSection, .Polaris-Layout__AnnotatedSection + .Polaris-Layout__AnnotatedSection {
    padding-top: var(--p-space-4);
    border-top: var(--p-border-divider);
  }
}

.Polaris-Layout__AnnotationWrapper {
  margin-top: calc(var(--p-space-4) * -1);
  margin-left: calc(var(--p-space-5) * -1);
  flex-wrap: wrap;
  display: flex;
}

.Polaris-Layout__AnnotationContent {
  flex: 2 2 30rem;
}

.Polaris-Layout__Annotation {
  padding: var(--p-space-4) var(--p-space-5) 0;
  flex: 15rem;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Layout__Annotation {
    padding: var(--p-space-4) 0 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Layout__Annotation {
    padding: var(--p-space-4) 0 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em) {
  [data-has-navigation] .Polaris-Layout__Annotation {
    padding: var(--p-space-5) var(--p-space-5) var(--p-space-5) 0;
  }
}

@media (min-width: 50em) {
  .Polaris-Layout__Annotation {
    padding: var(--p-space-5) var(--p-space-5) var(--p-space-5) 0;
  }
}

.Polaris-Layout__Annotation, .Polaris-Layout__AnnotationContent {
  min-width: 0;
  max-width: calc(100% - var(--p-space-5));
  margin-top: var(--p-space-4);
  margin-left: var(--p-space-5);
}

.Polaris-Layout__AnnotationDescription {
  color: var(--p-text-subdued);
}

.Polaris-Link {
  appearance: none;
  text-align: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: var(--p-interactive);
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  text-decoration: underline;
  display: inline;
}

.Polaris-Link:hover {
  color: var(--p-interactive-hovered);
  text-decoration: none;
}

.Polaris-Link:focus:not(:active) {
  outline: var(--p-focused) auto var(--p-border-width-1);
}

.Polaris-Link:active {
  color: var(--p-interactive-pressed);
  position: relative;
}

.Polaris-Link:active:before {
  content: "";
  z-index: -1;
  border-radius: var(--p-border-radius-base);
  display: block;
  position: absolute;
  inset: -.125rem -.3125rem;
}

@media print {
  .Polaris-Link {
    -webkit-text-decoration-color: var(--p-border-neutral-subdued);
    text-decoration-color: var(--p-border-neutral-subdued);
  }
}

.Polaris-Link__IconLockup {
  white-space: nowrap;
  display: inline;
}

.Polaris-Link__IconLockup svg {
  fill: currentColor;
}

.Polaris-Link__IconLockup:before {
  content: "⁠";
}

.Polaris-Link__IconLayout {
  display: inline-flex;
}

.Polaris-Link__IconLayout:before {
  content: "⁠";
}

.Polaris-Link--monochrome, .Polaris-Link--monochrome:hover, .Polaris-Link--monochrome:focus, .Polaris-Link--monochrome:active {
  color: inherit;
}

.Polaris-Link--removeUnderline {
  text-decoration: none;
}

.Polaris-Link--removeUnderline:hover {
  text-decoration: underline;
}

.Polaris-List {
  padding-left: var(--p-space-5);
  margin-top: 0;
  margin-bottom: 0;
  list-style: disc;
}

.Polaris-List + .Polaris-List {
  margin-top: var(--p-space-4);
}

.Polaris-List--typeNumber {
  padding-left: var(--p-space-8);
  list-style: decimal;
}

.Polaris-List__Item {
  margin-bottom: var(--p-space-2);
}

.Polaris-List__Item:last-child {
  margin-bottom: 0;
}

.Polaris-List__Item .Polaris-List:first-child {
  margin-top: var(--p-space-2);
}

.Polaris-MediaCard {
  height: 100%;
  width: 100%;
  flex-flow: wrap;
  display: flex;
}

.Polaris-MediaCard.Polaris-MediaCard--portrait {
  flex-flow: column;
}

@media (max-width: 50.25em) {
  .Polaris-MediaCard {
    flex-flow: column;
  }
}

.Polaris-MediaCard__MediaContainer {
  overflow: hidden;
}

.Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 40%;
}

.Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait).Polaris-MediaCard--sizeSmall {
  flex-basis: 33%;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-MediaCard__MediaContainer {
    border-top-left-radius: var(--p-border-radius-2);
    border-top-right-radius: var(--p-border-radius-2);
  }
}

@media (min-width: 30.625em) {
  .Polaris-MediaCard__MediaContainer {
    border-top-left-radius: var(--p-border-radius-2);
    border-top-right-radius: var(--p-border-radius-2);
  }
}

@media (min-width: 50.25em) {
  .Polaris-MediaCard__MediaContainer:not(.Polaris-MediaCard--portrait) {
    border-top-right-radius: 0;
    border-top-left-radius: var(--p-border-radius-2);
    border-bottom-left-radius: var(--p-border-radius-2);
  }
}

.Polaris-MediaCard__InfoContainer {
  position: relative;
}

.Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait) {
  flex-basis: 60%;
}

.Polaris-MediaCard__InfoContainer:not(.Polaris-MediaCard--portrait).Polaris-MediaCard--sizeSmall {
  flex-basis: 67%;
}

.Polaris-MediaCard__Popover {
  z-index: var(--p-z-2);
  top: var(--p-space-4);
  right: var(--p-space-4);
  position: absolute;
}

.Polaris-MediaCard__Heading {
  margin-right: var(--p-space-8);
}

.Polaris-MediaCard__PrimaryAction {
  margin-right: var(--p-space-2);
}

.Polaris-MediaCard__SecondaryAction {
  margin-left: -var(--p-space-2);
}

.Polaris-MediaCard__ActionContainer {
  padding-top: var(--p-space-2);
}

.Polaris-MediaCard__ActionContainer.Polaris-MediaCard--portrait {
  padding-top: var(--p-space-8);
}

@media (max-width: 50.25em) {
  .Polaris-MediaCard__ActionContainer {
    padding-top: var(--p-space-8);
  }
}

.Polaris-Navigation {
  --pc-navigation-icon-size: 1.25rem;
  width: calc(100vw - 4rem);
  min-width: 15rem;
  max-width: 22.5rem;
  height: 100%;
  min-height: 100%;
  background-color: var(--p-background);
  -webkit-overflow-scrolling: touch;
  border-right: var(--p-border-divider);
  padding-bottom: 0;
  padding-bottom: calc(constant(safe-area-inset-bottom) );
  padding-bottom: calc(env(safe-area-inset-bottom) );
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.Polaris-Navigation:focus {
  outline: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation {
    max-width: 15rem;
    max-width: 240px;
    max-width: calc(15rem + constant(safe-area-inset-left) );
    max-width: calc(15rem + env(safe-area-inset-left) );
  }
}

.Polaris-Navigation__UserMenu {
  flex: none;
}

.Polaris-Navigation__ContextControl {
  min-height: 3.5rem;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__ContextControl {
    display: none;
  }
}

.Polaris-Navigation__PrimaryNavigation {
  max-width: 100%;
  flex-direction: column;
  flex: auto;
  align-items: stretch;
  display: flex;
  overflow: auto;
}

.Polaris-Navigation__PrimaryNavigation:focus {
  outline: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__PrimaryNavigation {
    padding-top: var(--p-space-4);
  }
}

.Polaris-Navigation__LogoContainer {
  display: none;
}

@media (max-width: 48.0525em) {
  .Polaris-Navigation__LogoContainer {
    height: 3.5rem;
    padding: 0 var(--p-space-2) 0 var(--p-space-4);
    background-color: var(--p-surface);
    box-shadow: var(--p-shadow-top-bar);
    margin-bottom: var(--p-space-4);
    flex: 0 0 3.5rem;
    flex-basis: calc(3.5rem + constant(safe-area-inset-left) );
    flex-basis: calc(3.5rem + env(safe-area-inset-left) );
    padding-left: var(--p-space-4);
    padding-left: calc(var(--p-space-4)  + constant(safe-area-inset-left) );
    padding-left: calc(var(--p-space-4)  + env(safe-area-inset-left) );
    align-items: center;
    display: flex;
  }
}

.Polaris-Navigation__LogoContainer.Polaris-Navigation--hasLogoSuffix {
  gap: var(--p-space-2);
}

.Polaris-Navigation__Logo, .Polaris-Navigation__LogoLink {
  display: block;
}

.Polaris-Navigation__Item {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-6);
  max-width: 100%;
  padding: 0;
  padding: 0 var(--p-space-1) 0 var(--p-space-3);
  margin: 0;
  margin: 0 var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  text-align: left;
  background: none;
  border: none;
  flex-grow: 1;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: relative;
}

.Polaris-Navigation__Item:focus {
  outline: none;
}

.Polaris-Navigation__Item:hover {
  background: var(--p-background-hovered);
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Navigation__Item:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Navigation__Item.Polaris-Navigation--keyFocused {
  background: var(--p-background-hovered);
  color: var(--p-text);
  text-decoration: none;
}

.Polaris-Navigation__Item.Polaris-Navigation--keyFocused:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__Item:active, .Polaris-Navigation__Item:active:hover {
  color: var(--p-text);
  background-color: var(--p-background-pressed);
}

.Polaris-Navigation__Item:active:after, .Polaris-Navigation__Item:active:hover:after {
  content: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Item {
    font-size: var(--p-font-size-5);
    font-weight: var(--p-font-weight-medium);
    line-height: var(--p-line-height-5);
    padding-left: var(--p-space-3);
  }
}

.Polaris-Navigation__Item::-moz-focus-inner {
  border: 0;
}

.Polaris-Navigation__Item svg, .Polaris-Navigation__Item img {
  height: var(--p-space-5);
  width: var(--p-space-5);
  display: block;
}

.Polaris-Navigation__Item .Polaris-Navigation__Icon--resized svg, .Polaris-Navigation__Item .Polaris-Navigation__Icon--resized img {
  margin: var(--p-space-05);
  height: var(--p-space-4);
  width: var(--p-space-4);
}

.Polaris-Navigation__Item--selected {
  font-weight: var(--p-font-weight-semibold);
  color: var(--p-text-primary);
  background-color: var(--p-background-selected);
  outline: var(--p-border-width-1) solid transparent;
  position: relative;
}

.Polaris-Navigation__Item--selected:before {
  content: "";
  top: .0625rem;
  bottom: .0625rem;
  left: calc(var(--p-space-2) * -1);
  width: .1875rem;
  background-color: var(--p-action-primary);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
  position: absolute;
}

.Polaris-Navigation__Item--selected:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Navigation__Item--selected:hover, .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused {
  background-color: var(--p-background-hovered);
  color: var(--p-text-primary-hovered);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation--keyFocused:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__Item--selected:active, .Polaris-Navigation__Item--selected:active:hover {
  color: var(--p-text-primary);
  background-color: var(--p-background-pressed);
}

.Polaris-Navigation__Item--selected:active:after, .Polaris-Navigation__Item--selected:active:hover:after {
  content: none;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Item--selected {
    font-weight: var(--p-font-weight-medium);
  }
}

.Polaris-Navigation__Item--disabled {
  color: var(--p-text-disabled);
  pointer-events: none;
  opacity: .6;
}

.Polaris-Navigation__Item--disabled .Polaris-Navigation__Icon {
  opacity: .6;
}

.Polaris-Navigation__Badge {
  margin-left: var(--p-space-2);
  margin-top: var(--p-space-2);
  display: inline-flex;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Badge {
    margin-top: var(--p-space-1);
  }
}

.Polaris-Navigation__Icon {
  --pc-navigation-filter-icon: brightness(0) saturate(100%) invert(36%) sepia(13%) saturate(137%) hue-rotate(169deg) brightness(95%) contrast(87%);
  --pc-navigation-filter-icon-action-primary: brightness(0) saturate(100%) invert(20%) sepia(59%) saturate(5557%) hue-rotate(162deg) brightness(95%) contrast(101%);
  --pc-navigation-filter-icon-on-interactive: brightness(0) saturate(100%) invert(100%);
  width: 1.25rem;
  height: 1.25rem;
  margin-top: calc(var(--p-space-2)  + var(--p-space-05));
  margin-right: var(--p-space-3);
  margin-bottom: calc(var(--p-space-2)  + var(--p-space-05));
  flex-shrink: 0;
  align-self: flex-start;
}

.Polaris-Navigation__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__Icon img {
  filter: var(--pc-navigation-filter-icon);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Icon {
    margin-top: var(--p-space-1);
    margin-right: var(--p-space-2);
    margin-bottom: var(--p-space-1);
  }
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Navigation__Item:hover .Polaris-Navigation__Icon img, .Polaris-Navigation__Item.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon img {
  filter: var(--pc-navigation-filter-icon);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon svg, .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation--itemChildActive .Polaris-Navigation__Icon svg, .Polaris-Navigation--itemChildActive:hover .Polaris-Navigation__Icon svg, .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon svg {
  fill: var(--p-action-primary);
}

.Polaris-Navigation__Item--selected .Polaris-Navigation__Icon img, .Polaris-Navigation__Item--selected:hover .Polaris-Navigation__Icon img, .Polaris-Navigation--subNavigationActive .Polaris-Navigation__Icon img, .Polaris-Navigation--subNavigationActive:hover .Polaris-Navigation__Icon img, .Polaris-Navigation--itemChildActive .Polaris-Navigation__Icon img, .Polaris-Navigation--itemChildActive:hover .Polaris-Navigation__Icon img, .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused .Polaris-Navigation__Icon img {
  filter: var(--pc-navigation-filter-icon-action-primary);
}

.Polaris-Navigation__Icon svg {
  display: block;
}

.Polaris-Navigation__ExternalIcon {
  height: 2.5rem;
  padding: var(--p-space-1) var(--p-space-2) var(--p-space-1) var(--p-space-4);
  align-items: center;
  display: flex;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__ExternalIcon {
    height: 2rem;
  }
}

.Polaris-Navigation__ListItem {
  flex-wrap: wrap;
  display: flex;
  position: relative;
}

.Polaris-Navigation__RollupSection .Polaris-Navigation__ListItem, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__ListItem {
  opacity: 1;
}

.Polaris-Navigation__ListItem:nth-child(1) {
  animation-delay: 0s;
}

.Polaris-Navigation__ListItem:nth-child(2) {
  animation-delay: 50ms;
}

.Polaris-Navigation__ListItem:nth-child(3) {
  animation-delay: .1s;
}

.Polaris-Navigation__ListItem:nth-child(4) {
  animation-delay: .15s;
}

.Polaris-Navigation__ListItem:nth-child(5) {
  animation-delay: .2s;
}

.Polaris-Navigation__ListItem:nth-child(6) {
  animation-delay: .25s;
}

.Polaris-Navigation__ListItem:nth-child(7) {
  animation-delay: .3s;
}

.Polaris-Navigation__ListItem:nth-child(8) {
  animation-delay: .35s;
}

.Polaris-Navigation__ListItem:nth-child(9) {
  animation-delay: .4s;
}

.Polaris-Navigation__ListItem:nth-child(10) {
  animation-delay: .45s;
}

.Polaris-Navigation__ListItem:nth-child(11) {
  animation-delay: .5s;
}

.Polaris-Navigation__ListItem:nth-child(12) {
  animation-delay: .55s;
}

.Polaris-Navigation__ListItem:not(:first-child) .Polaris-Navigation__Item {
  border-top: var(--p-border-width-1) solid var(--p-background);
}

.Polaris-Navigation__ListItem--hasAction .Polaris-Navigation__Item {
  max-width: calc(100% - var(--pc-navigation-icon-size)  + var(--p-space-4) * 2 + var(--p-space-1));
}

.Polaris-Navigation__ItemWrapper {
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
}

.Polaris-Navigation__Text {
  margin-top: calc(var(--p-space-2)  + var(--p-space-05));
  margin-bottom: calc(var(--p-space-2)  + var(--p-space-05));
  line-height: var(--p-line-height-2);
  font-size: var(--p-font-size-3);
  flex: auto;
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__Text {
    margin-top: var(--p-space-1);
    margin-bottom: var(--p-space-1);
  }
}

@media (max-width: 48.0625em) {
  .Polaris-Navigation__Text {
    font-size: var(--p-font-size-4);
    line-height: var(--p-line-height-2);
  }
}

.Polaris-Navigation__SecondaryAction {
  height: 2.5rem;
  margin-right: var(--p-space-1);
  padding: var(--p-space-1) var(--p-space-4);
  border-radius: var(--p-border-radius-1);
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-Navigation__SecondaryAction svg {
  fill: var(--p-icon);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__SecondaryAction {
    height: 2rem;
  }
}

.Polaris-Navigation__SecondaryAction:hover, .Polaris-Navigation__SecondaryAction:focus, .Polaris-Navigation__SecondaryAction:active {
  background: var(--p-background-hovered);
}

.Polaris-Navigation__SecondaryAction:hover svg, .Polaris-Navigation__SecondaryAction:focus svg, .Polaris-Navigation__SecondaryAction:active svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Navigation__SecondaryAction:hover img, .Polaris-Navigation__SecondaryAction:focus img, .Polaris-Navigation__SecondaryAction:active img {
  filter: var(--p-filter-icon-action-primary);
}

@media (-ms-high-contrast: active) {
  .Polaris-Navigation__SecondaryAction:hover svg, .Polaris-Navigation__SecondaryAction:focus svg, .Polaris-Navigation__SecondaryAction:active svg {
    fill: var(--p-icon-on-interactive);
  }

  .Polaris-Navigation__SecondaryAction:hover img, .Polaris-Navigation__SecondaryAction:focus img, .Polaris-Navigation__SecondaryAction:active img {
    filter: var(--p-filter-icon-on-interactive);
  }
}

.Polaris-Navigation__SecondaryAction:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Navigation__SecondaryAction:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__SecondaryAction:active {
  background: var(--p-background-pressed);
}

.Polaris-Navigation__SecondaryAction:active:after {
  content: none;
}

.Polaris-Navigation__SecondaryAction:active svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Navigation__SecondaryAction:focus, .Polaris-Navigation__SecondaryAction:active {
  outline: none;
}

.Polaris-Navigation__SecondaryNavigation {
  flex-basis: 100%;
  margin-left: 0;
  overflow-x: visible;
}

.Polaris-Navigation__SecondaryNavigation.Polaris-Navigation--isExpanded {
  margin-bottom: var(--p-space-2);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__List {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-medium);
  color: var(--p-text-subdued);
  padding-left: calc(var(--p-space-8)  + var(--p-space-3));
  line-height: 2.5rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:hover {
  color: var(--p-text-subdued);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item.Polaris-Navigation--keyFocused {
  color: var(--p-text);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item.Polaris-Navigation--keyFocused:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:after, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active:hover:after {
  content: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item:active {
  color: var(--p-text-primary);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item {
    font-size: var(--p-font-size-3);
    padding-left: var(--p-space-10);
    line-height: 1;
  }
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Text {
  margin-top: var(--p-space-1);
  margin-bottom: var(--p-space-1);
  line-height: var(--p-space-5);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected {
  color: var(--p-text-primary);
  position: relative;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:hover {
  color: var(--p-text-primary-hovered);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected.Polaris-Navigation--keyFocused, .Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active {
  color: var(--p-text-primary);
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--selected:active:after {
  content: none;
}

.Polaris-Navigation__SecondaryNavigation .Polaris-Navigation__Item--disabled {
  font-weight: var(--p-font-weight-medium);
  color: var(--p-text-disabled);
}

.Polaris-Navigation__SecondaryNavigation--noIcon {
  margin-left: var(--p-space-4);
}

.Polaris-Navigation__Section {
  padding: var(--p-space-4) 0;
  padding-top: 0;
  padding-left: 0;
  padding-left: calc(constant(safe-area-inset-left) );
  padding-left: calc(env(safe-area-inset-left) );
  flex: none;
  margin: 0;
  list-style: none;
}

.Polaris-Navigation__Section + .Polaris-Navigation__Section {
  padding-top: var(--p-space-2);
  padding-bottom: var(--p-space-4);
}

.Polaris-Navigation__Section--fill {
  flex: 1 0 auto;
}

.Polaris-Navigation__Section--withSeparator {
  border-top: var(--p-border-divider);
}

.Polaris-Navigation__SectionHeading {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-1);
  text-transform: uppercase;
  text-transform: none;
  padding-left: calc(var(--p-space-5)  + var(--p-space-05));
  align-items: center;
  display: flex;
}

@media (min-width: 40em) {
  .Polaris-Navigation__SectionHeading {
    font-size: var(--p-font-size-1);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Text {
  font-size: var(--p-font-size-2);
  color: var(--p-text-subdued);
}

@media (min-width: 40em) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Text {
    font-size: var(--p-font-size-1);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  margin: 0;
  margin-right: var(--p-space-1);
  padding: 0;
  padding: var(--p-space-1) var(--p-space-4);
  border-radius: var(--p-border-radius-1);
  background: none;
  border: none;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus {
  outline: none;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg {
  fill: var(--p-icon);
}

@media (min-width: 48.0625em) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action svg, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action img {
    height: var(--p-space-4);
    width: var(--p-space-4);
    margin: var(--p-space-05);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus {
  background: var(--p-background-hovered);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg {
  fill: var(--p-icon-hovered);
}

@media (-ms-high-contrast: active) {
  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover svg, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus svg {
    fill: var(--p-icon-on-interactive);
  }

  .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus img {
    filter: var(--p-filter-icon-on-interactive);
  }
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:hover img {
  filter: var(--p-filter-icon);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover {
  background: var(--p-background-pressed);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active svg, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover svg {
  fill: var(--p-icon-pressed);
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:after, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active:hover:after {
  content: none;
}

.Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:focus, .Polaris-Navigation__SectionHeading .Polaris-Navigation__Action:active {
  outline: none;
}

.Polaris-Navigation__RollupToggle {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  color: var(--p-text-subdued);
}

.Polaris-Navigation__RollupToggle:hover {
  color: var(--p-text-primary);
}

.Polaris-Navigation__RollupToggle:hover svg {
  fill: var(--p-action-primary);
}

.Polaris-Navigation__RollupToggle:hover img {
  filter: var(--p-filter-icon-action-primary);
}

.Polaris-Navigation__RollupToggle:focus {
  outline: none;
}

.Polaris-Navigation__RollupToggle:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Navigation__List {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-Navigation__Indicator {
  height: .625rem;
  width: .625rem;
  display: inline-block;
  position: relative;
}

.Polaris-Navigation__SecondaryNavigation--noIcon .Polaris-Navigation__Item {
  padding-left: var(--p-space-3);
}

.Polaris-OptionList-Checkbox {
  width: 100%;
  margin: var(--p-choice-margin);
  position: relative;
}

.Polaris-OptionList-Checkbox.Polaris-OptionList-Checkbox--active .Polaris-OptionList-Checkbox__Backdrop:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Checkbox__Input {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox--keyFocused + .Polaris-OptionList-Checkbox__Backdrop:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop {
  border-color: var(--p-interactive);
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop:before, .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop:before, .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop:before {
  opacity: 1;
  transform: scale(1);
}

@media (-ms-high-contrast: active) {
  .Polaris-OptionList-Checkbox__Input:active:not(:disabled) + .Polaris-OptionList-Checkbox__Backdrop:before, .Polaris-OptionList-Checkbox__Input:checked + .Polaris-OptionList-Checkbox__Backdrop:before, .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate + .Polaris-OptionList-Checkbox__Backdrop:before {
    border: var(--p-border-width-2) solid windowText;
  }
}

.Polaris-OptionList-Checkbox__Input:active:not(:disabled) ~ .Polaris-OptionList-Checkbox__Icon, .Polaris-OptionList-Checkbox__Input:checked ~ .Polaris-OptionList-Checkbox__Icon, .Polaris-OptionList-Checkbox__Input.Polaris-OptionList-Checkbox__Input--indeterminate ~ .Polaris-OptionList-Checkbox__Icon {
  opacity: 1;
  transition: opacity var(--p-duration-150) var(--p-ease), transform var(--p-duration-150) var(--p-ease);
  transform: translate(-50%, -50%)scale(1);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop:before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-OptionList-Checkbox__Input:disabled + .Polaris-OptionList-Checkbox__Backdrop:hover {
  cursor: default;
}

.Polaris-OptionList-Checkbox__Input:disabled:checked + .Polaris-OptionList-Checkbox__Backdrop, .Polaris-OptionList-Checkbox__Input:disabled:checked + .Polaris-OptionList-Checkbox__Backdrop:before {
  background: var(--p-border-disabled);
}

.Polaris-OptionList-Checkbox__Backdrop {
  border: var(--p-control-border-width) solid var(--p-border);
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-1);
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.Polaris-OptionList-Checkbox__Backdrop:before {
  content: "";
  top: calc(var(--p-control-border-width) * -1);
  right: calc(var(--p-control-border-width) * -1);
  bottom: calc(var(--p-control-border-width) * -1);
  left: calc(var(--p-control-border-width) * -1);
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-interactive);
  opacity: 0;
  transition: opacity var(--p-duration-100) var(--p-ease), transform var(--p-duration-100) var(--p-ease);
  position: absolute;
  transform: scale(.25);
}

.Polaris-OptionList-Checkbox__Backdrop.Polaris-OptionList-Checkbox--hover, .Polaris-OptionList-Checkbox__Backdrop:hover {
  cursor: pointer;
  border-color: var(--p-border-hovered);
}

.Polaris-OptionList-Checkbox__Backdrop:after {
  content: "";
  z-index: 1;
  top: calc(var(--p-control-border-width) * -1 + -.0625rem);
  right: calc(var(--p-control-border-width) * -1 + -.0625rem);
  bottom: calc(var(--p-control-border-width) * -1 + -.0625rem);
  left: calc(var(--p-control-border-width) * -1 + -.0625rem);
  pointer-events: none;
  box-shadow: 0 0 0 calc(var(--p-control-border-width) * -1 + -.0625rem) var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
}

.Polaris-OptionList-Checkbox__Icon {
  transform-origin: 50%;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--p-duration-100) var(--p-ease), transform var(--p-duration-100) var(--p-ease);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(.25);
}

.Polaris-OptionList-Checkbox__Icon svg {
  fill: var(--p-icon-on-interactive);
}

@media (-ms-high-contrast: active) {
  .Polaris-OptionList-Checkbox__Icon {
    fill: windowText;
  }
}

.Polaris-OptionList-Option {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  width: 100%;
  min-height: 2.25rem;
  text-align: left;
  border-radius: var(--p-border-radius-1);
  margin: 0;
  margin-top: var(--p-space-1);
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
}

.Polaris-OptionList-Option:focus {
  outline: none;
}

.Polaris-OptionList-Option:visited {
  color: inherit;
}

.Polaris-OptionList-Option__SingleSelectOption {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.Polaris-OptionList-Option__SingleSelectOption:focus {
  outline: none;
}

.Polaris-OptionList-Option__SingleSelectOption:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--focused:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active {
  background: var(--p-surface-selected);
}

.Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--active:before, .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select:before {
  content: "";
  background-color: var(--p-interactive);
  top: 0;
  left: calc(var(--p-space-2) * -1);
  height: 100%;
  width: var(--p-border-width-3);
  border-top-right-radius: var(--p-border-radius-1);
  border-bottom-right-radius: var(--p-border-radius-1);
  display: block;
  position: absolute;
}

.Polaris-OptionList-Option__SingleSelectOption:not(.Polaris-OptionList-Option--disabled) {
  color: inherit;
}

.Polaris-OptionList-Option__SingleSelectOption .Polaris-OptionList-Option__Media {
  padding: 0 var(--p-space-2) 0 0;
}

.Polaris-OptionList-Option__Label, .Polaris-OptionList-Option__SingleSelectOption {
  width: 100%;
  cursor: pointer;
  border-radius: var(--p-border-radius-1);
  padding: var(--p-space-2);
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  align-items: flex-start;
  display: flex;
}

.Polaris-OptionList-Option__Label:hover:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option__SingleSelectOption:hover:not(.Polaris-OptionList-Option--disabled) {
  background: var(--p-surface-hovered);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select, .Polaris-OptionList-Option__Label.Polaris-OptionList-Option--select:hover:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option__Label:active:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select, .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--select:hover:not(.Polaris-OptionList-Option--disabled), .Polaris-OptionList-Option__SingleSelectOption:active:not(.Polaris-OptionList-Option--disabled) {
  background: var(--p-surface-selected);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-OptionList-Option__Label.Polaris-OptionList-Option--disabled, .Polaris-OptionList-Option__SingleSelectOption.Polaris-OptionList-Option--disabled {
  background: var(--p-surface-disabled);
  cursor: default;
  color: var(--p-text-disabled);
}

.Polaris-OptionList-Option__Checkbox {
  box-sizing: border-box;
  width: var(--p-choice-size);
  height: var(--p-choice-size);
  margin-right: var(--p-space-2);
  margin-left: calc(var(--p-choice-margin) * -1);
  flex-shrink: 0;
  display: flex;
}

.Polaris-OptionList-Option--disabled .Polaris-OptionList-Option__Media svg {
  fill: var(--p-icon-disabled);
}

.Polaris-OptionList-Option__Media {
  padding: 0 var(--p-space-2);
}

.Polaris-OptionList-Option__Media svg {
  fill: var(--p-icon);
}

.Polaris-OptionList-Option--verticalAlignTop {
  align-items: flex-start;
}

.Polaris-OptionList-Option--verticalAlignCenter {
  align-items: center;
}

.Polaris-OptionList-Option--verticalAlignBottom {
  align-items: flex-end;
}

.Polaris-OptionList {
  padding: var(--p-space-2);
  margin: 0;
  list-style: none;
}

.Polaris-OptionList__Options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.Polaris-OptionList__Title {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-1);
  text-transform: uppercase;
  padding: var(--p-space-2);
  color: var(--p-text-subdued);
}

@media (min-width: 40em) {
  .Polaris-OptionList__Title {
    font-size: var(--p-font-size-1);
  }
}

.Polaris-Header-Title {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  font-weight: var(--p-font-weight-semibold);
  font-size: var(--p-font-size-9);
  line-height: var(--p-line-height-4);
}

@media (min-width: 40em) {
  .Polaris-Header-Title {
    font-size: var(--p-font-size-7);
  }
}

.Polaris-Header-Title__TitleWithSubtitle {
  margin-top: var(--p-space-1);
}

.Polaris-Header-Title__SubTitle {
  margin-top: var(--p-space-1);
  color: var(--p-text-subdued);
}

.Polaris-Header-Title__SubTitle.Polaris-Header-Title__SubtitleCompact {
  margin-top: 0;
}

.Polaris-Header-Title__TitleWithMetadataWrapper {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title {
  margin-right: var(--p-space-2);
  display: inline;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title > * {
  display: inline;
}

.Polaris-Header-Title__TitleWithMetadataWrapper .Polaris-Header-Title__TitleMetadata {
  vertical-align: bottom;
  margin-top: 0;
}

.Polaris-Page-Header {
  padding: var(--p-space-4);
  position: relative;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Page-Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page-Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Page-Header {
    padding: var(--p-space-5) 0;
  }
}

@media (min-width: 46.5em) {
  .Polaris-Page-Header {
    padding: var(--p-space-5) 0;
  }
}

.Polaris-Page-Header--titleHidden {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-Page-Header__TitleWrapper {
  margin-top: var(--p-space-1);
  flex: auto;
  grid-area: title;
  align-self: center;
}

@media (min-width: 29.25em) {
  .Polaris-Page-Header__TitleWrapper {
    margin-top: 0;
  }
}

.Polaris-Page-Header__Navigation {
  margin-bottom: var(--p-space-1);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView .Polaris-Page-Header__Navigation {
  padding-right: 2.5rem;
}

@media print {
  .Polaris-Page-Header__Navigation {
    display: none !important;
  }
}

.Polaris-Page-Header__BreadcrumbWrapper {
  max-width: 100%;
  margin-right: var(--p-space-4);
  flex: none;
  grid-area: breadcrumbs;
}

@media print {
  .Polaris-Page-Header__BreadcrumbWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__PaginationWrapper {
  margin-left: var(--p-space-1);
  flex: none;
  justify-content: flex-end;
  line-height: 1;
  display: flex;
}

.Polaris-Page-Header__PaginationWrapper button {
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued) !important;
  box-shadow: none !important;
}

.Polaris-Page-Header__PaginationWrapper button:hover, .Polaris-Page-Header__PaginationWrapper button:active, .Polaris-Page-Header__PaginationWrapper button:focus {
  border: var(--p-border-width-1) solid var(--p-border-neutral-subdued) !important;
}

@media print {
  .Polaris-Page-Header__PaginationWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__AdditionalNavigationWrapper {
  flex: 1 0 auto;
  justify-content: flex-end;
  display: flex;
}

@media print {
  .Polaris-Page-Header__AdditionalNavigationWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__MainContent {
  align-items: center;
  display: flex;
}

.Polaris-Page-Header__TitleActionMenuWrapper {
  flex: auto;
}

.Polaris-Page-Header:not(.Polaris-Page-Header--mobileView) .Polaris-Page-Header__TitleActionMenuWrapper {
  padding-right: var(--p-space-5);
}

.Polaris-Page-Header--hasActionMenu.Polaris-Page-Header--mobileView:not(.Polaris-Page-Header--hasNavigation) .Polaris-Page-Header__TitleActionMenuWrapper {
  padding-right: 2.5rem;
}

.Polaris-Page-Header__PrimaryActionWrapper {
  margin-top: 0;
  margin-left: var(--p-space-1);
  flex: none;
}

@media (min-width: 48em) {
  .Polaris-Page-Header__PrimaryActionWrapper {
    margin-left: var(--p-space-4);
  }
}

@media print {
  .Polaris-Page-Header__PrimaryActionWrapper {
    display: none !important;
  }
}

.Polaris-Page-Header__Row {
  justify-content: space-between;
  display: flex;
}

.Polaris-Page-Header__Row:first-child {
  min-height: 2.25rem;
}

.Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
  margin-top: var(--p-space-1);
}

.Polaris-Page-Header--mobileView .Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
  margin-top: var(--p-space-2);
}

.Polaris-Page-Header__Row + .Polaris-Page-Header__Row .Polaris-Page-Header__RightAlign {
  margin-left: 0;
}

.Polaris-Page-Header__LeftAlign {
  align-content: flex-start;
  align-items: center;
  display: flex;
}

.Polaris-Page-Header__RightAlign {
  margin-left: var(--p-space-4);
  white-space: nowrap;
  flex: auto;
  grid-area: actions;
  place-content: flex-end;
  align-self: flex-start;
  align-items: center;
  display: flex;
}

@media (max-width: 29.25em) {
  .Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__RightAlign {
    margin-left: 0;
  }
}

@media (min-width: 29.25em) {
  .Polaris-Page-Header__AdditionalMetaData {
    margin-left: calc(var(--p-space-5) * 2 + var(--p-space-2)  + var(--p-space-1));
  }
}

.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__AdditionalMetaData {
  margin-left: 0;
}

@media print {
  .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0 !important;
  }
}

.Polaris-Page-Header__Actions {
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media (max-width: 67.5em) {
  .Polaris-Page-Header--longTitle .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0;
  }

  .Polaris-Page-Header--longTitle .Polaris-Page-Header__Row {
    gap: var(--p-space-2) var(--p-space-4);
    grid-template-columns: auto 1fr;
    grid-template-areas: "breadcrumbs actions"
                         "title title";
    display: grid;
  }

  .Polaris-Page-Header--longTitle .Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
    gap: 0;
  }
}

@media (max-width: 53.75em) {
  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs) .Polaris-Page-Header__AdditionalMetaData {
    margin-left: 0;
  }

  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs) .Polaris-Page-Header__Row {
    gap: var(--p-space-2) var(--p-space-4);
    grid-template-columns: auto 1fr;
    grid-template-areas: "breadcrumbs actions"
                         "title title";
    display: grid;
  }

  .Polaris-Page-Header--mediumTitle:not(.Polaris-Page-Header--noBreadcrumbs) .Polaris-Page-Header__Row + .Polaris-Page-Header__Row {
    gap: 0;
  }
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__TitleWrapper {
  margin-top: 0;
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__RightAlign {
  margin-bottom: var(--p-space-1);
}

@media (min-width: 48.0625em) {
  .Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__RightAlign {
    margin-bottom: 0;
  }
}

.Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__Row {
  flex-wrap: wrap-reverse;
}

@media (min-width: 48.0625em) {
  .Polaris-Page-Header--mediumTitle.Polaris-Page-Header--noBreadcrumbs .Polaris-Page-Header__Row {
    flex-wrap: nowrap;
  }
}

.Polaris-Page-Header--isSingleRow .Polaris-Page-Header__Row {
  gap: 0;
}

html, body {
  min-height: 100%;
  height: 100%;
}

.Polaris-Page {
  max-width: 62.375rem;
  margin: 0 auto;
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-Page {
    padding: 0 var(--p-space-6);
  }
}

@media (min-width: 30.625em) {
  .Polaris-Page {
    padding: 0 var(--p-space-6);
  }
}

.Polaris-Page:after {
  content: "";
  display: table;
}

.Polaris-Page--fullWidth {
  max-width: none;
}

.Polaris-Page--narrowWidth {
  max-width: 41.375rem;
}

.Polaris-Page__Content {
  margin: var(--p-space-2) 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-Page__Content {
    margin-top: var(--p-space-5);
  }
}

@media (min-width: 46.5em) {
  .Polaris-Page__Content {
    margin-top: var(--p-space-5);
  }
}

.Polaris-Page--divider {
  border-top: var(--p-border-divider);
  padding-top: var(--p-space-8);
}

.Polaris-PageActions {
  padding: var(--p-space-5);
  border-top: var(--p-border-divider);
  margin: 0 auto;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-PageActions {
    padding: var(--p-space-5) 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-PageActions {
    padding: var(--p-space-5) 0;
  }
}

.Polaris-ProgressBar {
  width: 100%;
  background-color: var(--pc-progress-bar-background);
  border-radius: var(--p-border-radius-1);
  overflow: hidden;
}

@media (forced-colors: active) {
  .Polaris-ProgressBar {
    border: var(--p-border-width-1) solid transparent;
  }
}

.Polaris-ProgressBar--sizeSmall {
  height: .5rem;
}

.Polaris-ProgressBar--sizeMedium {
  height: 1rem;
}

.Polaris-ProgressBar--sizeLarge {
  height: 2rem;
}

.Polaris-ProgressBar--colorHighlight {
  --pc-progress-bar-background: var(--p-surface-neutral);
  --pc-progress-bar-indicator: var(--p-border-highlight);
}

.Polaris-ProgressBar--colorPrimary {
  --pc-progress-bar-background: var(--p-surface-neutral);
  --pc-progress-bar-indicator: var(--p-action-primary);
}

.Polaris-ProgressBar--colorSuccess {
  --pc-progress-bar-background: var(--p-surface-neutral);
  --pc-progress-bar-indicator: var(--p-border-success);
}

.Polaris-ProgressBar--colorCritical {
  --pc-progress-bar-background: var(--p-surface-neutral);
  --pc-progress-bar-indicator: var(--p-interactive-critical);
}

.Polaris-ProgressBar__Indicator {
  height: inherit;
  background-color: var(--pc-progress-bar-indicator);
  transition: transform var(--pc-progress-bar-duration) var(--p-ease);
  transform-origin: 0;
  transform: scaleX(0);
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-ProgressBar__Indicator {
    border: 1rem solid highlight;
  }
}

.Polaris-ProgressBar__IndicatorAppearActive, .Polaris-ProgressBar__IndicatorAppearDone {
  transform: scaleX(var(--pc-progress-bar-percent));
}

.Polaris-ProgressBar__Progress, .Polaris-ProgressBar__Label {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.Polaris-RangeSlider, .Polaris-RangeSlider-DualThumb__RangeSlider {
  --pc-range-slider-input: 10;
  --pc-range-slider-output: 20;
  --pc-range-slider-track-height: .25rem;
  --pc-range-slider-thumb-size: var(--p-range-slider-thumb-size-base);
  --pc-track-dashed-border-radius: var(--p-border-radius-1);
}

.Polaris-RangeSlider-DualThumb {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-DualThumb__TrackWrapper {
  width: 100%;
  min-height: 1.75rem;
  cursor: pointer;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-DualThumb__TrackWrapper.Polaris-RangeSlider-DualThumb--disabled {
  opacity: .8;
  cursor: not-allowed;
}

.Polaris-RangeSlider-DualThumb__Track {
  z-index: 1;
  width: 100%;
  height: var(--pc-range-slider-track-height);
  border-radius: var(--pc-range-slider-thumb-size);
  --pc-dual-thumb-unselected-range: transparent;
  --pc-dual-thumb-selected-range: var(--p-interactive);
  --pc-dual-thumb-gradient-colors: var(--pc-dual-thumb-unselected-range) 0%, var(--pc-dual-thumb-unselected-range) var(--pc-range-slider-progress-lower), var(--pc-dual-thumb-selected-range) var(--pc-range-slider-progress-lower), var(--pc-dual-thumb-selected-range) var(--pc-range-slider-progress-upper), var(--pc-dual-thumb-unselected-range) var(--pc-range-slider-progress-upper), var(--pc-dual-thumb-unselected-range) 100%;
  background-image: linear-gradient(to right, var(--pc-dual-thumb-gradient-colors));
  position: absolute;
}

.Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Track {
  --pc-dual-thumb-selected-range: var(--p-action-critical);
  --pc-dual-thumb-gradient-colors: var(--pc-dual-thumb-unselected-range) 0%, var(--pc-dual-thumb-unselected-range) var(--pc-range-slider-progress-lower), var(--pc-dual-thumb-selected-range) var(--pc-range-slider-progress-lower), var(--pc-dual-thumb-selected-range) var(--pc-range-slider-progress-upper), var(--pc-dual-thumb-unselected-range) var(--pc-range-slider-progress-upper), var(--pc-dual-thumb-unselected-range) 100%;
  background-image: linear-gradient(to right, var(--pc-dual-thumb-gradient-colors));
}

.Polaris-RangeSlider-DualThumb--disabled .Polaris-RangeSlider-DualThumb__Track {
  background: var(--p-border-disabled) none;
}

.Polaris-RangeSlider-DualThumb--trackDashed {
  content: "";
  height: var(--pc-range-slider-track-height);
  width: 100%;
  background-image: linear-gradient(to right, var(--p-border), var(--p-border) 50%, transparent 50%, transparent 100%);
  background-size: var(--pc-range-slider-track-height) var(--pc-range-slider-track-height);
  border-radius: var(--pc-track-dashed-border-radius);
  border-right: var(--pc-track-dashed-border-radius) var(--p-border) solid;
  position: absolute;
}

.Polaris-RangeSlider-DualThumb__Thumbs {
  z-index: var(--pc-range-slider-input);
  width: var(--pc-range-slider-thumb-size);
  height: var(--pc-range-slider-thumb-size);
  border-radius: var(--p-border-radius-half);
  border: var(--p-border-width-1) solid var(--p-interactive);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  -webkit-tap-highlight-color: transparent;
  cursor: -webkit-grab;
  transition: transform var(--p-duration-150) var(--p-ease);
  padding: 0;
  position: absolute;
}

.Polaris-RangeSlider-DualThumb__Thumbs:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-2)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-RangeSlider-DualThumb__Thumbs.Polaris-RangeSlider-DualThumb--disabled {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-DualThumb__Thumbs:active {
  transform: scale(1.5);
}

.Polaris-RangeSlider-DualThumb__Thumbs:focus {
  outline: 0;
}

.Polaris-RangeSlider-DualThumb__Thumbs:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-RangeSlider-DualThumb--error .Polaris-RangeSlider-DualThumb__Thumbs {
  border-color: var(--p-action-critical);
  background: linear-gradient(var(--p-action-critical), var(--p-action-critical));
}

.Polaris-RangeSlider-DualThumb__Prefix {
  margin-right: var(--p-space-2);
  flex: none;
}

.Polaris-RangeSlider-DualThumb__Suffix {
  margin-left: var(--p-space-2);
  flex: none;
}

.Polaris-RangeSlider-DualThumb__Output {
  --pc-range-slider-output-spacing: var(--p-space-4);
  z-index: var(--pc-range-slider-output);
  bottom: var(--p-range-slider-thumb-size-active);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility, bottom;
  transition-duration: var(--p-duration-150);
  transition-timing-function: var(--p-ease);
  transform: translateX(calc(-50% + var(--pc-range-slider-thumb-size) / 2) );
  position: absolute;
}

.Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: calc(var(--p-range-slider-thumb-size-active)  + var(--p-range-slider-thumb-size-active)  - var(--p-range-slider-thumb-size-base));
}

.Polaris-RangeSlider-DualThumb__OutputBubble {
  padding: 0 var(--p-space-2);
  min-width: 2rem;
  height: 2rem;
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-popover);
  border-radius: var(--p-border-radius-base);
  transition-property: transform;
  transition-duration: var(--p-duration-150);
  transition-timing-function: var(--p-ease);
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble {
  transform: translateY(calc(var(--pc-range-slider-output-spacing) * -1) );
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, [data-has-navigation] .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, [data-has-navigation] .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble {
    transform: translateY(calc((var(--pc-range-slider-output-spacing) * .5) * -1) );
  }
}

@media (min-width: 46.5em) {
  .Polaris-RangeSlider-DualThumb__Thumbs:hover + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, .Polaris-RangeSlider-DualThumb__Thumbs:active + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble, .Polaris-RangeSlider-DualThumb__Thumbs:focus + .Polaris-RangeSlider-DualThumb__Output .Polaris-RangeSlider-DualThumb__OutputBubble {
    transform: translateY(calc((var(--pc-range-slider-output-spacing) * .5) * -1) );
  }
}

.Polaris-RangeSlider-DualThumb__OutputText {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-1);
  text-transform: uppercase;
  text-align: center;
  color: var(--p-text);
  flex: auto;
  margin: auto;
  display: block;
}

@media (min-width: 40em) {
  .Polaris-RangeSlider-DualThumb__OutputText {
    font-size: var(--p-font-size-1);
  }
}

.Polaris-RangeSlider-SingleThumb__RangeSlider {
  --pc-range-slider-input: 10;
  --pc-range-slider-output: 20;
  --pc-range-slider-track-height: .25rem;
  --pc-range-slider-thumb-size: var(--p-range-slider-thumb-size-base);
  --pc-track-dashed-border-radius: var(--p-border-radius-1);
}

.Polaris-RangeSlider-SingleThumb {
  align-items: center;
  display: flex;
}

.Polaris-RangeSlider-SingleThumb.Polaris-RangeSlider-SingleThumb--disabled {
  opacity: .8;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper {
  height: var(--pc-range-slider-thumb-size);
  flex: auto;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper input {
  padding: var(--p-space-3) 0;
  cursor: pointer;
  background-color: #0000;
}

.Polaris-RangeSlider-SingleThumb__InputWrapper:after {
  content: "";
  height: var(--pc-range-slider-track-height);
  width: 100%;
  background-image: linear-gradient(to right, var(--p-border), var(--p-border) 50%, transparent 50%, transparent 100%);
  background-size: var(--pc-range-slider-track-height) var(--pc-range-slider-track-height);
  border-radius: var(--pc-track-dashed-border-radius);
  border-right: var(--pc-track-dashed-border-radius) var(--p-border) solid;
  position: absolute;
}

.Polaris-RangeSlider-SingleThumb--disabled input {
  cursor: not-allowed;
}

.Polaris-RangeSlider-SingleThumb__Prefix {
  margin-right: var(--p-space-2);
  flex: none;
}

.Polaris-RangeSlider-SingleThumb__Suffix {
  margin-left: var(--p-space-2);
  flex: none;
}

.Polaris-RangeSlider-SingleThumb__Input {
  --pc-single-thumb-progress-lower: var(--p-interactive);
  --pc-single-thumb-progress-upper: transparent;
  --pc-single-thumb-gradient-colors: var(--pc-single-thumb-progress-lower) 0%, var(--pc-single-thumb-progress-lower) var(--pc-range-slider-progress), var(--pc-single-thumb-progress-upper) var(--pc-range-slider-progress), var(--pc-single-thumb-progress-upper) 100%;
  width: 100%;
  appearance: none;
  z-index: var(--pc-range-slider-input);
  background-color: #0000;
  flex: auto;
  margin: 0;
  padding: 0;
  position: relative;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-tooltip {
  display: none;
}

.Polaris-RangeSlider-SingleThumb__Input:focus {
  outline: 0;
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-focus-outer {
  border: 0;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-track {
  outline: var(--p-border-width-1) solid transparent;
  cursor: pointer;
  width: 100%;
  height: var(--pc-range-slider-track-height);
  background-image: linear-gradient(to right, var(--pc-single-thumb-gradient-colors));
  border-radius: var(--pc-range-slider-track-height);
  border: none;
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-track {
  cursor: pointer;
  width: 100%;
  height: var(--pc-range-slider-track-height);
  background-image: linear-gradient(to right, var(--pc-single-thumb-gradient-colors));
  border-radius: var(--pc-range-slider-track-height);
  border: none;
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track {
  cursor: pointer;
  width: 100%;
  height: var(--pc-range-slider-track-height);
  background-image: linear-gradient(to right, var(--pc-single-thumb-gradient-colors));
  border-radius: var(--pc-range-slider-track-height);
  border: none;
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  cursor: -webkit-grab;
  width: var(--pc-range-slider-thumb-size);
  height: var(--pc-range-slider-thumb-size);
  border: var(--p-border-transparent);
  border-radius: var(--p-border-radius-half);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  appearance: none;
  -ms-transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition-property: border-color, box-shadow, transform;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  margin-top: calc((var(--pc-range-slider-thumb-size)  - var(--pc-range-slider-track-height)) * -1 / 2);
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  cursor: -webkit-grab;
  width: var(--pc-range-slider-thumb-size);
  height: var(--pc-range-slider-thumb-size);
  border: var(--p-border-transparent);
  border-radius: var(--p-border-radius-half);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  appearance: none;
  -moz-transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition-property: border-color, box-shadow, transform;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  margin-top: calc((var(--pc-range-slider-thumb-size)  - var(--pc-range-slider-track-height)) * -1 / 2);
}

.Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  cursor: -webkit-grab;
  width: var(--pc-range-slider-thumb-size);
  height: var(--pc-range-slider-thumb-size);
  border: var(--p-border-transparent);
  border-radius: var(--p-border-radius-half);
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
  box-shadow: 0 0 0 0 var(--p-focused);
  appearance: none;
  -webkit-transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  transition-property: border-color, box-shadow, transform;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  margin-top: calc((var(--pc-range-slider-thumb-size)  - var(--pc-range-slider-track-height)) * -1 / 2);
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb:hover {
  background: linear-gradient(var(--p-interactive), var(--p-interactive));
}

.Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  transform: translateY(calc(var(--pc-range-slider-thumb-size) * .2) ) scale(.4);
  margin-top: 0;
}

.Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  margin-top: calc((var(--pc-range-slider-thumb-size)  - var(--pc-range-slider-track-height)) * -.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-ms-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-moz-range-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:active::-webkit-slider-thumb {
  transform: scale(1.5);
}

.Polaris-RangeSlider-SingleThumb__Input:focus {
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-ms-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 var(--p-border-width-2) var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-moz-range-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 var(--p-border-width-2) var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb__Input:focus::-webkit-slider-thumb {
  border-color: var(--p-surface);
  box-shadow: 0 0 0 var(--p-border-width-2) var(--p-focused);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input {
  --pc-single-thumb-progress-lower: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--error .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  border-color: var(--p-action-critical);
  background: var(--p-action-critical);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-track {
  outline: var(--p-border-width-1) solid transparent;
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-runnable-track {
  cursor: auto;
  background-image: none;
  background-color: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-ms-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-moz-range-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb--disabled .Polaris-RangeSlider-SingleThumb__Input::-webkit-slider-thumb {
  cursor: not-allowed;
  border-color: var(--p-border-disabled);
  background: var(--p-border-disabled);
}

.Polaris-RangeSlider-SingleThumb__Output {
  --pc-range-slider-output-spacing: var(--p-space-4);
  z-index: var(--pc-range-slider-output);
  bottom: var(--pc-range-slider-thumb-size);
  left: var(--pc-range-slider-progress);
  transform: translateX(calc(-50% + var(--pc-range-slider-output-factor) * var(--pc-range-slider-thumb-size)) );
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition-property: opacity, visibility, bottom;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  position: absolute;
}

.Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output {
  opacity: 1;
  visibility: visible;
  bottom: calc(var(--pc-range-slider-thumb-size)  + var(--p-range-slider-thumb-size-active)  - var(--p-range-slider-thumb-size-base));
}

.Polaris-RangeSlider-SingleThumb__OutputBubble {
  box-shadow: var(--p-shadow-popover);
  padding: 0 var(--p-space-2);
  min-width: 2rem;
  height: 2rem;
  background-color: var(--p-surface);
  border-radius: var(--p-border-radius-base);
  transition-property: transform;
  transition-duration: var(--p-duration-200);
  transition-timing-function: var(--p-ease);
  outline: var(--p-border-width-1) solid transparent;
  display: flex;
  position: relative;
}

.Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble {
  transform: translateY(calc(var(--pc-range-slider-output-spacing) * -1) );
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, [data-has-navigation] .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, [data-has-navigation] .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble {
    transform: translateY(calc((var(--pc-range-slider-output-spacing) * .4) * -1) );
  }
}

@media (min-width: 46.5em) {
  .Polaris-RangeSlider-SingleThumb__Input:hover + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, .Polaris-RangeSlider-SingleThumb__Input:active + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble, .Polaris-RangeSlider-SingleThumb__Input:focus + .Polaris-RangeSlider-SingleThumb__Output .Polaris-RangeSlider-SingleThumb__OutputBubble {
    transform: translateY(calc((var(--pc-range-slider-output-spacing) * .4) * -1) );
  }
}

.Polaris-RangeSlider-SingleThumb__OutputText {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-semibold);
  line-height: var(--p-line-height-1);
  text-transform: uppercase;
  text-align: center;
  color: var(--p-text);
  flex: auto;
  margin: auto;
  display: block;
}

@media (min-width: 40em) {
  .Polaris-RangeSlider-SingleThumb__OutputText {
    font-size: var(--p-font-size-1);
  }
}

.Polaris-ResourceItem__CheckboxWrapper {
  display: flex;
}

.Polaris-ResourceItem {
  --pc-resource-item-min-height: 2.75rem;
  --pc-resource-item-disclosure-width: 3rem;
  --pc-resource-item-offset: 2.5rem;
  --pc-resource-item-clickable-stacking-order: 1;
  --pc-resource-item-content-stacking-order: 2;
  cursor: pointer;
  outline: none;
  position: relative;
}

.Polaris-ResourceItem:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
  right: var(--p-space-4);
}

.Polaris-ResourceItem:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
  clip: auto;
  height: 100%;
  overflow: visible;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem:hover:not(.Polaris-ResourceItem--persistActions) .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-ResourceItem__ItemWrapper {
  max-width: 100%;
  overflow: hidden;
}

.Polaris-ResourceItem--focusedInner, .Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused, .Polaris-ResourceItem--focusedInner.Polaris-ResourceItem--focused.Polaris-ResourceItem--selected {
  box-shadow: none;
}

.Polaris-ResourceItem__Link, .Polaris-ResourceItem__Button {
  z-index: var(--pc-resource-item-clickable-stacking-order);
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-ResourceItem__Button {
  border: none;
  padding: 0;
}

.Polaris-ResourceItem__Container {
  z-index: var(--pc-resource-item-content-stacking-order);
  padding: var(--p-space-3) var(--p-space-4);
  min-height: var(--pc-resource-item-min-height);
  align-items: flex-start;
  display: flex;
  position: relative;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceItem__Container {
    padding: var(--p-space-3) var(--p-space-5);
  }
}

.Polaris-ResourceItem--alignmentLeading {
  align-items: flex-start;
}

.Polaris-ResourceItem--alignmentTrailing {
  align-items: flex-end;
}

.Polaris-ResourceItem--alignmentCenter {
  align-items: center;
}

.Polaris-ResourceItem--alignmentFill {
  align-items: stretch;
}

.Polaris-ResourceItem--alignmentBaseline {
  align-items: baseline;
}

.Polaris-ResourceItem__Owned {
  display: flex;
}

.Polaris-ResourceItem__OwnedNoMedia {
  padding-top: var(--p-space-1);
}

.Polaris-ResourceItem__Handle {
  width: 3rem;
  min-height: var(--pc-resource-item-min-height);
  margin: calc(var(--p-space-3) * -1) var(--p-space-1) calc(var(--p-space-3) * -1) calc(var(--p-space-3) * -1);
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 28.615em) {
  .Polaris-ResourceItem__Handle {
    visibility: hidden;
  }

  .Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Handle {
    visibility: visible;
  }
}

.Polaris-ResourceItem--selectable {
  width: calc(100% + var(--pc-resource-item-offset));
  transform: translateX(calc(var(--pc-resource-item-offset) * -1) );
  transition: transform var(--p-ease) var(--p-duration-200);
  margin-right: calc(var(--pc-resource-item-offset) * -1);
}

.Polaris-ResourceItem--selectable.Polaris-ResourceItem--selectMode {
  transform: translateX(0);
}

@media (min-width: 28.625em) {
  .Polaris-ResourceItem--selectable {
    width: 100%;
    margin-right: 0;
    transform: translateX(0);
  }
}

.Polaris-ResourceItem__Media {
  margin-right: var(--p-space-5);
  color: inherit;
  flex: none;
  text-decoration: none;
}

.Polaris-ResourceItem__Content {
  min-width: 0;
  max-width: 100%;
  flex: auto;
}

.Polaris-ResourceItem__Actions {
  pointer-events: initial;
  height: 100%;
  max-height: 3.5rem;
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  height: .0625rem;
  display: flex;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.Polaris-ResourceItem--focused .Polaris-ResourceItem__Actions {
  clip: auto;
  height: 100%;
  overflow: visible;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
  margin-top: 0;
  margin-left: var(--p-space-4);
  pointer-events: initial;
  height: 100%;
  flex: none;
  align-items: center;
  display: flex;
  position: relative;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem--persistActions .Polaris-ResourceItem__Actions {
    display: none;
  }
}

.Polaris-ResourceItem__Disclosure {
  top: calc(var(--p-space-3) * -1);
  right: calc(var(--p-space-4) * -1);
  width: var(--pc-resource-item-disclosure-width);
  min-height: var(--pc-resource-item-min-height);
  pointer-events: initial;
  display: none;
  position: relative;
}

.Polaris-ResourceItem--selectMode .Polaris-ResourceItem__Disclosure {
  display: none;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-ResourceItem__Disclosure {
    flex: 0 0 var(--pc-resource-item-disclosure-width);
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (max-width: 46.5em) {
  .Polaris-ResourceItem__Disclosure {
    flex: 0 0 var(--pc-resource-item-disclosure-width);
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.Polaris-ResourceItem--selected {
  background-color: var(--p-surface-selected);
}

.Polaris-ResourceItem--selected:hover {
  background-color: var(--p-surface-selected-hovered);
}

.Polaris-ResourceItem--selected:active {
  background-color: var(--p-surface-selected-pressed);
}

.Polaris-ResourceItem__ListItem {
  position: relative;
}

.Polaris-ResourceItem__ListItem:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 0rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: 0;
}

.Polaris-ResourceItem__ListItem + .Polaris-ResourceItem__ListItem {
  border-top: var(--p-border-divider);
}

.Polaris-ResourceItem__ListItem:after {
  border-radius: var(--p-border-radius-05);
}

.Polaris-ResourceItem__ListItem:last-of-type, .Polaris-ResourceItem__ListItem:last-of-type .Polaris-ResourceItem__ItemWrapper, .Polaris-ResourceItem__ListItem:last-of-type.Polaris-ResourceItem--focused:after {
  border-bottom-left-radius: var(--p-border-radius-2);
  border-bottom-right-radius: var(--p-border-radius-2);
}

.Polaris-ResourceItem__ListItem.Polaris-ResourceItem--focused {
  z-index: var(--pc-resource-item-clickable-stacking-order);
}

.Polaris-ResourceItem__ListItem.Polaris-ResourceItem--focused:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

* + ul > .Polaris-ResourceItem__ListItem:first-of-type.Polaris-ResourceItem--focused:after {
  top: .0625rem;
}

.Polaris-Select {
  --pc-select-backdrop: 10;
  --pc-select-content: 20;
  --pc-select-input: 30;
  position: relative;
}

.Polaris-Select select::-ms-expand {
  display: none;
}

.Polaris-Select--disabled .Polaris-Select__Content {
  color: var(--p-text-disabled);
}

.Polaris-Select--disabled .Polaris-Select__InlineLabel {
  color: inherit;
}

.Polaris-Select--disabled .Polaris-Select__Icon svg {
  fill: var(--p-icon-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop {
  border-color: var(--p-border-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop:before {
  background-color: var(--p-action-secondary-disabled);
}

.Polaris-Select--disabled .Polaris-Select__Backdrop:hover {
  cursor: default;
}

.Polaris-Select__Content {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  text-transform: initial;
  letter-spacing: initial;
  z-index: var(--pc-select-content);
  width: 100%;
  min-height: 2.25rem;
  padding: calc((var(--p-line-height-6)  - var(--p-line-height-3)  - var(--p-space-05)) / 2) var(--p-space-2) calc((var(--p-line-height-6)  - var(--p-line-height-3)  - var(--p-space-05)) / 2) var(--p-space-3);
  border: none;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 40em) {
  .Polaris-Select__Content {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Select__InlineLabel {
  color: var(--p-text-subdued);
  margin-right: var(--p-space-1);
  white-space: nowrap;
  overflow: hidden;
}

.Polaris-Select__SelectedOption {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.Polaris-Select__Prefix {
  padding-right: var(--p-space-2);
}

.Polaris-Select__Icon svg {
  fill: var(--p-icon);
}

.Polaris-Select__Input {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  text-transform: initial;
  letter-spacing: initial;
  text-rendering: auto;
  z-index: var(--pc-select-input);
  width: 100%;
  height: 100%;
  opacity: 0;
  appearance: none;
  border: none;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 40em) {
  .Polaris-Select__Input {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Select__Backdrop {
  z-index: var(--pc-select-backdrop);
  border: var(--p-border-width-1) solid var(--p-border-subdued);
  border-bottom-color: var(--p-border-shadow-subdued);
  border-radius: var(--p-border-radius-1);
  background-color: var(--p-surface);
  box-shadow: var(--p-shadow-button);
  position: absolute;
  inset: 0;
}

.Polaris-Select__Backdrop:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.125rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.125rem;
}

.Polaris-Select--error .Polaris-Select__Backdrop {
  border-color: var(--p-border-critical);
  background-color: var(--p-surface-critical-subdued);
}

.Polaris-Select--error .Polaris-Select__Backdrop.Polaris-Select--hover, .Polaris-Select--error .Polaris-Select__Backdrop:hover {
  border-color: var(--p-border-critical);
}

.Polaris-Select--error .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop:after, .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

@media (-ms-high-contrast: active) {
  .Polaris-Select__Content {
    color: windowText;
    -ms-high-contrast-adjust: none;
  }

  .Polaris-Select__InlineLabel {
    color: inherit;
  }

  .Polaris-Select__InlineLabel:after {
    content: ":";
  }

  .Polaris-Select__SelectedOption {
    color: inherit;
  }

  .Polaris-Select__Icon svg {
    fill: buttonText;
  }

  .Polaris-Select__Backdrop:after {
    display: none;
  }

  .Polaris-Select__Input:focus ~ .Polaris-Select__Content {
    color: highlightText;
  }

  .Polaris-Select__Input:focus ~ .Polaris-Select__Backdrop {
    background-color: highlight;
  }

  .Polaris-Select--disabled .Polaris-Select__Content {
    color: grayText;
  }

  .Polaris-Select--disabled .Polaris-Select__Icon {
    opacity: 1;
  }

  .Polaris-Select--disabled .Polaris-Select__Icon svg {
    fill: grayText;
  }
}

.Polaris-ResourceList__FiltersWrapper {
  padding: var(--p-space-3) var(--p-space-3) var(--p-space-4);
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__FiltersWrapper {
    padding: var(--p-space-4);
  }
}

.Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList {
  border-top: var(--p-border-divider);
}

.Polaris-ResourceList__HeaderOuterWrapper {
  background-color: var(--p-surface);
  z-index: 2;
  border-top-left-radius: var(--p-border-radius-2);
  border-top-right-radius: var(--p-border-radius-2);
  position: relative;
  overflow: hidden;
}

.Polaris-ResourceList__HeaderOuterWrapper + .Polaris-ResourceList {
  border-top: var(--p-border-divider);
}

.Polaris-ResourceList__HeaderWrapper--disabled {
  pointer-events: none;
}

.Polaris-ResourceList__HeaderWrapper--overlay {
  z-index: 4;
  background-color: var(--p-overlay);
  position: absolute;
  inset: 0;
}

.Polaris-ResourceList__FiltersWrapper + .Polaris-ResourceList__HeaderOuterWrapper {
  margin-top: calc(var(--p-space-4) * -1);
}

.Polaris-ResourceList__HeaderWrapper {
  width: 100%;
  min-height: 3.5rem;
  padding: calc(var(--p-space-3)  - var(--p-space-05)) var(--p-space-4);
  background-color: var(--p-surface);
  align-items: flex-start;
  display: flex;
  position: relative;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderWrapper {
    flex-direction: row;
  }
}

.Polaris-ResourceList__HeaderWrapper--isSticky {
  box-shadow: var(--p-shadow-base);
}

.Polaris-ResourceList__HeaderContentWrapper {
  z-index: 1;
  right: var(--p-space-3);
  left: var(--p-space-3);
  min-height: 2.25rem;
  opacity: 1;
  transition: opacity var(--p-ease) var(--p-duration-200);
  display: flex;
  position: absolute;
}

.Polaris-ResourceList__HeaderWrapper--inSelectMode .Polaris-ResourceList__HeaderContentWrapper {
  opacity: 0;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderContentWrapper {
    right: var(--p-space-4);
    left: var(--p-space-4);
    align-self: flex-start;
  }
}

.Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__AlternateToolWrapper {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__AlternateToolWrapper {
  padding-right: var(--p-space-2);
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__AlternateToolWrapper {
    margin-left: var(--p-space-4);
    flex: 0 auto;
    position: relative;
    left: auto;
  }

  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__SortWrapper, .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__AlternateToolWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__AlternateToolWrapper {
    padding-right: 0;
  }
}

.Polaris-ResourceList__SortWrapper {
  min-width: 0;
  max-width: 100%;
}

.Polaris-ResourceList__SortWrapper > * {
  max-width: 100%;
}

.Polaris-ResourceList__HeaderTitleWrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: var(--p-space-1);
  flex: 1;
  align-self: center;
}

.Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper, .Polaris-ResourceList__HeaderWrapper--hasAlternateTool.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort.Polaris-ResourceList__HeaderWrapper--hasSelect .Polaris-ResourceList__HeaderTitleWrapper {
    display: none;
  }

  .Polaris-ResourceList__HeaderWrapper--hasAlternateTool .Polaris-ResourceList__HeaderTitleWrapper, .Polaris-ResourceList__HeaderWrapper--hasSort .Polaris-ResourceList__HeaderTitleWrapper {
    display: block;
  }
}

.Polaris-ResourceList__BulkActionsWrapper {
  z-index: 2;
  width: 100%;
  position: relative;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__BulkActionsWrapper {
    flex: 0 auto;
    align-self: flex-start;
  }
}

.Polaris-ResourceList__CheckableButtonWrapper {
  display: none;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__CheckableButtonWrapper {
    flex: 1;
    display: block;
  }
}

.Polaris-ResourceList__SelectButtonWrapper {
  flex: none;
  position: relative;
}

@media (min-width: 28.625em) {
  .Polaris-ResourceList__SelectButtonWrapper {
    display: none;
  }
}

.Polaris-ResourceList__EmptySearchResultWrapper {
  padding-top: var(--p-space-8);
  padding-bottom: var(--p-space-8);
}

@media (min-height: 37.5em) {
  .Polaris-ResourceList__EmptySearchResultWrapper {
    padding-top: var(--p-space-16);
    padding-bottom: var(--p-space-16);
  }
}

.Polaris-ResourceList__ResourceListWrapper {
  position: relative;
}

.Polaris-ResourceList {
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.Polaris-ResourceList__ItemWrapper {
  z-index: 1;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}

.Polaris-ResourceList__ItemWrapper + .Polaris-ResourceList__ItemWrapper {
  border-top: var(--p-border-divider);
}

.Polaris-ResourceList__ItemWrapper--isLoading {
  min-height: 4rem;
}

.Polaris-ResourceList__SpinnerContainer {
  z-index: 4;
  justify-content: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.Polaris-ResourceList__LoadingOverlay {
  z-index: 3;
  background-color: var(--p-overlay);
  justify-content: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.Polaris-ResourceList__DisabledPointerEvents {
  pointer-events: none;
}

.Polaris-ResourceList--disableTextSelection {
  user-select: none;
}

.Polaris-SkeletonBodyText {
  height: var(--p-space-2);
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-base);
  display: flex;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonBodyText {
    background-color: grayText;
  }
}

.Polaris-SkeletonBodyText:last-child:not(:first-child) {
  width: 80%;
}

.Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText {
  margin-top: var(--p-space-3);
}

.Polaris-SkeletonDisplayText__DisplayText {
  --pc-skeleton-display-text-height: var(--p-line-height-2);
  --pc-skeleton-display-text-height-not-condensed: var(--p-line-height-2);
  max-width: 7.5rem;
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-base);
  display: flex;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonDisplayText__DisplayText {
    background-color: grayText;
  }
}

.Polaris-SkeletonDisplayText--sizeSmall {
  --pc-skeleton-display-text-height: var(--p-line-height-3);
  --pc-skeleton-display-text-height-not-condensed: var(--p-line-height-4);
  height: var(--pc-skeleton-display-text-height);
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeSmall {
    height: var(--pc-skeleton-display-text-height-not-condensed);
  }
}

.Polaris-SkeletonDisplayText--sizeMedium {
  --pc-skeleton-display-text-height: var(--p-line-height-4);
  --pc-skeleton-display-text-height-not-condensed: var(--p-line-height-5);
  height: var(--pc-skeleton-display-text-height);
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeMedium {
    height: var(--pc-skeleton-display-text-height-not-condensed);
  }
}

.Polaris-SkeletonDisplayText--sizeLarge {
  --pc-skeleton-display-text-height: var(--p-line-height-4);
  --pc-skeleton-display-text-height-not-condensed: var(--p-line-height-5);
  height: var(--pc-skeleton-display-text-height);
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeLarge {
    height: var(--pc-skeleton-display-text-height-not-condensed);
  }
}

.Polaris-SkeletonDisplayText--sizeExtraLarge {
  --pc-skeleton-display-text-height: var(--p-line-height-6);
  --pc-skeleton-display-text-height-not-condensed: var(--p-line-height-7);
  height: var(--pc-skeleton-display-text-height);
}

@media (min-width: 40em) {
  .Polaris-SkeletonDisplayText--sizeExtraLarge {
    height: var(--pc-skeleton-display-text-height-not-condensed);
  }
}

.Polaris-SkeletonPage__Page {
  max-width: 62.375rem;
  margin: 0 auto;
  padding: 0;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__Page {
    padding: 0 var(--p-space-6);
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__Page {
    padding: 0 var(--p-space-6);
  }
}

.Polaris-SkeletonPage--fullWidth {
  max-width: none;
}

.Polaris-SkeletonPage--narrowWidth {
  max-width: 41.375rem;
}

.Polaris-SkeletonPage__Content {
  margin: var(--p-space-2) 0;
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Content {
    margin-top: var(--p-space-5);
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__Content {
    margin-top: var(--p-space-5);
  }
}

.Polaris-SkeletonPage__Header {
  padding: var(--p-space-4);
  padding-bottom: var(--p-space-2);
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__Header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Header {
    padding: var(--p-space-5) 0;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__Header {
    padding: var(--p-space-5) 0;
  }
}

.Polaris-SkeletonPage__BreadcrumbAction {
  padding-top: var(--p-space-4);
  padding-bottom: var(--p-space-4);
  margin-top: calc(var(--p-space-1) * -1);
  margin-bottom: calc(var(--p-space-1) * -1);
}

.Polaris-SkeletonPage__TitleAndPrimaryAction {
  display: flex;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__TitleAndPrimaryAction {
    display: block;
  }
}

@media (max-width: 46.5em) {
  .Polaris-SkeletonPage__TitleAndPrimaryAction {
    display: block;
  }
}

.Polaris-SkeletonPage__TitleWrapper {
  flex: 1;
}

.Polaris-SkeletonPage__Title {
  font-weight: var(--p-font-weight-semibold);
  font-size: 1.5rem;
  line-height: 1.75rem;
}

@media (min-width: 40em) {
  .Polaris-SkeletonPage__Title {
    font-size: 1.25rem;
  }
}

.Polaris-SkeletonPage__SkeletonTitle {
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-base);
  max-width: 7.5rem;
  height: 1.75rem;
  display: flex;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonPage__SkeletonTitle {
    background-color: grayText;
  }
}

.Polaris-SkeletonPage__PrimaryAction {
  align-self: stretch;
}

.Polaris-SkeletonPage__PrimaryAction > * {
  height: 2.25rem;
  min-width: 6.25rem;
}

@media (max-width: 48.0625em), (min-width: 48.0625em) and (max-width: 65em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: var(--p-space-4);
    margin-bottom: calc(var(--p-space-2) * -1);
  }
}

@media (max-width: 50em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: var(--p-space-4);
    margin-bottom: calc(var(--p-space-2) * -1);
  }
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: var(--p-space-2);
    margin-bottom: calc(var(--p-space-2) * -1);
  }
}

@media (min-width: 30.625em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: var(--p-space-2);
    margin-bottom: calc(var(--p-space-2) * -1);
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (min-width: 46.5em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (max-width: 48.0625em) and (min-width: 50em), (min-width: 65em) {
  [data-has-navigation] .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

@media (min-width: 50em) {
  .Polaris-SkeletonPage__PrimaryAction {
    margin-top: 0;
  }
}

.Polaris-SkeletonPage__Actions {
  margin-top: var(--p-space-2);
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Polaris-SkeletonPage__Action {
  min-height: 1.75rem;
  padding-right: var(--p-space-6);
  margin-top: calc(var(--p-space-1) * -1);
  margin-bottom: calc(var(--p-space-1) * -1);
  padding-top: var(--p-space-4);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.Polaris-SkeletonPage__Action:first-child {
  padding-right: 0;
}

@media (max-width: 45em), (min-width: 48.0625em) and (max-width: 61.5em) {
  [data-has-navigation] .Polaris-SkeletonPage__Action:not(:last-child) {
    display: none;
  }
}

@media (max-width: 46.5em) {
  .Polaris-SkeletonPage__Action:not(:last-child) {
    display: none;
  }
}

.Polaris-SkeletonTabs__Tabs {
  width: 100%;
  border-bottom: var(--p-border-divider);
  display: flex;
}

.Polaris-SkeletonTabs__Tab {
  padding: calc(var(--p-space-5)  + var(--p-space-05)) var(--p-space-4);
  position: relative;
}

.Polaris-SkeletonTabs__Tab:first-child:before {
  background-color: var(--p-border-hovered);
}

.Polaris-SkeletonTabs__Tab:before {
  content: "";
  bottom: -.0625rem;
  left: var(--p-space-3);
  right: var(--p-space-3);
  height: var(--p-border-width-3);
  border-top-left-radius: var(--p-border-radius-1);
  border-top-right-radius: var(--p-border-radius-1);
  position: absolute;
}

.Polaris-SkeletonTabs__Tab--short {
  width: 5rem;
}

.Polaris-SkeletonTabs__Tab--long {
  width: 6.25rem;
}

.Polaris-SkeletonThumbnail {
  --pc-skeleton-thumbnail-extra-small-size: 1.5rem;
  --pc-skeleton-thumbnail-small-size: 2.5rem;
  --pc-skeleton-thumbnail-medium-size: 3.75rem;
  --pc-skeleton-thumbnail-large-size: 5rem;
  background-color: var(--p-surface-neutral);
  border-radius: var(--p-border-radius-base);
  display: flex;
}

@media screen and (-ms-high-contrast: active) {
  .Polaris-SkeletonThumbnail {
    background-color: grayText;
  }
}

.Polaris-SkeletonThumbnail--sizeExtraSmall {
  height: var(--pc-skeleton-thumbnail-extra-small-size);
  width: var(--pc-skeleton-thumbnail-extra-small-size);
}

.Polaris-SkeletonThumbnail--sizeSmall {
  height: var(--pc-skeleton-thumbnail-small-size);
  width: var(--pc-skeleton-thumbnail-small-size);
}

.Polaris-SkeletonThumbnail--sizeMedium {
  height: var(--pc-skeleton-thumbnail-medium-size);
  width: var(--pc-skeleton-thumbnail-medium-size);
}

.Polaris-SkeletonThumbnail--sizeLarge {
  height: var(--pc-skeleton-thumbnail-large-size);
  width: var(--pc-skeleton-thumbnail-large-size);
}

.Polaris-Tabs {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.Polaris-Tabs__Wrapper {
  border-bottom: var(--p-border-divider);
  padding: 0 var(--p-space-2);
}

.Polaris-Tabs--fitted {
  flex-wrap: nowrap;
}

.Polaris-Tabs--fitted .Polaris-Tabs__TabContainer {
  flex: 100%;
}

.Polaris-Tabs--fitted .Polaris-Tabs__Title {
  width: 100%;
  padding: var(--p-space-2) var(--p-space-4);
}

.Polaris-Tabs--fillSpace .Polaris-Tabs__TabContainer {
  flex: auto;
}

.Polaris-Tabs__TabContainer {
  margin: 0;
  padding: 0;
  display: flex;
}

.Polaris-Tabs__Tab {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  color: var(--p-text-subdued);
  color: var(--p-text);
  width: 100%;
  min-width: 100%;
  margin: 0;
  margin-top: var(--p-space-025);
  margin-bottom: calc(var(--p-space-025) * -1);
  padding: 0;
  padding: var(--p-space-2) var(--p-space-1);
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  justify-content: center;
  text-decoration: none;
  position: relative;
}

.Polaris-Tabs__Tab:focus {
  outline: none;
}

@media (min-width: 40em) {
  .Polaris-Tabs__Tab {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-Tabs__Tab:hover {
  text-decoration: none;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
  background-color: #0000;
}

.Polaris-Tabs__Tab:hover .Polaris-Tabs__Title:before {
  background-color: var(--p-border-hovered);
}

.Polaris-Tabs__Tab:active .Polaris-Tabs__Title {
  background-color: #0000;
}

.Polaris-Tabs__Tab:active .Polaris-Tabs__Title:before {
  background: var(--p-surface-primary-selected-pressed);
}

.Polaris-Tabs__Tab:focus .Polaris-Tabs__Title {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
}

.Polaris-Tabs__Tab:focus:not(:active) .Polaris-Tabs__Title:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tabs__Tab:visited {
  color: inherit;
}

.Polaris-Tabs__Tab--selected {
  font-weight: var(--p-font-weight-regular);
  color: var(--p-text);
}

.Polaris-Tabs__Tab--selected:focus .Polaris-Tabs__Title {
  outline: var(--p-border-width-3) solid transparent;
}

.Polaris-Tabs__Tab--selected:focus .Polaris-Tabs__Title:before {
  background: var(--p-action-primary);
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
  outline: var(--p-border-width-3) solid transparent;
  color: var(--p-text);
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title:before {
  background: var(--p-action-primary);
}

.Polaris-Tabs__Title {
  border-radius: var(--p-border-radius-1);
  padding: var(--p-space-2) var(--p-space-4);
  min-width: 3.125rem;
  color: var(--p-text-subdued);
  display: block;
  position: relative;
}

.Polaris-Tabs__Title:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Tabs__Title:before {
  content: "";
  bottom: calc(var(--p-space-2) * -1);
  height: var(--p-border-width-3);
  border-top-left-radius: var(--p-border-radius-1);
  border-top-right-radius: var(--p-border-radius-1);
  position: absolute;
  left: 0;
  right: 0;
}

.Polaris-Tabs--titleWithIcon {
  display: flex;
}

.Polaris-Tabs__Panel {
  display: block;
}

.Polaris-Tabs__Panel:focus {
  outline: none;
}

.Polaris-Tabs__Panel--hidden {
  display: none;
}

.Polaris-Tabs__List {
  padding: var(--p-space-2);
  margin: 0;
  list-style: none;
}

.Polaris-Tabs__Item {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  width: 100%;
  min-height: 1rem;
  padding: 0;
  padding: .5rem var(--p-space-4);
  text-align: left;
  cursor: pointer;
  border-radius: var(--p-border-radius-1);
  color: inherit;
  background: none;
  border: none;
  margin: 0;
  text-decoration: none;
  display: block;
  position: relative;
}

.Polaris-Tabs__Item:focus {
  outline: none;
}

.Polaris-Tabs__Item:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Tabs__Item::-moz-focus-inner {
  border: none;
}

.Polaris-Tabs__Item:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-Tabs__Item:active {
  background-color: var(--p-surface-primary-selected-pressed);
}

.Polaris-Tabs__Item:focus:not(:active):after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tabs__Item:visited {
  color: inherit;
}

.Polaris-Tabs__DisclosureTab {
  display: none;
}

.Polaris-Tabs__DisclosureTab--visible {
  display: flex;
}

.Polaris-Tabs__DisclosureActivator {
  height: 100%;
  cursor: pointer;
  margin: var(--p-space-025) var(--p-space-025) calc(var(--p-space-025) * -1) 0;
  background-color: #0000;
  border: none;
  outline: none;
  position: relative;
}

.Polaris-Tabs__DisclosureActivator:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-Tabs__DisclosureActivator:hover svg, .Polaris-Tabs__DisclosureActivator:focus svg {
  fill: var(--p-icon);
}

.Polaris-Tabs__DisclosureActivator:focus .Polaris-Tabs__Title:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-Tabs__DisclosureActivator:hover .Polaris-Tabs__Title:before {
  background-color: var(--p-border-hovered);
}

.Polaris-Tabs__TabMeasurer {
  visibility: hidden;
  height: 0;
  display: flex;
}

.Polaris-Thumbnail {
  --pc-thumbnail-extra-small-size: 1.5rem;
  --pc-thumbnail-small-size: 2.5rem;
  --pc-thumbnail-medium-size: 3.75rem;
  --pc-thumbnail-large-size: 5rem;
  background: var(--p-surface);
  min-width: var(--pc-thumbnail-extra-small-size);
  max-width: 100%;
  border-radius: var(--p-border-radius-1);
  border: var(--p-border-divider);
  display: block;
  position: relative;
  overflow: hidden;
}

.Polaris-Thumbnail:after {
  content: "";
  padding-bottom: 100%;
  display: block;
}

.Polaris-Thumbnail--sizeExtraSmall {
  width: var(--pc-thumbnail-extra-small-size);
}

.Polaris-Thumbnail--sizeSmall {
  width: var(--pc-thumbnail-small-size);
}

.Polaris-Thumbnail--sizeMedium {
  width: var(--pc-thumbnail-medium-size);
}

.Polaris-Thumbnail--sizeLarge {
  width: var(--pc-thumbnail-large-size);
}

.Polaris-Thumbnail--transparent {
  background: none;
}

.Polaris-Thumbnail > * {
  max-width: 100%;
  max-height: 100%;
  color: var(--p-icon-subdued);
  margin: auto;
  position: absolute;
  inset: 0;
}

.Polaris-Thumbnail > * svg {
  fill: currentColor;
}

.Polaris-TopBar-SearchDismissOverlay {
  z-index: var(--p-z-7);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.Polaris-TopBar-SearchDismissOverlay--visible {
  background-color: #0000;
  animation: none;
}

.Polaris-TopBar-Search {
  visibility: hidden;
  z-index: var(--p-z-8);
  pointer-events: none;
  box-shadow: var(--p-shadow-modal);
  position: fixed;
  top: 3.5rem;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar-Search {
    max-width: 36.25rem;
    margin: var(--p-space-1) var(--p-space-5) 0;
    border-radius: var(--p-border-radius-2);
    position: absolute;
    top: 100%;
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar-Search {
    max-width: 36.25rem;
    margin: var(--p-space-1) var(--p-space-5) 0;
    border-radius: var(--p-border-radius-2);
    position: absolute;
    top: 100%;
  }
}

@media (max-width: 48.0625em) and (min-width: 46.5em), (min-width: 61.5em) {
  [data-has-navigation] .Polaris-TopBar-Search {
    margin: var(--p-space-1) var(--p-space-8) 0;
  }
}

@media (min-width: 46.5em) {
  .Polaris-TopBar-Search {
    margin: var(--p-space-1) var(--p-space-8) 0;
  }
}

.Polaris-TopBar-Search__SearchContent {
  background-color: var(--p-surface);
}

.Polaris-TopBar-Search--visible {
  visibility: initial;
  pointer-events: all;
}

.Polaris-TopBar-Search__Results {
  max-height: calc(100vh - 3.5rem);
  flex-direction: column;
  margin: 0;
  display: flex;
  position: relative;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar-Search__Results {
    max-height: 60vh;
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar-Search__Results {
    max-height: 60vh;
  }
}

.Polaris-TopBar-SearchField {
  --pc-search-field-backdrop: 1;
  --pc-search-field-input: 2;
  --pc-search-field-icon: 3;
  --pc-search-field-action: 3;
  z-index: var(--p-z-11);
  border: var(--p-border-transparent);
  width: 100%;
  max-width: 36.25rem;
  flex: auto;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input, .Polaris-TopBar-SearchField__Input:focus {
  color: var(--p-text);
  border: none;
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Input::placeholder, .Polaris-TopBar-SearchField__Input:focus::placeholder {
  color: var(--p-text-subdued);
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Backdrop:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__BackdropShowFocusBorder {
  border: var(--p-border-width-1) solid var(--pc-top-bar-border);
}

.Polaris-TopBar-SearchField__Input:focus ~ .Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__BackdropShowFocusBorder {
  border: var(--p-border-width-1) solid var(--pc-top-bar-border);
}

.Polaris-TopBar-SearchField--focused .Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Input {
  font-size: var(--p-font-size-5);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-3);
  text-transform: initial;
  letter-spacing: initial;
  z-index: var(--pc-search-field-input);
  height: 2.25rem;
  width: 100%;
  padding: 0 0 0 calc(1.25rem + var(--p-space-4));
  color: var(--p-text);
  backface-visibility: hidden;
  will-change: fill, color;
  transition: fill var(--p-duration-200) var(--p-ease), color var(--p-duration-200) var(--p-ease);
  appearance: textfield;
  background-color: #0000;
  border: none;
  outline: none;
}

@media (min-width: 40em) {
  .Polaris-TopBar-SearchField__Input {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-TopBar-SearchField__Input::placeholder {
  color: var(--p-text);
}

.Polaris-TopBar-SearchField__Input::-webkit-search-decoration, .Polaris-TopBar-SearchField__Input::-webkit-search-cancel-button {
  appearance: none;
}

.Polaris-TopBar-SearchField__Icon {
  z-index: var(--pc-search-field-icon);
  top: 50%;
  left: var(--p-space-2);
  height: 1.25rem;
  pointer-events: none;
  display: flex;
  position: absolute;
  transform: translateY(-50%);
}

.Polaris-TopBar-SearchField__Icon svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Clear {
  z-index: var(--pc-search-field-action);
  appearance: none;
  padding: var(--p-space-2);
  background: none;
  border: none;
  position: relative;
}

.Polaris-TopBar-SearchField__Clear svg {
  fill: var(--p-icon);
}

.Polaris-TopBar-SearchField__Clear:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-2)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-TopBar-SearchField__Clear:focus, .Polaris-TopBar-SearchField__Clear:hover {
  outline: none;
}

.Polaris-TopBar-SearchField__Clear:focus svg, .Polaris-TopBar-SearchField__Clear:hover svg {
  fill: var(--p-icon-hovered);
}

.Polaris-TopBar-SearchField__Clear:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-TopBar-SearchField__Clear:active svg {
  fill: var(--p-icon-pressed);
}

.Polaris-TopBar-SearchField__Clear:active:after {
  border: none;
}

.Polaris-TopBar-SearchField__Backdrop {
  z-index: var(--pc-search-field-backdrop);
  background-color: var(--p-surface-search-field);
  border-radius: var(--p-border-radius-1);
  position: absolute;
  inset: 0;
}

.Polaris-TopBar-SearchField__Backdrop:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-MessageIndicator__MessageIndicatorWrapper {
  position: relative;
}

.Polaris-MessageIndicator {
  z-index: 1;
  width: .75rem;
  height: .75rem;
  border-radius: var(--p-border-radius-half);
  background-color: var(--p-icon-highlight);
  border: solid var(--p-border-width-2) var(--p-background);
  position: absolute;
  top: -.1875rem;
  right: -.1875rem;
}

.Polaris-Menu-Message__Section {
  max-width: 20.3125rem;
  margin-top: var(--p-space-2);
  padding-top: var(--p-space-2);
  border-top: var(--p-border-divider);
}

.Polaris-TopBar-Menu__ActivatorWrapper {
  height: 3.5rem;
  align-items: center;
  display: flex;
}

.Polaris-TopBar-Menu__Activator {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  color: var(--p-text);
  min-width: 2.25rem;
  padding: 0;
  padding: var(--p-space-1) var(--p-space-2);
  cursor: pointer;
  transition: background-color var(--p-duration-100);
  margin: 0;
  margin-right: var(--p-space-2);
  border-radius: var(--p-border-radius-base);
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.Polaris-TopBar-Menu__Activator:focus {
  outline: none;
}

.Polaris-TopBar-Menu__Activator:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.0625rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.0625rem;
}

.Polaris-TopBar-Menu__Activator:focus {
  background-color: var(--pc-top-bar-background-lighter);
  outline: none;
}

.Polaris-TopBar-Menu__Activator:focus:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-TopBar-Menu__Activator:hover {
  background-color: var(--pc-top-bar-background-lighter, var(--p-surface-hovered));
}

.Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
  background-color: var(--pc-top-bar-background-darker, var(--p-surface-pressed));
  outline: none;
  transition: none;
}

.Polaris-TopBar-Menu__Activator:active:after, .Polaris-TopBar-Menu__Activator[aria-expanded="true"]:after {
  border: none;
}

@media (max-width: 48.0525em) {
  .Polaris-TopBar-Menu__Activator {
    margin: 0;
  }

  .Polaris-TopBar-Menu__Activator:focus, .Polaris-TopBar-Menu__Activator:hover, .Polaris-TopBar-Menu__Activator:active, .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
    opacity: .85;
    background-color: #0000;
  }
}

.Polaris-TopBar-Menu__Section {
  margin-top: var(--p-space-2);
  padding-top: var(--p-space-2);
  border-top: var(--p-border-divider);
}

.Polaris-TopBar-UserMenu__Details {
  max-width: 10rem;
  margin-left: var(--p-space-2);
}

@media (max-width: 48.0525em) {
  .Polaris-TopBar-UserMenu__Details {
    display: none;
  }
}

.Polaris-TopBar-UserMenu__Name {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--p-font-size-4);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  text-transform: initial;
  letter-spacing: initial;
  font-weight: var(--p-font-weight-medium);
  line-height: var(--p-line-height-1);
  color: var(--p-text);
  text-align: left;
  overflow: hidden;
}

@media (min-width: 40em) {
  .Polaris-TopBar-UserMenu__Name {
    font-size: var(--p-font-size-3);
  }
}

.Polaris-TopBar-UserMenu__Detail {
  font-size: var(--p-font-size-2);
  font-weight: var(--p-font-weight-regular);
  line-height: var(--p-line-height-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .7;
  color: var(--p-text);
  text-align: left;
  overflow: hidden;
}

@media (min-width: 40em) {
  .Polaris-TopBar-UserMenu__Detail {
    font-size: var(--p-font-size-1);
    line-height: var(--p-line-height-1);
  }
}

.Polaris-TopBar {
  height: 3.5rem;
  box-shadow: var(--p-shadow-top-bar);
  background-color: var(--p-surface);
  transition: var(--p-duration-200) background-color var(--p-ease-in-out);
  display: flex;
  position: relative;
}

.Polaris-TopBar:after {
  content: "";
  width: 100%;
  border-bottom: var(--p-border-width-1) solid transparent;
  position: absolute;
  bottom: 0;
}

.Polaris-TopBar__LogoDisplayControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__LogoDisplayControl {
    display: flex;
  }
}

.Polaris-TopBar__LogoDisplayContainer {
  display: flex;
}

.Polaris-TopBar__LogoContainer {
  height: 100%;
  padding: 0 var(--p-space-2) 0 var(--p-space-4);
  flex: 0 0 15rem;
  flex-basis: calc(15rem + constant(safe-area-inset-left) );
  flex-basis: calc(15rem + env(safe-area-inset-left) );
  padding-left: var(--p-space-4);
  padding-left: calc(var(--p-space-4)  + constant(safe-area-inset-left) );
  padding-left: calc(var(--p-space-4)  + env(safe-area-inset-left) );
  align-items: center;
}

.Polaris-TopBar__LogoContainer.Polaris-TopBar--hasLogoSuffix {
  gap: var(--p-space-2);
}

.Polaris-TopBar__Logo, .Polaris-TopBar__LogoLink {
  display: block;
}

.Polaris-TopBar__ContextControl {
  display: none;
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__ContextControl {
    width: 15rem;
    display: block;
  }
}

@media (min-width: 87.5em) {
  .Polaris-TopBar__ContextControl {
    width: 15rem;
  }
}

.Polaris-TopBar__NavigationIcon {
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  margin: 0;
  margin-left: calc(var(--p-space-2)  + var(--p-space-05));
  margin-right: var(--p-space-2);
  padding: 0;
  padding: var(--p-space-2);
  border-radius: var(--p-border-radius-base);
  fill: var(--p-icon);
  transition: var(--p-duration-150) fill var(--p-ease) var(--p-duration-50);
  background: none;
  border: none;
  align-self: center;
  position: relative;
}

.Polaris-TopBar__NavigationIcon:focus {
  outline: none;
}

.Polaris-TopBar__NavigationIcon.Polaris-TopBar--focused:active {
  background-color: var(--p-surface-pressed);
}

.Polaris-TopBar__NavigationIcon:hover {
  background-color: var(--p-surface-hovered);
}

.Polaris-TopBar__NavigationIcon:after {
  content: "";
  top: calc(var(--p-space-2) * -1);
  left: calc(var(--p-space-2) * -1);
  width: calc(100% + var(--p-space-5));
  height: calc(100% + var(--p-space-5));
  position: absolute;
}

@media (min-width: 48.0625em) {
  .Polaris-TopBar__NavigationIcon {
    display: none;
  }
}

.Polaris-TopBar__NavigationIcon .Polaris-TopBar__IconWrapper {
  position: relative;
}

.Polaris-TopBar__NavigationIcon .Polaris-TopBar__IconWrapper:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  box-shadow: 0 0 0 -.4375rem var(--p-focused);
  transition: box-shadow var(--p-duration-100) var(--p-ease);
  border-radius: calc(var(--p-border-radius-1)  + .0625rem);
  display: block;
  position: absolute;
  inset: -.4375rem;
}

.Polaris-TopBar__NavigationIcon:focus:not(:active) .Polaris-TopBar__IconWrapper:after {
  box-shadow: 0 0 0 .125rem var(--p-focused);
  outline: var(--p-border-width-1) solid transparent;
}

.Polaris-TopBar__Contents {
  z-index: var(--p-z-1);
  height: 100%;
  flex: auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

@media (min-width: 42.5em) {
  .Polaris-TopBar__Contents {
    position: relative;
  }
}

.Polaris-TopBar__SearchField {
  max-width: 62.375rem;
  width: 100%;
  max-width: none;
  margin: 0 0 0 calc(50% - 31.1875rem);
  padding: 0;
  position: relative;
}

@media (max-width: 48.0625em) and (min-width: 30.625em), (min-width: 45.625em) {
  [data-has-navigation] .Polaris-TopBar__SearchField {
    padding: 0 var(--p-space-6);
  }
}

@media (min-width: 30.625em) {
  .Polaris-TopBar__SearchField {
    padding: 0 var(--p-space-6);
  }
}

@media (max-width: 1238px) {
  .Polaris-TopBar__SearchField {
    margin-left: 0;
    margin-right: var(--p-space-1);
  }
}

.Polaris-TopBar__SecondaryMenu svg {
  fill: var(--p-icon);
}

.Polaris-VideoThumbnail__Thumbnail {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 56.25%;
  position: relative;
}

.Polaris-VideoThumbnail__Thumbnail.Polaris-VideoThumbnail__WithPlayer {
  z-index: 1;
  width: 100%;
  height: 100%;
  padding-bottom: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-VideoThumbnail__PlayButton {
  --pc-play-button-focused-state-overlay: #dfe3e84d;
  width: 100%;
  height: 100%;
  opacity: .8;
  transition: opacity var(--p-duration-200) var(--p-ease-in);
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.Polaris-VideoThumbnail__PlayButton:hover, .Polaris-VideoThumbnail__PlayButton:focus {
  opacity: 1;
}

.Polaris-VideoThumbnail__PlayButton:focus {
  box-shadow: inset .125rem 0 0 var(--p-focused);
  background-image: linear-gradient(var(--pc-play-button-focused-state-overlay), var(--pc-play-button-focused-state-overlay));
  outline: none;
}

.Polaris-VideoThumbnail__PlayIcon {
  width: 3.75rem;
  height: 3.75rem;
  margin-top: -1.875rem;
  margin-left: -1.875rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

.Polaris-VideoThumbnail__Timestamp {
  padding: 0 var(--p-space-1);
  margin-bottom: var(--p-space-2);
  margin-left: var(--p-space-2);
  border-radius: var(--p-border-radius-1);
  color: var(--p-text);
  background-color: var(--p-surface);
  opacity: .8;
  text-align: center;
  position: absolute;
  bottom: 0;
}

.Polaris-VideoThumbnail--withProgress {
  margin-bottom: var(--p-space-3);
}

.Polaris-VideoThumbnail__Progress {
  width: 100%;
  background-color: var(--p-surface);
  height: .375rem;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.Polaris-VideoThumbnail__Indicator {
  height: inherit;
  width: 100%;
  transform-origin: 0;
  background-color: var(--p-border-highlight);
  transition: transform var(--p-duration-500) var(--p-ease);
  transform: scaleX(0);
}

.Polaris-VideoThumbnail__ProgressBar, .Polaris-VideoThumbnail__Label {
  top: 0;
  width: .0625rem !important;
  height: .0625rem !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

/*# sourceMappingURL=index.41214557.css.map */
